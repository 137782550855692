import styled from "styled-components";
import { useState, useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Form } from "antd";

import SVGHeader from "./SVGHeader";
import PageSelector from "../../..//components/DrawerComponents/PageSelector";
import TableHeader from "../../..//components/DrawerComponents/TableHeader";
import TableEntry, { TABLE_ENTRY_HEIGHT } from "./TableEntry";
import ExpenseDetails from "./ExpenseDetails";
import PlanContent from "./PlanContent";
//import { MainCard } from "./ShortContent";

import useExpenseData from "../../ActiveTracker/useExpenseData";
import { useCheckResponseFail } from "../../../hooks/useCheckResponseFail";

import {
  monthABV,
  isCurrentMonth,
  get1MonthQueryString,
  getLastNumMonthsFromDateQueryString,
} from "../../../functions/timeDate";
import { twoDecimals } from "../../../functions/currency";
import backend from "../../../functions/backend";

import { SubcatListSimple } from "../../CFTSetup/Forms/SubcatList";
import { useCategoryData } from "../../CFTSetup/Forms/Data";
import { BudgetProgressBar } from "./BudgetProgressBar";

const MARGIN_LEFT = "35px";

export default function TallContent({
  editDate,
  dateString,
  category,
  tableData,
  onFinish,
  setEditID,
  budgetInfo,
  switchCategory,
  onFinishPlanned,
  viewMonth,
  viewYear,
  openShortDrawer,
}) {
  const [getCategoryData] = useCategoryData();
  //this is to set the different names for the tabs in the page selector
  const tabNames = [
    category !== "All" ? "Budget" : "Income",
    "Spending",
    "Details",
  ];

  const categoryNames = {
    All: "All",
    Personal: "Family",
    Grocery: "Grocery",
    Home: "Home & Garden",
    Entertainment: "Entertainment",
    Health: "Health",
    Transport: "Transportation",
    Focus: "Focus",
    Other: "Other",
  };

  console.log(`category = ${category}`);
  const categoryName2 = categoryNames[category];
  console.log(`categoryName2 = ${categoryName2}`);

  const headerLabel =
    category === "All"
      ? "All"
      : getCategoryData(categoryName2).categoryTitleText;
  console.log(`headerLabel = ${headerLabel}`);

  const selectedCurrentMonth = isCurrentMonth(viewMonth, viewYear);
  const [tab, setTab] = useState("Spending");
  const setTabProp = (index) => {
    if (index < 0 || index > 2) {
      return;
    }
    const tabNames = ["Planned", "Spending", "Details"];
    setTab(tabNames[index]);
  };

  const [showExpenseDetails, setShowExpenseDetails] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [filteredData, setFilteredData] = useState(tableData);
  const [form] = Form.useForm();
  useEffect(() => {
    // update filteredData, when tableData changes due to date changer
    setFilteredData(tableData);
  }, [tableData]);
  console.log("tableData", tableData);
  const searchFunction = (searchTerm) => {
    const filtered = tableData.filter((entry) => {
      return entry.description.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredData(filtered);
  };
  const createEntry = (entry) => {
    return (
      <TableEntry
        key={entry.id}
        date={entry.date}
        amount={entry.amount}
        originalAmount={entry.original_amount}
        counterParty={entry.counterparty}
        description={entry.description}
        // category={category === "All" ? entry.category : null}
        category={entry.isIncome ? "Income" : entry.category}
        isIncome={entry.isIncome}
        editRow={() => {
          setShowExpenseDetails(true);
          setSelectedEntry(entry);
          console.log("1123");
          console.log(entry.id);
          console.log(entry);
          setEditID(entry.id);

          const values = {
            // Sets the initial amount that goes inside the "Split Amount" Input.
            // Make it always positive.
            splitAmount: twoDecimals(Math.abs(entry.amount)),
            label: entry.label !== null ? entry.label : "",
            note: entry.note !== null ? entry.note : "",
            category: entry.category_id,
          };
          console.log("values", values);
          form.setFieldsValue(values);
        }}
        id={entry.id}
      />
    );
  };
  const tableEntries = filteredData.map((entry) => createEntry(entry));
  const [incomeData, setIncomeData] = useState([]);
  const checkResponseFail = useCheckResponseFail();
  const { formatForTable } = useExpenseData();
  //this is separate from the other fetches because it extends back 6 months
  const fetchIncomeData = async () => {
    console.log("getCurrentCategoryExpenses for category: Income");
    let query =
      "/v1/tx/transaction?posted_date=" +
      getLastNumMonthsFromDateQueryString(6, viewMonth, viewYear) +
      "&_sort_by=posted_date&_sort_order=desc";

    query += "&tx_category_id=" + 101;
    console.log("query: " + query);
    const response = await backend.get(query);
    if (
      checkResponseFail(
        response,
        "Failed to GET current category transactions:",
      )
    ) {
      return;
    }

    console.log("Got " + response.items.length + " income transactions.");
    return response.items;
  };

  const fetchData = async (txCategoryId, txCategoryName) => {
    console.log(`getCurrentCategoryExpenses for category: ${txCategoryName}`);
    let query =
      "/v1/tx/transaction?posted_date=" +
      get1MonthQueryString(viewMonth, viewYear) +
      "&_sort_by=posted_date&_sort_order=desc";

    query += "&tx_category_id=" + txCategoryId;
    console.log("getCurrentCategoryExpenses()");
    console.log(`    for ${viewMonth}/${viewYear}`);
    console.log("query: " + query);
    const response = await backend.get(query);
    if (
      checkResponseFail(
        response,
        "Failed to GET current category transactions:",
      )
    ) {
      return;
    }

    console.log(`Got ${response.items.length} ${txCategoryName} transactions.`);
    return response.items;
  };

  const fetchIncomeDepositTransferData = async () => {
    const incomeData = await fetchIncomeData();
    const depositData = await fetchData(102, "Deposits");
    const transferData = await fetchData(201, "Transfer");
    const tableData = formatForTable(
      [...(incomeData || []), ...(depositData || []), ...(transferData || [])],
      true,
    );
    setIncomeData(tableData.map((entry) => createEntry(entry)));
  };

  useEffect(() => {
    fetchIncomeDepositTransferData();
  }, [viewYear, viewMonth]);

  /*
  const getIncomeEntries = () => {
    return tableEntries.filter((entry) => entry.props.isIncome);
  };
  */

  const handleClick = (event) => {
    openShortDrawer();
  };

  return showExpenseDetails ? (
    <ExpenseDetails
      back={() => {
        setShowExpenseDetails(false);
        fetchIncomeDepositTransferData();
      }}
      entry={selectedEntry}
      form={form}
      onFinish={onFinish}
    />
  ) : (
    <MainContainer>
      {/* <h1> Tall Content </h1> */}
      {/* header and date section */}
      <HeaderInfo onClick={handleClick}>
        <SVGHeader
          headerLabel={headerLabel}
          fontSize="28px"
          category={category}
          switchCategory={switchCategory}
        />
        <DateSelector
          data-cy="tallDrawerDateSelect"
          onClick={(event) => {
            editDate();
            event.stopPropagation();
          }}
        >
          {dateString}
          <DownOutlined style={{ marginLeft: "8px" }} />
        </DateSelector>
      </HeaderInfo>
      {tab === "Planned" &&
        (category !== "All" ? (
          <>
            <TableHolder>
              <PageSelector
                selectedIndex={0}
                setTab={setTabProp}
                tabNames={tabNames}
              />
              <br />
            </TableHolder>
            <PlanContent
              budgetInfo={budgetInfo}
              changePlanAmount={onFinishPlanned}
              category={category}
              viewMonth={viewMonth}
              viewYear={viewYear}
            />
          </>
        ) : (
          //income tab
          <>
            <TableHolder>
              <PageSelector
                selectedIndex={0}
                setTab={setTabProp}
                tabNames={tabNames}
              />
              <TitleText>All Deposits</TitleText>
              <Table>
                <TableHeader searchFunction={searchFunction} />
              </Table>
            </TableHolder>
            <TableElementsContainer>
              {/* {getIncomeEntries()} */}
              {incomeData}
            </TableElementsContainer>
          </>
        ))}
      {tab === "Spending" && (
        <>
          <TableHolder>
            <PageSelector
              selectedIndex={1}
              setTab={setTabProp}
              tabNames={tabNames}
            />
            <TitleText>
              {selectedCurrentMonth
                ? "Current"
                : `${monthABV[viewMonth - 1]} ${viewYear}`}{" "}
              Spending
            </TitleText>
            <BudgetProgressBar
              spent={budgetInfo.actualSpend}
              total={budgetInfo.budgetAmount}
              remaining={budgetInfo.remaining}
              isCurrentMonth={selectedCurrentMonth}
            ></BudgetProgressBar>
            <TitleText>All Expenses</TitleText>
            <Table>
              <TableHeader searchFunction={searchFunction} />
            </Table>
          </TableHolder>

          <TableElementsContainer>{tableEntries}</TableElementsContainer>
        </>
      )}
      {tab === "Details" && (
        <>
          <TableHolder>
            <PageSelector
              selectedIndex={2}
              setTab={setTabProp}
              tabNames={tabNames}
            />
            <TitleText></TitleText>
          </TableHolder>
          <ContentBackground>
            {/* <MainCard budgetInfo={budgetInfo} /> */}
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {category !== "All" && (
                <SubcatListSimple
                  subcategoryText={
                    getCategoryData(categoryName2).subcategoryText
                  }
                />
              )}
            </div>
          </ContentBackground>
        </>
      )}
    </MainContainer>
  );
}

const MainContainer = styled.div`
  background: linear-gradient(180deg, #d9f7bd 0%, #adffb6 39.91%);
  width: 100%;
  height: 100%;
  // height: 100svh;
  display: flex;
  flex-direction: column;
`;

//might need to change this to its own component when we add date selecting fucntionality
const DateSelector = styled.button`
  box-shadow: 0px 4px 4px 0px #d4d4d466;
  background: #f3fff4cc;
  border-radius: 20px;
  border: none;
  height: 40px;
  padding: 10px 20px 10px 20px;

  align-self: flex-start;
  flex-shrink: 0; // don't let it word-wrap
  margin: 0;
`;

const TableHolder = styled.div`
  background: #ffffff;
  width: 100%;
  border-radius: 36px 36px 0 0;
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
  line-height: 33px;
  margin-left: ${MARGIN_LEFT};
  margin-top: 15px;
  margin-bottom: 15px;
`;

const Table = styled.div`
  background: #f0f0f0;
  width: 100%;
  border-radius: 36px 36px 0 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

const TableElementsContainer = styled.div`
  background: #f0f0f0;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin-bottom: 10px;
  padding-bottom: ${TABLE_ENTRY_HEIGHT}px;
`;

const ContentBackground = styled.div`
  background: #ffffff;
  flex: 1;
  padding: 20px;
`;

const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  //width: 96%;
  padding: 0 35px 0.5em 35px;
  //padding: 0 MARGIN_LEFT 0.5em MARGIN_LEFT;
`;
