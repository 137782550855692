// IN: a.date and b.date are dates in M/D format (eg 1/27 or 12/2)
// with no leading zeros
const dateSorter = (a, b) => {
  console.assert(
    typeof a.date === "string",
    "dateSorter(): a.date is not a string",
  );
  console.assert(
    typeof b.date === "string",
    "dateSorter(): b.date is not a string",
  );
  // Pad single digit month and day with zero, then join the strings,
  // to create MMDD format for comparison.
  const dateA = a.date
    .split("/")
    .map((num) => num.padStart(2, "0"))
    .join("");
  const dateB = b.date
    .split("/")
    .map((num) => num.padStart(2, "0"))
    .join("");

  // TODO: should really be comparing the years too.
  // But for now we will assume that dates are close.
  // Usually a higher month means it's more recent,
  // but if comparing 1 and 12, we'll assume the 12
  // is from the prior year, so the 1 is more recent.
  const monthA = parseInt(a.date.split("/")[0]);
  const monthB = parseInt(b.date.split("/")[0]);
  if (Math.abs(monthA - monthB) >= 8) {
    // if the difference between the 2 months is 8+ months
    // then we'll assume the higher number one is from the
    // previous year, so the order reverses.
    return dateB.localeCompare(dateA);
  }
  return dateA.localeCompare(dateB);
};

export const sortByDateThenBySplitFromTxId = (a, b) => {
  if (a.date === b.date) {
    // if date is the same,
    // do a secondary sort by splitFromTxId

    if (a.splitFromTxId === b.splitFromTxId) {
      // if splitFromTxTd is the same,
      // do a 3rd sort by id (higher id means
      // it was split off more recently)
      return a.id - b.id;
    }
    return a.splitFromTxId - b.splitFromTxId;
  }

  // primary sort by date
  return dateSorter(a, b);
};

export const sortByOriginalAmountThenBySplitFromTxId = (a, b) => {
  let a_original_amount = a.original_amount
    ? a.original_amount
    : a.amountString.split(" ").at(-1);
  a_original_amount = parseFloat(a_original_amount);
  let b_original_amount = b.original_amount
    ? b.original_amount
    : b.amountString.split(" ").at(-1);
  b_original_amount = parseFloat(b_original_amount);

  if (a_original_amount === b_original_amount) {
    // if the original amount is the same,
    // do a secondary sort by splitFromTxId

    if (a.splitFromTxId === b.splitFromTxId) {
      // if splitFromTxTd is the same,
      // do a 3rd sort by id (higher id means
      // it was split off more recently)
      return b.id - a.id;
    }
    return b.splitFromTxId - a.splitFromTxId; // desc, so newest split off tx is first
  }

  // primary sort by original amount
  return a_original_amount - b_original_amount;
  // We want the most significant amounts (ie highest absolute value)
  // So if amounts are positive, we want to sort "desc".
  // If amounts are negative, we want to sort "asc".
};

export const createColumnsCat = (editRow) => {
  return [
    {
      title: "",
      dataIndex: "icon",
      key: "icon",
      render: (t, r) => (
        <img
          width="24"
          height="24"
          onClick={() => editRow(r.id)}
          src={r.icon}
          alt="category icon"
          className="imageCategoryIcon"
          id={"icontableRowText_" + r.id}
        />
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: sortByDateThenBySplitFromTxId,
      // When user clicks on a column to sort it manually,
      // toggle between "descend" and "ascend" only.
      // Ant Design requires ["descend", "ascend", "descend"]
      // to make that work.
      sortDirections: ["descend", "ascend", "descend"],
      defaultSortOrder: "descend",
      render: (t, r) => (
        <div
          className="tableRowText"
          id={"DatetableRowText_" + r.id}
          onClick={() => editRow(r.id)}
        >
          {r.date}
        </div>
      ),
    },
    /* Previously, this was available during ExpenseReview,
       when looking at a single category.
    {
      title: "Account",
      dataIndex: "etype",
      key: "etype",
      sorter: (a, b) => a.etype.length - b.etype.length,
      sortDirections: ["descend", "ascend"],
      render: (t, r) => (
        <div className="tableRowText" onClick={() => editRow(r.id)}>
          {r.id}
        </div>
      ),
    },
    */
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      // When user clicks on a column to sort it manually,
      // toggle between "descend" and "ascend" only.
      // Ant Design requires ["descend", "ascend", "descend"]
      // to make that work.
      sortDirections: ["descend", "ascend", "descend"],
      render: (t, r) => (
        <div
          className="tableRowText"
          id={"DescriptiontableRowText_" + r.id}
          onClick={() => editRow(r.id)}
        >
          {r.description}
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amountString",
      sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount),
      // When user clicks on a column to sort it manually,
      // toggle between "descend" and "ascend" only.
      // Ant Design requires ["descend", "ascend", "descend"]
      // to make that work.
      sortDirections: ["descend", "ascend", "descend"],
      render: (t, r) => (
        <div
          className="tableRowText"
          id={"AmounttableRowText_" + r.id}
          onClick={() => editRow(r.id)}
        >
          {r.amountString}
        </div>
      ),
    },
    {
      title: "SplitFromTxId",
      dataIndex: "splitFromTxId",
      key: "splitFromTxId",
      sorter: (a, b) => a.splitFromTxId - b.splitFromTxId,
      sortDirections: ["descend", "ascend", "descend"],
      /*
      // keep for debugging
      render: (t, r) => (
        <div
          className="tableRowText"
          id={"SplitFromTxTdtableRowText_" + r.id}
          onClick={() => editRow(r.id)}
        >
          {r.splitFromTxId}
        </div>
      ),
      */
      hidden: true,
    },
  ];
};
