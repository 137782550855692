import parse from "html-react-parser";
import { Popover } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";

const InfoPopoverAlt = ({
  children,
  contentText,
  onCloseButton,
  ...commonProps
}) => {
  const BG_COLOR = "white";
  const defaultProps = {
    placement: "top",
    color: BG_COLOR,
  };

  const overlayStyle = {
    fontSize: "1.5rem",
    width: "80%",
    lineHeight: 1.4,
    color: "#333333",
    textAlign: "center",

    //padding: "0.5em 1em", // this messes up the overlay
    //border: "1px solid grey",  // looks bad

    borderRadius: "0.5em", // looks bad alone, without boxShadow
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  };

  // The outer div provides padding inside the popover
  const content = (
    <div style={{ position: "relative", paddingTop: "15px" }}>
      <CloseOutlined
        onClick={() => {
          onCloseButton(false);
        }}
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
          cursor: "pointer",
          color: "#999",
          fontSize: "16px",
        }}
      />
      <div>{parse(contentText)}</div>
      {/* parse(`
        Here is my placement: ${placement}.<br />
        <br />
      `) */}
    </div>
  );

  //here we make the popover have a small delay before opening
  const [openDelayed, setOpenDelayed] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setOpenDelayed(commonProps.open);
    }, 300); //popover opening delay
  }, [commonProps.open]);

  const mergedProps = { ...defaultProps, ...commonProps };
  mergedProps.open = openDelayed && commonProps.open;

  return (
    <Popover content={content} {...mergedProps} overlayStyle={overlayStyle}>
      {children}
    </Popover>
  );
};

export default InfoPopoverAlt;

export const MultiStepInfoPopoverAlt = ({
  children,
  contentText,
  maxTextStep,
  onCloseButton,
  ...commonProps
}) => {
  const BG_COLOR = "white";
  const defaultProps = {
    placement: "top",
    color: BG_COLOR,
  };

  const overlayStyle = {
    fontSize: "1.5rem",
    width: "80%",
    lineHeight: 1.4,
    color: "#333333",
    textAlign: "center",

    //padding: "0.5em 1em", // this messes up the overlay
    //border: "1px solid grey",  // looks bad

    borderRadius: "0.5em", // looks bad alone, without boxShadow
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  };

  const [textStep, setTextStep] = useState(1);

  const CircleRadius = "1rem";
  // The outer div provides padding inside the popover
  const content = (
    <div style={{ position: "relative", paddingTop: "15px" }}>
      <CloseOutlined
        onClick={() => {
          onCloseButton(false);
        }}
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
          cursor: "pointer",
          color: "#999",
          fontSize: "16px",
        }}
      />
      <div>{parse(contentText["step" + textStep])}</div>
      {/* parse(`
        Here is my placement: ${placement}.<br />
        <br />
      `) */}
      <div>
        <button
          style={{
            fontSize: "1.2rem",
            margin: "0.3rem",
            backgroundColor: textStep === 1 ? "#D9D9D9" : "#FFFFFF",
            borderRadius: "10px",
            border: "2px solid #333333CC",
          }}
          onClick={() => setTextStep((prev) => Math.max(1, prev - 1))}
        >
          Back
        </button>
        <button
          style={{
            fontSize: "1.2rem",
            margin: "0.3rem",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            border: "2px solid #333333CC",
          }}
          onClick={() => {
            setTextStep((prev) => Math.min(maxTextStep, prev + 1));
            if (textStep === maxTextStep) {
              onCloseButton(false);
            }
          }}
        >
          {textStep === maxTextStep ? "Done" : "Next"}
        </button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <div
          style={{
            width: CircleRadius,
            height: CircleRadius,
            borderRadius: "10px",
            border: "1px solid black",
            backgroundColor: textStep >= 1 ? "#D9D9D9" : "#FFFFFF",
          }}
        ></div>
        <div
          style={{
            width: CircleRadius,
            height: CircleRadius,
            borderRadius: "10px",
            border: "1px solid black",
            backgroundColor: textStep >= 2 ? "#D9D9D9" : "#FFFFFF",
          }}
        ></div>
        <div
          style={{
            width: CircleRadius,
            height: CircleRadius,
            borderRadius: "10px",
            border: "1px solid black",
            backgroundColor: textStep >= 3 ? "#D9D9D9" : "#FFFFFF",
          }}
        ></div>
        <div
          style={{
            width: CircleRadius,
            height: CircleRadius,
            borderRadius: "10px",
            border: "1px solid black",
            backgroundColor: textStep >= 4 ? "#D9D9D9" : "#FFFFFF",
          }}
        ></div>
      </div>
    </div>
  );

  //here we make the popover have a small delay before opening
  const [openDelayed, setOpenDelayed] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setOpenDelayed(commonProps.open);
    }, 300); //popover opening delay
  }, [commonProps.open]);

  const mergedProps = { ...defaultProps, ...commonProps };
  mergedProps.open = openDelayed && commonProps.open;

  return (
    <Popover content={content} {...mergedProps} overlayStyle={overlayStyle}>
      {children}
    </Popover>
  );
};
