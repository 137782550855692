// Custom React hook for handling error messages
import { useApp } from "../state/AppProvider";

export const useDisplayErrorMsg = () => {
  const { setShowErrorMsg, setErrorMsg, setLastErrorMsg } = useApp();

  const displayErrorMsg = (msg) => {
    // alert(message);
    setShowErrorMsg(true);
    setErrorMsg(msg);
    setLastErrorMsg(msg);
  };

  const hideErrorMsg = () => {
    setShowErrorMsg(false);
  };

  return { displayErrorMsg, hideErrorMsg };
};
