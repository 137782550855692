import { useEffect, useRef } from "react";
import { logBlue, logRed } from "../../functions/consoleLog";

export default function BudgetPoll({ budget, getBudget }) {
  useEffect(() => {
    logBlue("BudgetPoll mounted");
    if (invalidBudget(budget)) {
      pollBudget();
    }
  }, []);

  console.log(
    "BudgetPoll component rendering, but not necessarily running pollBudget",
  );
  const numTries = useRef(0);
  const startTimeRef = useRef(Date.now());
  console.log("Start time:  " + Math.floor(startTimeRef.current / 1000));

  // It's an invalid budget if budget is null
  // or all items in the budget are 0
  function invalidBudget(budget) {
    console.log(`invalidBudget() budget:`);
    console.log(budget);

    // for testing: make budgets always invalid
    //return true;

    if (budget === null) return true;

    for (const key in budget) {
      if (budget[key] === 0) {
        // for debugging, print out possibly suspicious 0 budgets
        console.warn(`Got 0 budget for category ${key}`);
      } else {
        return false;
      }
    }

    // every budget number is 0, so the budget is bad
    return true;
  }

  // Polling is happening in the background and not preventing the user
  // from doing anything.  Polling should continue until we get complete
  // budget numbers, so then the wheel and drawer numbers can be updated.
  // So it makes sense for the BUDGET_POLLING_TIME_LIMIT to be large.
  // However, could later implement exponential backoff with random jitter,
  // to avoid overloading the server.
  const BUDGET_POLLING_TIME_LIMIT = 600000; // for testing wait up to 10 minutes
  //const BUDGET_POLLING_TIME_LIMIT = 60000;  // for production
  const BUDGET_POLLING_TIME_INTERVAL = 5000;

  async function pollBudget() {
    logBlue("Calling pollBudget");
    let budgetReady = await getBudget();
    console.log("pollBudget: finished calling getBudget()");

    numTries.current += 1;

    if (!budgetReady) {
      console.log("Got a bad budget");
      if (Date.now() - startTimeRef.current < BUDGET_POLLING_TIME_LIMIT) {
        // not yet 1 minute elapsed
        setTimeout(pollBudget, BUDGET_POLLING_TIME_INTERVAL);
      } else {
        logBlue("End time:  " + Math.floor(Date.now() / 1000));
        logBlue(
          `Couldn't get budget in ${BUDGET_POLLING_TIME_LIMIT / 1000} seconds`,
        );
      }
    } else {
      let startTime = Math.floor(startTimeRef.current / 1000);
      logBlue(`Start time:  ${startTime}`);
      let endTime = Math.floor(Date.now() / 1000);
      logBlue(`End time:  ${endTime}`);
      logBlue(`Elapsed time: ${(endTime - startTime) / 60} minutes`);
      logBlue("Got a good budget");
    }
  }

  return null;

  /* for debugging
  return (
    <p style={{position: 'fixed', right: "2px"}}>Budget Poll: {numTries.current}</p>
  );
  */
}
