import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import { getMoneyFlowToken } from "../../state/stateApplication";
import { useUserTable } from "./useUserTable";
import backend from "../../functions/backend";
import { Table, Layout } from "antd";

//import { userData } from "./TestData";

// true: use hard coded data in TestData.js
// false: fetch data from the server
//const USE_TEST_DATA = false;

const { Content } = Layout;

export default function AdminUsersContent() {
  const navigate = useNavigate();
  const checkResponseFail = useCheckResponseFail();
  const [loading, setLoading] = useState(false);
  const { dataSource, setDataSource, columns } = useUserTable();

  async function getUser() {
    const response = await backend.get("/v1/user");
    if (response.items) {
      let key = 0;
      response.items.forEach((element) => {
        element.key = key;
        key++;
      });
      setDataSource(response.items);
      setLoading(false);
    }
  }
  /*
  useEffect(() => {
    console.log("dataSource has changed");
    console.log(dataSource);
  }, [dataSource]);
  */

  useEffect(() => {
    if (getMoneyFlowToken() === "") {
      navigate("/", { replace: true });
    }
    setLoading(true);
    getUser();

    /*

    fetch( + "/v1/user", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-access-token": loginId,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setDataSource(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        console.log("Got the data");
        console.log(dataSource);
      });
      */
  }, []);

  return (
    <Content
      style={{
        margin: "24px 16px 0",
        minHeight: "75vh",
        overflow: "initial",
      }}
    >
      <div
        className="site-layout-background"
        style={{ padding: 24, textAlign: "left" }}
      >
        <h1>Admin Users</h1>
        <Link to="/admin">Back to Admin Dashboard</Link>
      </div>
      <Table loading={loading} dataSource={dataSource} columns={columns} />
    </Content>
  );
}
