import { useState, useRef, useEffect } from "react";
import { twoDecimals } from "../../functions/currency";
import styled from "styled-components";
import getIcontSVG, {
  categoryNames,
  categoryColors,
} from "../../pages/ActiveTracker/DrawerComponents/IconHelper";
import { UpOutlined } from "@ant-design/icons";
import { Form, InputNumber, Input, DatePicker } from "antd";

export const SmallText = styled.div`
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 10;
  background-color: white;
  flex-grow: 0;
  display: inline-block;
  padding: 0 4px;
  color: #a0a0a0;
  font-size: 12px;
  max-height: 21px;
`;
export const StyleInput = styled.input`
  height: 49px;
  width: 322px;
  border-radius: 15px;
  background: #f6f6f6;
  border: 1.5px solid #d9d9d9;
  padding-left: 15px;
`;

export const AntStyleDateSelector = styled(DatePicker)`
  height: 49px;
  width: 322px;
  border-radius: 15px;
  background: #f6f6f6;
  border: 1.5px solid #d9d9d9;
  padding-left: 15px;
`;
export const AntDateSelector = ({ label, name }) => {
  return (
    <AntGreyTextEntryContainer>
      <SmallText>{label}</SmallText>
      <Form.Item name={name}>
        <AntStyleDateSelector />
      </Form.Item>
    </AntGreyTextEntryContainer>
  );
};
export const AntStyleInput = styled(Input)`
  height: 49px;
  width: 322px;
  border-radius: 15px;
  background: #f6f6f6;
  border: 1.5px solid #d9d9d9;
  padding-left: 15px;
`;
export const AntStyleNumberInput = styled(InputNumber)`
  height: 49px;
  width: 322px;
  border-radius: 15px;
  background: #f6f6f6;
  border: 1.5px solid #d9d9d9;
  padding-left: 15px;
`;

export const AntGreyTextEntryContainer = styled.div`
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  .ant-form-item {
    margin-bottom: 10px;
  }
`;

export const AntGreyTextEntry = ({ label, starterText, name, focus }) => {
  return (
    <AntGreyTextEntryContainer>
      <SmallText>{label}</SmallText>
      <Form.Item name={name}>
        <AntStyleInput
          autoFocus={focus}
          // use Form's initialValues instead of Form.Item's defaultValue
          //defaultValue={starterText ? starterText : ""}
        />
      </Form.Item>
    </AntGreyTextEntryContainer>
  );
};

// currently unused
/*
export const AntGreyNumberEntry = ({ label, starterText, name, focus }) => {
  return (
    <AntGreyTextEntryContainer>
      <SmallText>{label}</SmallText>
      <Form.Item name={name}>
        <AntStyleNumberInput
          autoFocus={focus}
          defaultValue={starterText ? starterText : ""}
        />
      </Form.Item>
    </AntGreyTextEntryContainer>
  );
};
*/

// currently unused
/*
export const GreyTextEntry = ({ label, starterText }) => {
  // const label = "Expense Label";

  return (
    <div style={{ position: "relative", marginTop: "20px" }}>
      <SmallText>{label}</SmallText>

      <StyleInput
      //defaultValue={starterText ? starterText : ""}
      />
    </div>
  );
};
*/

export const GreyDropDown = ({
  category,
  setCategory,
  defaultDisplay = null,
}) => {
  const SVG = getIcontSVG(category ? category : "other");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDocumentClick = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      console.log("clicked outside");
      // When the dropdown is open and user clicks outside of the dropdown
      // then the dropdown closes.
      setIsOpen(false);
      event.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const categoryButtons = [];

  // If we are using this in a header, we want to include "All"/"Money Wheel", but not "Deposit", "Income", or "Transfer"
  for (
    let i = defaultDisplay !== null ? 0 : 1;
    i < categoryNames.length - (defaultDisplay !== null ? 3 : 0);
    i++
  ) {
    const tempSVG = getIcontSVG(i);
    categoryButtons.push(
      <CategoryButton
        className="inList"
        type="button"
        key={i}
        onClick={(event) => {
          console.log("clicked selection from open dropdown");
          // From an open dropdown, clicked on one of the selections.
          setCategory(i);
          setIsOpen(false);
          event.stopPropagation();
        }}
      >
        <Icon as={tempSVG} />
        <p>{categoryNames[i]}</p>
      </CategoryButton>,
    );
  }
  return defaultDisplay !== null ? (
    <>
      <div
        ref={buttonRef}
        onClick={(event) => {
          setIsOpen(!isOpen);
          console.log("dropdown clicked");
          event.stopPropagation();
        }}
        style={
          {
            //outline: "1px solid red",
            //marginRight: "100px",
          }
        }
      >
        {defaultDisplay}
      </div>
      {isOpen && (
        <CategoryDropdown absolute={true} ref={dropdownRef}>
          {categoryButtons}
        </CategoryDropdown>
      )}
    </>
  ) : (
    <CategorySelectContainer>
      <SmallText>{"Category"}</SmallText>
      <CategoryButton
        type="button"
        data-cy="CategoryButton"
        ref={buttonRef}
        onClick={(event) => {
          setIsOpen(!isOpen);
          console.log("dropdown clicked");
          event.stopPropagation();
        }}
      >
        <Icon as={SVG} />
        <p>{categoryNames[category] ? categoryNames[category] : category}</p>
        <DownArrowIcon />
      </CategoryButton>
      {isOpen && (
        <CategoryDropdown ref={dropdownRef}>{categoryButtons}</CategoryDropdown>
      )}
    </CategorySelectContainer>
  );
};

export const CategorySelectContainer = styled.div`
  position: relative;
  margin-top: 20px;
  height: 49px;
  width: 230px;
  border-radius: 15px;
  background: #f6f6f6;
  border: 1.5px solid #d9d9d9;
  padding-left: 15px;
  padding: 5px;
`;
export const CategoryButton = styled.button`
  display: flex;
  background: white;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 0px #0b0d1640;
  align-items: center;
  width: 100%;
  max-height: 100%;
  &.inList {
    box-shadow: none;
    border-radius: 0;
  }
`;

const CategoryDropdown = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 0px #0b0d1640;
  align-items: center;
  width: 100%;
  height: 200px;
  z-index: 10;
  overflow: auto;
  ${(props) =>
    props.absolute &&
    `
  position: absolute;
  left: 0;
  `}
`;

export const DownArrowIcon = styled(UpOutlined)`
  transform: rotate(180deg);
  flex: 1;
`;
export const Icon = styled.svg`
  height: 30px;
  width: 30px;
  margin-right: 10px;
  margin-left: 10px;
`;

// currently unused
/*
export const SplitButton = styled.button`
  //this position relative doesn't seem to do anything so I commented it out -Aidan 11/8/24
  // position: relative;
  margin-top: 20px;
  background-color: #ffffff;

  border-radius: 15px;
  border: 2.37px solid #fffa8a;
  // border: 2.37px solid #ffde33;

  box-shadow: 0px 0px 6px 0px #d4d4d4cc;
  width: 100px;
  padding-top: 8px;
  padding-bottom: 8px;

  // border-image-source: linear-gradient(180deg, #fffa8a 0%, #ffde33 100%);
  // border-image-slice: 1;
`;
*/

export const AntSplitEntry = ({ total, name, rules, focus }) => {
  //const maxSplit = total ? total : -1;
  return (
    <SplitEntryContainer>
      <SmallText>{"Split Amount"}</SmallText>
      <Form.Item name={name} rules={rules} hasFeedback>
        <AntStyleInput
          autoFocus={focus}
          type="number"
          step={0.01}
        ></AntStyleInput>
      </Form.Item>
      <TotalReminderText>
        Of {total ? twoDecimals(total) : -1}
      </TotalReminderText>
    </SplitEntryContainer>
  );
};

// currently unused
/*
export const SplitEntry = (props) => {
  const total = props.total ? twoDecimals(props.total) : 35;
  return (
    <SplitEntryContainer>
      <SmallText>{"Split Amount"}</SmallText>
      <StyleInput type="number" step={0.01}></StyleInput>
      <TotalReminderText>Of {total}</TotalReminderText>
    </SplitEntryContainer>
  );
};
*/

export const SplitEntryContainer = styled.div`
  position: relative;
  margin-top: 20px;
`;

export const TotalReminderText = styled.p`
  position: absolute;
  top: 2px;
  right: 40px;
  color: #b6b6b6;
`;

export const CreateSplitButton = styled.button`
  background: linear-gradient(180deg, #fffa8a -25.51%, #ffde33 258.16%);
  box-shadow: 0px 2px 8px 0px #0b0d1626;
  margin-top: 20px;
  border-radius: 40px;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  width: 100%;
`;

export const IconAndText = ({ category }) => {
  const SVG = getIcontSVG(category ? category : "other");
  const color = categoryColors[category] ? categoryColors[category] : "#25b764";
  return (
    <IconAndTextContainer color={color}>
      <Icon as={SVG} style={{ height: "20px" }} />
      <p>{categoryNames[category] ? categoryNames[category] : category}</p>
    </IconAndTextContainer>
  );
};

const IconAndTextContainer = styled.div`
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  font-size: 16px;
  font-weight: 700;
  width: fit-content;
  padding: 5px 10px 5px 0px;
  p {
    margin: 0;
  }
`;
