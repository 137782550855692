import categoryData from "../../CFTSetup/Forms/Data";
import { ReactComponent as WheelIcon } from "../../../assets/img/Nav/wheels-icon-colored.svg";
import { ReactComponent as IncomeIcon } from "../../../assets/img/Other/income-icon.svg";

//this function will take an index or a string and return the appropriate icon
const getIcontSVG = (iconName) => {
  switch (iconName) {
    case "All":
    case "All Categories":
    case 0:
      return WheelIcon;
    case "Family":
    case 1:
      return categoryData.Family.CategoryTitleIcon;
    case "Grocery":
    case 2:
      return categoryData.Grocery.CategoryTitleIcon;
    case "Home & Garden":
    case "Home":
    case 3:
      return categoryData["Home & Garden"].CategoryTitleIcon;
    case "Entertainment":
    case 4:
      return categoryData.Entertainment.CategoryTitleIcon;
    case "Health":
    case 5:
      return categoryData.Health.CategoryTitleIcon;
    case "Transportation":
    case "Transport":
    case 6:
      return categoryData.Transportation.CategoryTitleIcon;
    case "Focus":
    case 7:
      return categoryData.Focus.CategoryTitleIcon;
    case "Other":
    case 8:
      return categoryData.Other.CategoryTitleIcon;
    case "Income":
      return IncomeIcon;
    case "Deposit":
      return IncomeIcon; // TODO: change once we have a "Deposit" icon
    case "Transfer":
      return IncomeIcon; // TODO: change once we have a "Deposit" icon
    case "Personal":
      return categoryData.Personal.CategoryTitleIcon;
    case "TransportationBus":
      return categoryData.TransportationBus.CategoryTitleIcon;
    case "TransportationCar":
      return categoryData.TransportationCar.CategoryTitleIcon;
    case "FocusWork":
    case "Work":
    case "Business":
      return categoryData.FocusWork.CategoryTitleIcon;
    case "FocusHobby":
    case "Hobby":
    case "Passion":
      return categoryData.FocusHobby.CategoryTitleIcon;
    case "FocusEducation":
    case "Education":
      return categoryData.FocusEducation.CategoryTitleIcon;

    default:
      return null;
  }
};

export default getIcontSVG;

export const categoryNames = [
  "All",
  "Personal",
  "Grocery",
  "Home",
  "Entertainment",
  "Health",
  "Transport",
  "Focus",
  "Other",
  "Deposit",
  "Income",
  "Transfer",
];

export const categoryColors = {
  All: " #68CD74",
  0: " #68CD74",
  Personal: "#0DD5CE",
  Family: "#0DD5CE",
  1: "#0DD5CE",
  Grocery: "#FFC634",
  2: "#FFC634",
  Home: "#64ABFF",
  3: "#64ABFF",
  Entertainment: "#FF773D",
  4: "#FF773D",
  Health: "#F11731",
  5: "#F11731",
  Transport: "#8729FF",
  6: "#8729FF",
  Focus: "#FF1292",
  7: "#FF1292",
  Other: "#68CD74",
  8: "#68CD74",
};
