import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import backend from "../../functions/backend";

import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import { TopNavBarPersonal } from "../../components/PageComponents/TopNavBar";
import FormFooter from "../../components/UI/FormFooter";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";

import styled from "styled-components";
import { PageTitle } from "../../styles/styledPageComponents";
import "../../styles/IPadLook.css";
import { Form, Button } from "antd";

function AccountsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.state;
  // currentPage is null, if no state was passed via Link or navigate.

  const [list, setList] = useState([]);
  const [form1] = Form.useForm();

  async function init() {
    let checkingList = await backend.get(
      "/v1/account/deposit?deposit_account_type=checking",
    );
    let savingsList = await backend.get(
      "/v1/account/deposit?deposit_account_type=savings",
    );
    let creditCardList = await backend.get(
      "/v1/account/credit?credit_account_type=credit_card",
    );

    var showablelist = [];

    showablelist = showablelist.concat(checkingList.items);
    showablelist = showablelist.concat(savingsList.items);
    showablelist = showablelist.concat(creditCardList.items);

    setList(showablelist);
  }

  function getShowableParams(list) {
    var ret = [];
    for (var i = 0; i < list.length; i++) {
      ret.push({
        name: list[i].name,
        current_balance: list[i].current_balance,
        account_number: list[i].account_number,
      });
    }
    return ret;
  }

  window.onload = init;
  useEffect(() => {
    if (list.length === 0) {
      init();
    }
  }, [list]);

  function onFinish(values) {
    navigate(currentPage ? currentPage : "/money-wheel");
  }

  function navBack() {
    navigate(currentPage ? currentPage : "/money-wheel");
  }

  function addAccount() {
    navigate("/link-account");
  }
  function removeAccount() {}
  function viewAccount() {}

  const selectedValue = Form.useWatch("beforeTax", form1);
  function onFinishFailed(errorInfo) {}

  return (
    <div className="screen">
      <MoneyFlowHeader />
      <TopNavBarPersonal />
      <div className="tracker-form-split">
        <AccountsContainer>
          <div id="changeForm">
            <Form
              layout="vertical"
              name="form_registration"
              onFinish={onFinish}
              form={form1}
            >
              <PageTitle>Accounts</PageTitle>
              <ul>
                <Form.Item name="add">
                  <Button onClick={addAccount}>Add</Button>
                </Form.Item>
                {list &&
                  list.map((value, index) => (
                    <li key={value.name}>
                      <span>{value.name}</span>
                      <span>&nbsp;{value.account_number}</span>
                      <span>&nbsp;{value.current_balance}</span>
                      <AccountButtons>
                        <Form.Item name="view">
                          <Button
                            onClick={() => {
                              viewAccount(index);
                            }}
                          >
                            View
                          </Button>
                        </Form.Item>
                      </AccountButtons>
                    </li>
                  ))}
              </ul>
              <FormFooter
                onNextButton={selectedValue ? "enabled" : "disabled"}
              />
            </Form>
          </div>
        </AccountsContainer>
      </div>
      <BottomNavBar />
    </div>
  );
}

export default AccountsPage;

const AccountsContainer = styled.div`
  font-family: TitleFont;
  font-size: 1rem; /* 20/16=1.25rem */
  font-weight: 700;
  line-height: 1.6875rem; /* 27/16 */
  text-right: center;
`;

const AccountButtons = styled.div`
  display: flex;
`;
