import backend from "../../functions/backend";
import { Modal } from "antd";

// custom React hook
export function useDeleteUser(setDataSource) {
  // IN: email: string
  // RETURNS: the part after the `@`
  // or null, if invalid email
  function getEmailDomain(email) {
    if (typeof email !== "string") {
      return null;
    }

    const atIndex = email.indexOf("@");
    if (atIndex === -1) {
      return null;
    }

    return email.substring(atIndex + 1);
  }

  const showConfirm = (email, fullName, userId) => {
    Modal.confirm({
      title: "Delete User?",
      content: `Do you want to delete ${fullName} ${email}?`,
      onOk() {
        console.log("OK");
        deleteUser(userId, email);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // If it's a known test email, then skip the confirm dialog.
  // eg *@a.com
  // eg cypress*@email.com
  function fasttrackDeleteUser(email, fullName) {
    const domain = getEmailDomain(email);
    return domain === "a.com" || email.startsWith("cypress");
  }

  // Deletes the user with the specified id (and corresponding email)
  // from the backend database and the displayed table.
  async function deleteUser(id, email) {
    const response = await backend.delete(`/v1/user?id=${id}`);
    //checkResponseFail(response, `Failed to delete user id=${id}`);
    if (response.success) {
      // update visible table on the page
      setDataSource((prevData) =>
        prevData.filter((item) => item.email_address !== email),
      );
      console.log(`Deleted user with id=${id} ${email}`);
    } else {
      console.warn(`ERROR: Failed to delete user with id=${id} ${email}`);
    }
  }

  async function handleDeleteUser(row) {
    console.log("Delete button clicked");
    const email = row.email_address;
    console.log(email);
    const userId = row.id;
    console.log(userId);
    const fullName = `"${row.first_name} ${row.last_name}"`;
    console.log(fullName);
    if (!row || !row.id) {
      console.error("Can't delete user that has no id");
      return;
    }
    if (fasttrackDeleteUser(email, fullName)) {
      deleteUser(userId, email);
      return;
    }
    showConfirm(email, fullName, userId);
  }

  return { handleDeleteUser };
}
