import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Layout } from "antd";
import backend from "../../functions/backend";
import { prettyPrintDateTime } from "../../functions/timeDate";
import { setupStepString } from "./stat";

const { Content } = Layout;

// Works for Unicode characters.
// Ignores case and diacritics.
// Currently hardcoded to 'en' language.
const alphabeticalSort = (a, b) => {
  return a.localeCompare(b, "en", { sensitivity: "base" });
};

// Cycle between 'ascend' and 'descend'.
// The last 'ascend' is to tell Ant, not to cycle back to 'no sort'.
const ASCEND_DESCEND = ["ascend", "descend", "ascend"];

const columns = [
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    sorter: (a, b) => alphabeticalSort(a.username, b.username),
    sortDirections: ASCEND_DESCEND,
  },
  {
    title: "First Name",
    dataIndex: "first_name",
    key: "firstName",
    sorter: (a, b) => alphabeticalSort(a.first_name, b.first_name),
    sortDirections: ASCEND_DESCEND,
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "lastName",
    sorter: (a, b) => alphabeticalSort(a.last_name, b.last_name),
    sortDirections: ASCEND_DESCEND,
  },
  {
    title: "Email Address",
    dataIndex: "email_address",
    key: "email",
    sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    sortDirections: ASCEND_DESCEND,
  },
  {
    title: "Last Login",
    dataIndex: "last_login",
    key: "lastLogin",
    //sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    //sortDirections: ASCEND_DESCEND,
    render: (last_login) => {
      return <p>{prettyPrintDateTime(last_login)}</p>;
    },
  },
  {
    title: "Created Account",
    dataIndex: "created_datetime",
    key: "createdDatetime",
    //sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    //sortDirections: ASCEND_DESCEND,
    render: (created_datetime) => {
      return <p>{prettyPrintDateTime(created_datetime)}</p>;
    },
  },
  {
    title: "Last Changed",
    dataIndex: "last_changed_datetime",
    key: "lastChangedDatetime",
    //sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    //sortDirections: ASCEND_DESCEND,
    render: (last_changed_datetime) => {
      return <p>{prettyPrintDateTime(last_changed_datetime)}</p>;
    },
  },
  {
    title: "Farthest Setup Step",
    dataIndex: "money_wheel_setup_step",
    key: "moneyWheelSetupStep",
    //sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    //sortDirections: ASCEND_DESCEND,
    render: (money_wheel_setup_step) => {
      return <p>{setupStepString(money_wheel_setup_step)}</p>;
    },
  },
  {
    title: "Setup Complete?",
    dataIndex: "is_setup_complete",
    key: "isSetupComplete",
    //sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    //sortDirections: ASCEND_DESCEND,
    render: (is_setup_complete) => {
      return <p>{is_setup_complete ? "Y" : "N"}</p>;
    },
  },
  {
    title: "Last Monthly Review",
    dataIndex: "last_review",
    key: "lastReview",
    //sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    //sortDirections: ASCEND_DESCEND,
  },
];

export default function AdminUsersStatContent() {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  async function getUserSettings(userId) {
    const response = await backend.get(
      "/v1/money_wheel/settings?user_id=" + userId,
    );
    if (response.items && response.items.length) {
      return response.items[0]; // settings object
    }
    return null;
  }

  async function getUser() {
    let response = await backend.get("/v1/user");
    if (response.items) {
      let key = 0;
      for (const user of response.items) {
        user.key = key;
        key++;
        const moneyWheelSettings = await getUserSettings(user.id);
        if (moneyWheelSettings) {
          user.money_wheel_setup_step = moneyWheelSettings.setup_step;
          user.is_setup_complete = moneyWheelSettings.is_setup_complete;
          user.last_review = `${moneyWheelSettings.last_review_year}/${moneyWheelSettings.last_review_month}`;
          if (user.last_review === "2001/1") user.last_review = "none";
        } else {
          user.money_wheel_setup_step = "none";
          user.is_setup_complete = false;
          user.last_review = "none";
        }
      }

      setDataSource(response.items);
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    getUser();
  }, []);

  return (
    <Content
      style={{
        margin: "24px 16px 0",
        minHeight: "75vh",
        overflow: "initial",
      }}
    >
      <div
        className="site-layout-background"
        style={{ padding: 24, textAlign: "left" }}
      >
        <h1>Admin Users Stat</h1>
        <Link to="/admin">Back to Admin Dashboard</Link>
      </div>
      <Table loading={loading} dataSource={dataSource} columns={columns} />
    </Content>
  );
}
