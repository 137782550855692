export const HELP_FAQ = [
  {
    question: "How to add an account?",
    answer:
      "In the bottom navbar, click on the Personal icon.  Then in the top navbar, click on Accounts.  Then select the Add button.",
  },
  {
    question: "How to adjust your budget?",
    answer: `<ol><li>Go to your Money Wheel, by clicking on the Wheels icon in the bottom navbar.</li>
       <li>Click on the wheel category where you want to adjust the budget.</li>
       <li>Open the drawer and click on the Planned tab.</li>
       <li>Click on the orange Edit button.</li></ol>`,
  },
  {
    question: "How to make a product suggestion?",
    answer: "Click on the Report an Issue tab at the top of this page.",
  },
];
