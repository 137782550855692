import { useEffect } from "react";
import { SubcatList } from "./SubcatList";
import { useCategoryData } from "./Data";

export const SubcatForm = ({ category, onFinish, previousPage }) => {
  const [getCategoryData] = useCategoryData();
  useEffect(() => {
    console.log(`category has changed to ${category}`);
  }, [category]);

  return (
    <SubcatList
      {...getCategoryData(category)}
      nextPage={onFinish}
      previousPage={previousPage}
    />
  );
};
