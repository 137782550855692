import styled from "styled-components";
//import { ReactComponent as MinusCircle } from "../../../assets/img/Other/MinusCircle.svg";
//import { ReactComponent as IncomeIcon } from "../../../assets/img/Other/income-icon.svg";
import backend from "../../../functions/backend";
//import { categoryNames } from "./IconHelper";
import { monthABV } from "../../../functions/timeDate";
import { useState, useEffect } from "react";
import SetBudgetComponent, {
  DisplayBudgetComponent,
} from "../../BudgetSetup/SetBudgetComponent";

export default function PlanContent({
  budgetInfo,
  changePlanAmount,
  category,
  viewMonth,
  viewYear,
}) {
  //const [editing, setEditing] = useState(false);
  const [plannedAmount, setPlannedAmount] = useState(budgetInfo.budgetAmount);
  const [lastMonthValues, setLastMonthValues] = useState(null);
  const [lastYearValues, setLastYearValues] = useState(null);
  useEffect(() => {
    setPlannedAmount(budgetInfo.budgetAmount);
  }, [budgetInfo.budgetAmount]);
  async function patchInfo(amount) {
    const values = { planned: amount };
    changePlanAmount(values);
  }
  async function getPastValues() {
    let query = "/v1/money_wheel/budget?year=";
    // for "/v1/money_wheel/budget/", month can be 1..12
    if (viewMonth - 1 === 0) {
      // last month is Dec of previous year
      query += viewYear - 1 + "&month=" + 12;
    } else {
      query += viewYear + "&month=" + (viewMonth - 1);
    }
    query += "&tx_category_id=" + budgetInfo.categoryID;

    let budget = await backend.get(query);
    console.log("last month's budget");
    //process the values
    if (budget.success && budget.items.length > 0) {
      setLastMonthValues({
        planned: Math.round(budget.items[0].budget_amount),
        spent: -1 * Math.round(budget.items[0].actual_amount),
      });
    } else {
      console.error("Error getting last month's budget");
      console.log(budget);

      /*
      // for testing only, sample data
      setLastMonthValues({
        planned: 522,
        spent: 311,
        //spent: -311,  // eg negative, because got more refunds, than spent
      });
      */
    }

    query =
      "/v1/money_wheel/budget?year=" + (viewYear - 1) + "&month=" + viewMonth;
    query += "&tx_category_id=" + budgetInfo.categoryID;
    budget = await backend.get(query);
    console.log("last year's budget");
    if (budget.success && budget.items.length > 0) {
      setLastYearValues({
        planned: Math.round(budget.items[0].budget_amount),
        spent: -1 * Math.round(budget.items[0].actual_amount),
      });
    } else {
      console.error("Error getting last years's budget");
      console.log(budget);
    }
  }
  useEffect(() => {
    getPastValues();
  }, [viewMonth, viewYear, category]);

  /*
  function spentString(values) {
    if (!values) {
      // handle when the server doesn't return any budget values...
      return "$";
    }
    // if applicable, put the negative sign before the $, not after.
    return `${values.spent < 0 ? "-" : ""}$${Math.abs(values.spent)}`;
  }
    */

  return (
    <MainContainer>
      <SetBudgetComponent
        category={category}
        budgetedAmount={budgetInfo.budgetAmount}
        spentAmount={budgetInfo.actualSpend}
        remainingAmount={budgetInfo.remaining}
        setBudgetedAmount={patchInfo}
        finishedFunction={() => {}} //this will never be used
      ></SetBudgetComponent>
      {lastMonthValues && (
        <DisplayBudgetComponent
          date={
            "Last Month: " +
            monthABV[viewMonth - 1 === 0 ? 11 : viewMonth - 2] +
            " " +
            (viewMonth - 1 === 0 ? viewYear - 1 : viewYear)
          }
          budgetedAmount={lastMonthValues.planned}
          spentAmount={lastMonthValues.spent}
          remainingAmount={lastMonthValues.planned - lastMonthValues.spent}
        ></DisplayBudgetComponent>
      )}
      {lastYearValues && (
        <DisplayBudgetComponent
          date={"Last Year: " + monthABV[viewMonth - 1] + " " + (viewYear - 1)}
          budgetedAmount={lastYearValues.planned}
          spentAmount={lastYearValues.spent}
          remainingAmount={lastYearValues.planned - lastYearValues.spent}
        ></DisplayBudgetComponent>
      )}
    </MainContainer>
  );
}

const MainContainer = styled.div`
  background: #ffffff;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 73px; //bottom navbar height
  & > * {
    flex: 0;
  }
`;
