import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const VideoContainer = styled.div`
  position: absolute;
  top: ${({ y }) => `${y}px`};
  left: ${({ x }) => `${x}px`};
  z-index: 1000;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;

  // no cropping.  keep aspect ratio.  may cause black bars.
  object-fit: contain;

  background-color: white;

  // to help with testing scale and position of video...
  //outline: 2px solid red;
  //display: none;
  //width: 90%;
  //height: 90%;
`;

// IN: src: path to the video file
// IN: x,y is top,left corner of the tour video
function NewExpenseReviewTour({ src, x, y }) {
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  // styled-components may interfere with the autoplay.
  // So video.play() after the component has fully rendered.
  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const playPromise = video.play();

      playPromise
        .then(() => {
          // Autoplay started successfully.
        })
        .catch((error) => {
          // Autoplay was prevented.
          console.error("Autoplay prevented:", error);
        });
    }
  }, []);

  return (
    <VideoContainer x={x} y={y}>
      <Video
        ref={videoRef}
        src={src}
        type="video/mp4"
        //autoPlay
        loop
        muted
        //muted={isMuted}
        // muted attribute is required for autoplay to work on many browsers.
        playsInline // required by iOS
        controls
      >
        Your browser does not support the video tag.
      </Video>
    </VideoContainer>
  );
}

export default NewExpenseReviewTour;
