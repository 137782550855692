import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import { getMoneyFlowToken } from "../../state/stateApplication";
import { Layout } from "antd";

const { Content } = Layout;

export default function AdminNotificationsContent() {
  const navigate = useNavigate();
  const checkResponseFail = useCheckResponseFail();
  useEffect(() => {
    if (getMoneyFlowToken() === "") {
      navigate("/", { replace: true });
    }
  }, []);
  return (
    <Content
      style={{
        margin: "24px 16px 0",
        minHeight: "75vh",
        overflow: "initial",
      }}
    >
      <div
        className="site-layout-background"
        style={{ padding: 24, textAlign: "left" }}
      >
        <h1>Admin Notifications</h1>
        <Link to="/admin">Back to Admin Dashboard</Link>
      </div>
    </Content>
  );
}
