import { useRef } from "react";
import Tracker from "./Tracker";
import getCategoryIcons from "./MoneyWheelIcons";
import {
  Text_Below_Position_Angle_Map,
  Text_Above_Position_Angle_Map,
  Radius_Map,
  Size_Map,
} from "./MoneyWheelPlacedSVG";
import "./style.css";

export default function CFTSetupWheel(props) {
  const childRefs = useRef(null);

  // start w/ N category, and go clockwise
  const categoryLabel = [
    "Personal",
    "Home",
    "Transport",
    "Focus",
    "Other",
    "Health",
    "Entertain",
    "Grocery",
  ];

  return (
    <>
      <Tracker
        {...props}
        categoryIcons={getCategoryIcons(props.config)}
        categoryLabel={categoryLabel}
        Text_Below_Position_Angle_Map={Text_Below_Position_Angle_Map}
        Text_Above_Position_Angle_Map={Text_Above_Position_Angle_Map}
        Radius_Map={Radius_Map}
        Size_Map={Size_Map}
        ref={childRefs}
        // activeSlices={activeSlices}
      />
    </>
  );
}
