import { createContext, useContext, useState } from "react";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [moneyWheelData, setMoneyWheelData] = useState(null);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  // errorMsg clears when a new ErrorNoticeBar is created
  const [errorMsg, setErrorMsg] = useState("");
  // lastErrorMsg persists, even when a new ErrorNoticeBar is created
  const [lastErrorMsg, setLastErrorMsg] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [popoverTextKey, setPopoverTextKey] = useState(null);

  const AppStates = {
    NORMAL: 0,
    CLOSE_MODALS_AND_DRAWERS: 1,
  };
  const [appState, setAppState] = useState(AppStates.NORMAL);

  const PopoverTextMap = {
    NEXT_BUTTON: "nextButton",
  };

  return (
    <AppContext.Provider
      value={{
        moneyWheelData,
        setMoneyWheelData,
        showErrorMsg,
        setShowErrorMsg,
        errorMsg,
        setErrorMsg,
        lastErrorMsg,
        setLastErrorMsg,
        AppStates,
        appState,
        setAppState,
        PopoverTextMap,
        isPopoverOpen,
        setIsPopoverOpen,
        popoverTextKey,
        setPopoverTextKey,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Define custom hook to allow access to the values
// made available by AppProvider.
// useContext is how you access a context value.
// So now you don't need to export AppContext.
export const useApp = () => useContext(AppContext);

export default AppProvider;
