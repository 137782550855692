import { BigBlueButton } from "./StyleComponents";
import styled from "styled-components";
import { GoalOverviewPercent } from "./GoalOverview";
import getIcontSVG from "../IconHelper";
import { useState } from "react";

import { ReactComponent as cashIcon } from "../../../assets/img/GoalWheel/goal-cash-icon.svg";

export default function ShortContent({
  setGoalModalOpen,
  categoryNames,
  currentCategoryInt,
  totalApplied,
  totalNeeded,
  totalAssets,
  goalData,
  isTour,
  startTour,
}) {
  const [showStartButton, setShowStartButton] = useState(isTour);

  if (showStartButton) {
    console.log("its tour in the drawer!");
    return (
      <div>
        <p style={{ textAlign: "center", fontSize: "1.5rem" }}>
          Start Your Goal Wheel Setup
        </p>
        <StartButtonDiv>
          <button
            onClick={() => {
              startTour();
              setShowStartButton(false);
            }}
          >
            Go
          </button>
        </StartButtonDiv>
      </div>
    );
  }

  const SVG = getIcontSVG(currentCategoryInt);
  const dateString =
    new Date().getMonth() +
    1 +
    "/" +
    new Date().getDate() +
    "/" +
    new Date().getFullYear();
  return (
    <div style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <SVG height={"24px"} />
          <HeaderText fontSize={"24px"}>
            {currentCategoryInt === 8
              ? "MoneyFlow Buffer"
              : categoryNames[currentCategoryInt]}
          </HeaderText>
        </div>
        <DateText>{dateString}</DateText>
      </div>
      <GoalOverviewPercent
        category={currentCategoryInt}
        totalApplied={totalApplied}
        totalNeeded={totalNeeded}
        totalAssets={totalAssets}
        percent={20}
      />
    </div>
  );
}

const HeaderText = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
  line-height: 33px;
  margin-right: auto;
  margin-left: 10px;
`;

const DateText = styled.p`
  font-size: 18px;
  line-height: 25px;
  text-align: right;
  color: #9d9d9d;
`;

const StartButtonDiv = styled.div`
  border-radius: 15px;
  border: 1.5px solid #2180f4;
  height: 10rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  button{
    background-color: #66AEF6;
    color: white;
    border: none;
    height: 40%;
    width: 60%;
    border-radius: 25px;
    font-size: 2rem;
  }
  }

`;
