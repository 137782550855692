// import { ReactComponent as singleIcon } from "../../assets/img/GoalWheel/goal-single-icon.svg";
// import { ReactComponent as single1KidIcon } from "../../assets/img/GoalWheel/goal-single-1kid-icon.svg";
// import { ReactComponent as single2KidIcon } from "../../assets/img/GoalWheel/goal-single-2kid-icon.svg";
// import { ReactComponent as single3KidIcon } from "../../assets/img/GoalWheel/goal-single-3kid-icon.svg";
// import { ReactComponent as single4KidIcon } from "../../assets/img/GoalWheel/goal-single-+kid-icon.svg";

// import { ReactComponent as coupleIcon } from "../../assets/img/GoalWheel/goal-couple-icon.svg";
// import { ReactComponent as couple1KidIcon } from "../../assets/img/GoalWheel/goal-couple-1kid-icon.svg";
// import { ReactComponent as couple2KidIcon } from "../../assets/img/GoalWheel/goal-couple-2kid-icon.svg";
// import { ReactComponent as couple3KidIcon } from "../../assets/img/GoalWheel/goal-couple-3kid-icon.svg";
// import { ReactComponent as couple4KidIcon } from "../../assets/img/GoalWheel/goal-couple-+kid-icon.svg";

// import { ReactComponent as carIcon } from "../../assets/img/GoalWheel/goal-car-icon.svg";
// import { ReactComponent as cashIcon } from "../../assets/img/GoalWheel/goal-cash-icon.svg";
// import { ReactComponent as getawayIcon } from "../../assets/img/GoalWheel/goal-getaway-icon.svg";
// import { ReactComponent as homeIcon } from "../../assets/img/GoalWheel/goal-home-icon.svg";
// import { ReactComponent as retireIcon } from "../../assets/img/GoalWheel/goal-retire-icon.svg";
// import { ReactComponent as travelIcon } from "../../assets/img/GoalWheel/goal-travel-icon.svg";
//import { ReactComponent as personalIcon } from "../../assets/img/GoalWheel/goal-personal-icon.svg";

// function getFamilyIcon(config) {
//   const familyIcons = [
//     [
//       singleIcon,
//       single1KidIcon,
//       single2KidIcon,
//       single3KidIcon,
//       single4KidIcon,
//     ],
//     [
//       coupleIcon,
//       couple1KidIcon,
//       couple2KidIcon,
//       couple3KidIcon,
//       couple4KidIcon,
//     ],
//   ];
//   const numKids = config.family.kidAgeRanges.length;
//   const familyIcon = familyIcons[+config.family.married][numKids];
//   return familyIcon;
// }

// export default function getCategoryIcons(config) {
//   const categoryIcons = {
//     N_Icon: coupleIcon,
//     NE_Icon: homeIcon,
//     E_Icon: carIcon,
//     SE_Icon: getawayIcon,
//     S_Icon: retireIcon,
//     SW_Icon: cashIcon,
//     W_Icon: travelIcon,
//     NW_Icon: getFamilyIcon(config),
//   };
//   return categoryIcons;
// }

//import the white stroke icons (WS)
import { ReactComponent as familyWS } from "../../assets/img/GoalWheel/WhiteOutlineIcons/Family-White-Stroke.svg";
import { ReactComponent as homeWS } from "../../assets/img/GoalWheel/WhiteOutlineIcons/House-White-Stroke.svg";
import { ReactComponent as busWS } from "../../assets/img/GoalWheel/WhiteOutlineIcons/Bus-White-Stroke.svg";
import { ReactComponent as flexWS } from "../../assets/img/GoalWheel/WhiteOutlineIcons/Flex-White-Stroke.svg";
import { ReactComponent as moneyWS } from "../../assets/img/GoalWheel/WhiteOutlineIcons/Money-White-Stroke.svg";
import { ReactComponent as heartWS } from "../../assets/img/GoalWheel/WhiteOutlineIcons/Heart-White-Stroke.svg";
import { ReactComponent as entertainmentWS } from "../../assets/img/GoalWheel/WhiteOutlineIcons/Entertainment-White-Stroke.svg";
import { ReactComponent as moneyFlowerWS } from "../../assets/img/GoalWheel/WhiteOutlineIcons/MoneyFlower-White-Stroke.svg";

export default function getCategoryIcons(config) {
  const categoryIcons = {
    N_Icon: familyWS,
    NE_Icon: homeWS,
    E_Icon: busWS,
    SE_Icon: flexWS,
    S_Icon: moneyWS,
    SW_Icon: heartWS,
    W_Icon: entertainmentWS,
    NW_Icon: moneyFlowerWS,
  };
  return categoryIcons;
}
