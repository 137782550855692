import { useState } from "react";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import styled from "styled-components";

import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import { TopNavBarHelp } from "../../components/PageComponents/TopNavBar";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";

import * as SP from "../../styles/styledPageComponents";
import { Screen } from "../../styles/styledScreen";
import { PageTitle, BodyText } from "../../styles/styledPageComponents";
import "../../styles/IPadLook.css";
import { HELP_FAQ } from "./HelpFAQText";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FAQWrapper onClick={() => setIsOpen(!isOpen)}>
      <Question>{question}</Question>
      <Answer isOpen={isOpen}>
        <div>{parse(answer)}</div>
      </Answer>
    </FAQWrapper>
  );
};

const FAQList = ({ faqs }) => {
  return (
    <FAQListWrapper>
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </FAQListWrapper>
  );
};

function HelpFAQ() {
  const location = useLocation();
  const { currentPage } = location.state || {};
  console.log("HelpPage() received currentPage =", currentPage);
  //const { n, s, b } = location.state || {};

  return (
    <Screen>
      <MoneyFlowHeader />
      <TopNavBarHelp />
      <SP.ScrollingAboveBottomNavBar>
        <PageTitle>Help FAQ</PageTitle>
        {/* <BodyText> </BodyText> */}
        <FAQList faqs={HELP_FAQ} />
      </SP.ScrollingAboveBottomNavBar>
      <BottomNavBar />
    </Screen>
  );
}

export default HelpFAQ;

const FAQWrapper = styled.div`
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  margin: 0;
`;

const Question = styled.h3`
  font-size: 22px;
  margin: 0;
  padding: 22px 0 16px 0;
`;

const Answer = styled.div`
  font-size: 16px;
  line-height: 1.5;
  max-height: ${(props) => (props.isOpen ? "1000px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};

  & ol {
    margin-top: 0;
    margin-bottom: 0;
  }
  & > div {
    padding-bottom: 22px;
  }
`;

const FAQListWrapper = styled.div`
  max-width: 430px;
  margin: 0 auto;
  padding: 0 0 20px 0;
`;
