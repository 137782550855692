import { useEffect, useState } from "react";

import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import { SingleButtonFooter } from "../../components/UI/FormFooter";
import { DataPrep } from "./DataPrep";
import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";
import { IconHelper } from "./IconHelper";

import { SubheaderText, BodyRegularText } from "../../styles/styledText";
import styled from "styled-components";
import "../../styles/IPadLook.css";
import { Screen } from "../../styles/styledScreen";
import * as SP from "../../styles/styledPageComponents";
import { Button } from "antd";
import { SyncOutlined } from "@ant-design/icons";

// IN: pageStatus: can be "initial", "plaid_link_running",
//     "plaid_link_success", "plaid_link_fail", "downloading",
//     "download_success", "download_fail"
// IN: navNext() is called when user clicks on "Connect Accounts" button
// IN: ready: boolean: Plaid Link is loaded and ready to be opened.
const PlaidSetupPage = ({ initialPageStatus, navNext, ready }) => {
  // Since we shouldn't modify the prop directly,
  // keep a copy for the component to modify.
  const [pageStatus, setPageStatus] = useState(initialPageStatus);
  useEffect(() => {
    setPageStatus(initialPageStatus);
    if (initialPageStatus === "plaid_link_success")
      setAddedAccountSuccessfully(true);
  }, [initialPageStatus]);

  const [addedAccountSuccessfully, setAddedAccountSuccessfully] =
    useState(false);

  // When showLetsSyncMsg is false, disable the Connect button
  // because it has already been clicked.
  const showLetsSyncMsg = pageStatus === "initial";

  const showSyncingMsg = pageStatus === "downloading";

  const [whileSyncingMsg, setWhileSyncingMsg] = useState(
    "Your transactions are in safe hands with our bank-level security and strong encryption.",
  );

  useEffect(() => {
    console.log(`PlaidSetupPage() ------------`);
    console.log(`PlaidSetupPage pageStatus = ${pageStatus}`);
    console.log(`PlaidSetupPage showLetsSyncMsg = ${showLetsSyncMsg}`);
    console.log(`PlaidSetupPage showSyncingMsg = ${showSyncingMsg}`);
  }, []);

  function handleAddAccount() {
    console.log("handleAddAccount");
    //navigate("/link-account", { replace: true }); // go to "Connect Accounts" Plaid setup page

    // skip the "Connect Accounts" Plaid Setup page.  go straight to Plaid Link
    navNext();
  }

  // Done adding accounts. Proceed to download tx.
  function startDownload() {
    setPageStatus("downloading");
  }

  return (
    <Screen>
      {showSyncingMsg && (
        <DataPrep
          setWhileSyncingMsg={setWhileSyncingMsg}
          setPageStatus={setPageStatus}
        />
      )}
      <MoneyFlowHeader />
      <ErrorNoticeBar />
      <SP.FormWrapper2>
        <MainContainer>
          {showSyncingMsg && (
            <>
              <LoadingIcon>
                <SyncOutlined spin />
              </LoadingIcon>
              <Text0> Syncing ... </Text0>
              <Text1>
                We are syncing your transaction data to your MoneyFlow account.
              </Text1>
            </>
          )}
          {showLetsSyncMsg && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconHelper />
              <Text0>
                Connect your Financial Accounts to Access your Personal Data
              </Text0>
              {/*
              <Text0>Let's sync your accounts</Text0>
              */}

              <Text1>
                Once you connect your bank and credit card accounts, you'll be
                able to view balances. You can manage your settings through your
                accounts page.
              </Text1>

              <Text1>
                Your data rights and security are our top priority. We use
                bank-level security protocols, and you have rights regarding
                your data. See your data rights at www.moneyflow.org
              </Text1>
            </div>
          )}
          {(showLetsSyncMsg || showSyncingMsg) && (
            <Text1>{whileSyncingMsg}</Text1>
          )}
          {pageStatus === "plaid_link_success" && (
            <>
              <Text0>Account Connection Success</Text0>
              <Text1>Would you like to add more accounts now?</Text1>
              <Button onClick={handleAddAccount}>Add more accounts</Button>
              <Text1>You can add more accounts under your Profile later.</Text1>
              <Button onClick={startDownload}>Done adding accounts</Button>
            </>
          )}
          {pageStatus === "plaid_link_fail" && (
            <>
              <Text0>Account Connection Problem</Text0>
              <Text1>
                Would you like to add accounts from another financial
                institution?
              </Text1>
              <Button onClick={handleAddAccount}>Add more accounts</Button>

              {/* Show this button, only if at least 1 account has successfully
                   been added before.
               */}
              {addedAccountSuccessfully && (
                <Button onClick={startDownload}>Done adding accounts</Button>
              )}
            </>
          )}
          {pageStatus === "download_fail" && (
            <>
              <Text0>Transaction Sync Problem</Text0>
              <Text1>
                Would you like to add accounts from another financial
                institution?
              </Text1>
              <Button onClick={handleAddAccount}>Add more accounts</Button>
            </>
          )}

          {pageStatus !== "plaid_link_success" &&
            pageStatus !== "plaid_link_fail" &&
            pageStatus !== "download_fail" && (
              <SingleButtonFooter
                onNextButton={navNext}
                nextBtnText={"Connect Accounts"}
                disabledNextButton={!ready || !showLetsSyncMsg}
              />
            )}
        </MainContainer>
        <SecondRing />
        <GradientSection />
      </SP.FormWrapper2>
    </Screen>
  );
};

export default PlaidSetupPage;

const LoadingIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
  font-size: 7rem;
`;

const Text0 = styled.div`
  ${SubheaderText};
  margin-top: 2em;
`;

const Text1 = styled.div`
  ${BodyRegularText};
  margin-top: 1em;
  width: 90%;
`;

const MainContainer = styled.div`
  width: 100vw;
  height: 60vh;
  border-radius: 0 0 60% 60%/ 0 0 30% 30%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  background: linear-gradient(to bottom, #95e08a 0%, #d9f7bd 100%);
`;

const SecondRing = styled.div`
  //   background-color: #d9f7bd;
  background-color: #eef7ee;
  width: 100vw;
  height: 68vh;
  border-radius: 0 0 60% 60%/ 0 0 25% 25%;
  position: absolute;
  z-index: 5;
`;

const GradientSection = styled.div`
  background-color: #fafafa;
  width: 100vw;
  height: 60vh;
  position: absolute;
  top: 40vh;
  z-index: 0;
`;
