import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../state/UserProvider";
import { useSettings, updateSetupStep1 } from "../../state/SettingsProvider";
import useExpenseData from "../ActiveTracker/useExpenseData";
import useDraggableExpense from "../ActiveTracker/useDraggableExpense";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import { Form, Table } from "antd";

import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import MoneyWheel from "../Tracker/MoneyWheel";
import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";
import ExpenseDetailsModal, {
  ExpenseDetailsCircleModal,
} from "../ActiveTracker/Modals/ExpenseDetailsModal";
import Drawer from "../../components/DrawerComponents/Drawer";
import { ShortContentForInitialExpenseReview } from "../ActiveTracker/DrawerComponents/ShortContent";

import { ID_LIST, CAT_LIST } from "../ActiveTracker/constants";
import { createColumnsCat } from "../ActiveTracker/tableColumns";
import { get1MonthQueryString } from "../../functions/timeDate";
import { twoDecimals } from "../../functions/currency";

//import { ExpenseReviewForm } from "./Forms/ExpenseReviewForm";
import InitTrackerConfig from "./InitTrackerConfig";
import { getMoneyFlowToken } from "../../state/stateApplication";
import backend from "../../functions/backend";
import { getLastNumMonthsQueryString } from "../../functions/timeDate";

import tourVideo from "../../assets/vid/editLabelAndDragExpense.mp4";
import NewExpenseReviewTour from "./NewExpenseReviewTour";

import { SubcatListWithTitle } from "../CFTSetup/Forms/SubcatList";
import { useCategoryData } from "../CFTSetup/Forms/Data";

import "../../styles/IPadLook.css";
import styled from "styled-components";

var viewMonth = 1; // 1..12
var viewYear = 1;

function ExpenseReview() {
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const { wheelSettings, dispatchSettings } = useSettings();
  const { formatForTable } = useExpenseData();
  const checkResponseFail = useCheckResponseFail();
  const [cat, setCat] = useState(-1); // no category selected initially
  const [expenseData, setExpenseData] = useState([]);
  const [expenseTableFlag, setExpenseTableFlag] = useState(true);
  const [maxSplit, setMaxSplit] = useState({});
  const [editID, setEditID] = useState(0);
  const { handleTableChange, draggableExpense } = useDraggableExpense(
    expenseData,
    true, // true for sort by original amount.
    // false for sort by date.
  );
  const [getCategoryData] = useCategoryData();

  const [form2] = Form.useForm();
  const [form4] = Form.useForm();
  const [currentExpenseInfo, setCurrentExpenseInfo] = useState(null);

  const [isModalOpenDetails, setIsModalOpenDetails] = useState(false);
  const [isModalOpenDetailsCircle, setIsModalOpenDetailsCircle] =
    useState(false);
  const [combined, setCombined] = useState([]);
  const [drawerLocation, setDrawerLocation] = useState(1);
  const [mb, setMb] = useState("1px");
  const [isDrawerShort, setIsDrawerShort] = useState(true);
  const [isPageLayoutComplete, setIsPageLayoutComplete] = useState(false);

  const [showTour, setShowTour] = useState(
    wheelSettings.setup_step === wheelSettings.SETUP_STEP_EXPENSE_REVIEW_TOUR,
  );
  const [beforeShowTour, setBeforeShowTour] = useState(true);

  useEffect(() => {
    console.log(
      `wheelSettings.setup_step changed to ${wheelSettings.setup_step}`,
    );
  }, [wheelSettings.setup_step]);

  useEffect(() => {
    if (getMoneyFlowToken() === "") {
      navigate("/", { replace: true });
    }
  }, []);

  function onTourNextButton() {
    updateSetupStep1(
      wheelSettings.SETUP_STEP_EXPENSE_REVIEW_SORT,
      currentUser.money_wheel_settings_id,
      dispatchSettings,
    );
    setShowTour(false); // needed to force a re-render
  }

  function getNumUnsortedExpenses() {
    console.log(`getNumUnsortedExpenses() returns ${expenseData.length}`);
    return expenseData.length;
  }

  function setCurrentDate() {
    const today = new Date();
    viewMonth = today.getMonth() + 1;
    viewYear = today.getFullYear();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  //const columnsCat = useMemo(() => createColumnsCat(editRow), []);

  // Sets the form fields for the Expense Details modal.
  // Opens the modal.
  // IN: id: the id of the expense
  // IN: isCallerTable is true when invoked from a row in the table
  //     isCallerTable is false when invoked from the circle modal
  async function editRow(id, isCallerTable = true) {
    setEditID(id);
    console.log("editRow()");
    console.log(`id=${id}`);
    console.log(`isCallerTable=${isCallerTable}`);

    let query = "/v1/tx/transaction?id=" + id;
    const response = await backend.get(query);
    console.log(response);

    let label = response.items[0].label;
    // if (!label) label = response.items[0].counterparty; // the label is distinct from counterparty
    setCurrentExpenseInfo(response.items[0]); // this is for the modal, so that it can render the correct info about the expense
    setMaxSplit(parseFloat(response.items[0].amount));
    const values = {
      // keep amount positive for the ExpenseDetails modal
      splitAmount: Math.abs(parseFloat(response.items[0].amount)),
      label: label,
      note: response.items[0].note !== null ? response.items[0].note : "",
    };
    if (isCallerTable) {
      console.error(
        "ERROR: This (isCallerTable === true) should not be happening from Initial Expense Review",
      );
      // ExpenseDetails modal for initial Expense Review,
      // unlike the active Money Wheel,
      // doesn't have category select.

      // handle additional form field initialization
      // for the modal for editing the table row
      values.category = response.items[0].tx_category_id;
      setIsModalOpenDetails(true);
      form2.setFieldsValue(values);
    } else {
      setIsModalOpenDetailsCircle(true);
      form4.setFieldsValue(values);
    }
  }

  async function handleSplit(values) {
    console.log("_______________handleSplit_______________");
    console.log("values =");
    console.log(values);
    let splitParams = {
      original_tx_id: editID,
      new_amount: twoDecimals(String(values.splitAmount)),
    };
    if (values.category) {
      splitParams.new_tx_category_id = values.category;
      splitParams.new_tx_subcategory_id = values.category * 100;
    }
    if (values.label) {
      splitParams.new_label = values.label;
    }
    if (values.note) {
      splitParams.new_note = values.note;
    }
    const querySplit = "/v1/tx/split";

    const responseSplit = await backend.post(querySplit, splitParams);
    checkResponseFail(responseSplit, "Failed to split transaction:");
  }

  async function onFinishEditDetails(values) {
    console.log("onFinishEditDetails()");
    console.log(values);
    // necessary for modals that don't have a category field
    if (values.category === undefined) values.category = 0;
    if (values.label === null) values.label = "";
    const query = "/v1/tx/transaction?id=" + editID;
    const response = await backend.get(query);
    const originalAmount = parseFloat(response.items[0].amount);
    let newNumTx = getNumUnsortedExpenses();
    if (
      (values.splitAmount >= 0.01 && values.splitAmount < originalAmount) ||
      (values.splitAmount <= -0.01 && values.splitAmount > originalAmount)
    ) {
      await handleSplit(values);
      newNumTx += 1;
    } else {
      let data = {
        id: editID,
        tx_category_id: values.category,
        tx_subcategory_id: values.category * 100,
        label: values.label,
        note: values.note,
      };
      if (values.category) {
        data.categorization_type = "user_manual";
      }
      console.log("data1:", data);
      const r1 = await backend.patch("/v1/tx/transaction", data);
      checkResponseFail(r1, "Failed to PATCH transaction:");
    }
    // Limit the number of expenses asked for to the current number of expenses.
    // Update expenseData to trigger a resort, after a split expense occurs.
    getExpenses(newNumTx);

    getCurrentCategoryExpenses(cat);
    setIsModalOpenDetails(false);
    setIsModalOpenDetailsCircle(false);
    setCat(-1);
  }

  const switchCategory = (name, num) => {
    if (num) {
      setExpenseTableFlag(false);
      getCurrentCategoryExpenses(num);
      setCat(num);
    }
  };

  function navBack() {
    setExpenseTableFlag(true);
  }

  /*
  async function getAllExpensesDebug() {
    console.log("getAllExpensesDebug(): ");
    let response = await backend.get(
      "/v1/tx/transaction");
    checkResponseFail(response, "Failed to GET getAllExpensesDebug transactions:");
    console.log(response.items);
  }
  */

  const MAX_UNCATEGORIZED_TRANSACTIONS = 12;
  //const MAX_UNCATEGORIZED_TRANSACTIONS = 2; // for testing

  // Gets the largest original amount uncategorized expenses, up to
  // MAX_UNCATEGORIZED_TRANSACTIONS, by default, from the last 2 months.
  // IN: maxTx: integer: specify max uncategorized transactions.
  //
  // Stores the data in local ExpenseData and React state expenseData.
  // expenseData only has uncategorized expenses.
  // If there a 0 expenses, goes to Congratulations page.
  async function getExpenses(maxTx = MAX_UNCATEGORIZED_TRANSACTIONS) {
    console.log("getExpenses for " + viewYear + "-" + viewMonth);
    const response = await backend.get(
      "/v1/tx/transaction?categorization_type=uncategorized" +
        "&_limit=" +
        maxTx +
        //"&_sort_by=posted_date&_sort_order=desc&posted_date=" +
        //"&_sort_by=amount&_sort_order=desc&posted_date=" +

        // sort_order is asc to get the largest abs(negative expenses)
        "&_sort_by=original_amount&_sort_order=desc&posted_date=" +
        getLastNumMonthsQueryString(2),
    );
    // FYI: to trigger error msg, set categorization_type=foo
    if (
      checkResponseFail(response, "Failed to GET uncategorized transactions:")
    )
      return null;

    console.log("getExpenses(): response.items");
    console.log(response.items);
    const ExpenseData = formatForTable(response.items);
    setExpenseData(ExpenseData);
    if (ExpenseData.length === 0) {
      console.log("getExpenses(): there are no expenses.");
      if (window.location.pathname.includes("/expense-review")) {
        navigate("/budget-setup", { replace: true });
      }
    }
  }

  async function getCurrentCategoryExpenses(Category) {
    console.log("getCurrentCategoryExpenses for category: " + Category);
    let query =
      "/v1/tx/transaction?posted_date=" +
      get1MonthQueryString(viewMonth, viewYear) +
      "&_sort_by=posted_date&_sort_order=desc&tx_category_id=" +
      Category;
    console.log("query: " + query);
    const response = await backend.get(query);
    if (
      checkResponseFail(
        response,
        "Failed to GET current category transactions:",
      )
    )
      return null;

    // Because this function is in ActiveTracker or ExpenseReview
    // (and not Income page), we are only showing Cards that have
    // tx_category_id from 1 to 8.  This is true for the All category
    // or a specific MoneyWheel category.
    const wheelCategoryItems = response.items.filter(
      (item) => 1 <= item.tx_category_id && item.tx_category_id <= 8,
    );

    const tableData = formatForTable(wheelCategoryItems);
    setCombined(tableData);
  }

  function openTallDrawer() {
    setDrawerLocation(2);
  }

  function openShortDrawer() {
    setDrawerLocation(1);
  }

  useEffect(() => {
    setCurrentDate();
    console.log("First time running ExpenseReview");
    //getAllExpensesDebug();
    getExpenses();
    setIsPageLayoutComplete(true);

    // for Cypress testing, make these functions available in the window object
    window.getNumUnsortedExpenses = getNumUnsortedExpenses;
    window.openTallDrawer = openTallDrawer;
    window.openShortDrawer = openShortDrawer;
  }, []);

  useEffect(() => {
    console.log("rerendering ExpenseReview");
  }, [expenseData]);

  const trackerConfig = InitTrackerConfig();

  async function callbackDropExpense(expense, category, array = expenseData) {
    if (expense) {
      console.log("callbackDropExpense", expense, category, array);

      let data = {
        id: expense.key,
        tx_category_id: ID_LIST[category],
        categorization_type: "user_manual",
        tx_subcategory_id: parseInt(ID_LIST[category]) * 100,
      };
      const response = await backend.patch("/v1/tx/transaction", data);
      checkResponseFail(response, "Failed to PATCH transaction:");

      const newExpenseData = array.filter((item) => item.id !== expense.id);

      // NOTE: array[0] is undefined, after dragging the last uncategorized
      // expense into a category.
      setEditID(array[0] ? array[0].key : 0);
      setExpenseData(newExpenseData);

      // FYI: setExpenseData is done asynchronously, so the log below may not be
      // accurate yet.
      console.log("EXPENSE ARRAY: ", expenseData);

      // ActiveTracker does this, but don't need it for initial ExpenseReview
      // because remaining amounts are not displayed in the wheel here.
      //getBudget();

      if (newExpenseData.length === 0) {
        console.log("All expenses categorized");
        console.log("callbackDropExpense(): there are no expenses.");
        if (window.location.pathname.includes("/expense-review")) {
          navigate("/budget-setup", { replace: true });
        }
      }
      //this makes it so that drawer will display the new expense when it is dragged in
      if (ID_LIST[category] === cat) {
        console.log("refreshing category expenses");
        console.log("category: ", category);
        getCurrentCategoryExpenses(cat);
      }
      setCat(-1);
    }
  }

  const categoryNames = {
    All: "All",
    Personal: "Family",
    Grocery: "Grocery",
    Home: "Home & Garden",
    Entertainment: "Entertainment",
    Health: "Health",
    Transport: "Transportation",
    Focus: "Focus",
    Other: "Other",
  };

  const categoryName = categoryNames[CAT_LIST[cat]];

  return (
    <div className="screen">
      <MoneyFlowHeader />
      <ErrorNoticeBar />
      <div className="tracker-form-split">
        <MoneyWheel
          dropExpense={callbackDropExpense}
          draggableExpense={draggableExpense}
          // expenseData table is sorted from newest to oldest
          nextExpense={expenseData.length > 1 ? expenseData[1] : null}
          config={trackerConfig}
          switchCategory={switchCategory}
          showNumbers={false}
          categoryFigures={{}}
          navBack={navBack}
          isExpenseReview={true}
          wheelType={"MoneyWheel"}
          editID={editID}
          EditFunction={editRow}
        />
        {/* <ExpenseReviewForm expenseCount={expenseData.length} /> */}
      </div>
      <ExpenseDetailsModal
        form2={form2}
        isOpen={isModalOpenDetails}
        setIsOpen={setIsModalOpenDetails}
        onFinish={onFinishEditDetails}
        maxSplit={maxSplit}
      />
      <ExpenseDetailsCircleModal
        form4={form4}
        isOpen={isModalOpenDetailsCircle}
        setIsOpen={setIsModalOpenDetailsCircle}
        onFinish={onFinishEditDetails}
        maxSplit={maxSplit}
        expenseInfo={currentExpenseInfo}
      />
      <Drawer
        {...{ drawerLocation, setMb, isPageLayoutComplete, setIsDrawerShort }}
        isInitialExpenseReview={true}
      >
        {!isDrawerShort && (
          <div style={{ marginBottom: mb }}>
            <TransactionsContainer>
              <Table
                dataSource={expenseTableFlag ? [...expenseData] : [...combined]}
                columns={createColumnsCat(editRow)}
                onChange={handleTableChange}
              />
            </TransactionsContainer>
          </div>
        )}
        {isDrawerShort && cat === -1 && (
          <ShortContentForInitialExpenseReview
            unsortedAmount={expenseData.length}
            onTourNextButton={onTourNextButton}
            showTour={showTour}
            beforeShowTour={beforeShowTour}
            setBeforeShowTour={setBeforeShowTour}
          />
        )}
        {isDrawerShort && 1 <= cat && cat <= 8 && (
          <SubcatListWithTitle {...getCategoryData(categoryName)} />
        )}
      </Drawer>
      {!beforeShowTour && showTour && (
        <NewExpenseReviewTour
          src={tourVideo}
          x={0}
          y={0}
        ></NewExpenseReviewTour>
      )}
    </div>
  );
}

export default ExpenseReview;

const TransactionsContainer = styled.div`
  font-family: TitleFont;
  font-size: 1rem; /* 20/16=1.25rem */
  font-weight: 700;
  line-height: 1.6875rem; /* 27/16 */
  text-align: left;
  white-space: break-spaces;
  /*
  overflow-y: scroll;
  min-height: 200px;
  max-height: 200px;
  */
  --header-height: 50px;
  .tableRowText {
    cursor: pointer;
  }
`;
