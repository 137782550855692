// exports TopNavBarWheels, TopNavBarPersonal, and TopNavBarHelp
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

function TopNavBarItem({ pathname, itemText }) {
  const navigate = useNavigate();
  return (
    <div
      className={
        window.location.pathname === pathname ? "selected" : "unselected"
      }
    >
      <span
        onClick={() => {
          navigate(pathname);
        }}
      >
        {itemText}
      </span>
    </div>
  );
}

export function TopNavBarWheels() {
  return (
    <TopNavBarStyle id="topNavBar">
      <TopNavBarItem pathname="/money-wheel" itemText="Money" />
      <TopNavBarItem pathname="/credit-wheel" itemText="Credit" />
      <TopNavBarItem pathname="/goals-wheel" itemText="Goals" />
    </TopNavBarStyle>
  );
}

export function TopNavBarPersonal() {
  return (
    <TopNavBarStyle id="topNavBar">
      <TopNavBarItem pathname="/profile-page" itemText="Profile" />
      <TopNavBarItem pathname="/accounts-page" itemText="Accounts" />
      <TopNavBarItem pathname="/score" itemText="Score" />
    </TopNavBarStyle>
  );
}

export function TopNavBarHelp() {
  return (
    <TopNavBarStyle id="topNavBar">
      <TopNavBarItem pathname="/help-faq" itemText="Help FAQ" />
      <TopNavBarItem pathname="/help" itemText="Report an Issue" />
    </TopNavBarStyle>
  );
}

const TopNavBarStyle = styled.div`
  text-align: center;
  padding: 6px;
  div {
    display: inline-block;
    margin: 3px;
    padding: 3px;
  }
  .selected {
    background: #eee;
    border: solid #ccc 2px;
  }
  span {
    cursor: pointer;
  }
`;
