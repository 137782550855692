import { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import { getMoneyFlowToken } from "../../state/stateApplication";
import backend from "../../functions/backend";
import { Table, Layout, Space, Button } from "antd";
import { EditOutlined, ReadOutlined, DeleteOutlined } from "@ant-design/icons";

const { Content } = Layout;
//Categories for the table
//amount
//acount type
//tx_category_id
//posted_date

// Works for Unicode characters.
// Ignores case and diacritics.
// Currently hardcoded to 'en' language.
const alphabeticalSort = (a, b) => {
  if (a) {
    return a.localeCompare(b, "en", { sensitivity: "base" });
  }
  return false;
};

const numericSort = (a, b) => {
  return a - b;
};

const dateSort = (a, b) => {
  return new Date(a) < new Date(b);
};

// Cycle between 'ascend' and 'descend'.
// The last 'ascend' is to tell Ant, not to cycle back to 'no sort'.
const ASCEND_DESCEND = ["ascend", "descend", "ascend"];

const columns = [
  {
    title: "Actions",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <Button icon={<EditOutlined />} />
        <Button icon={<ReadOutlined />} />
        <Button icon={<DeleteOutlined />} />
      </Space>
    ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    sorter: (a, b) => numericSort(a.amount, b.amount),
    sortDirections: ASCEND_DESCEND,
  },
  {
    title: "Account Type",
    dataIndex: "account_type",
    key: "accountType",
    sorter: (a, b) => alphabeticalSort(a.account_type, b.account_type),
    sortDirections: ASCEND_DESCEND,
  },
  {
    title: "Tx Category",
    dataIndex: "tx_category_name",
    key: "txCategoryName",
    sorter: (a, b) => alphabeticalSort(a.tx_category_name, b.tx_category_name),
    sortDirections: ASCEND_DESCEND,
  },
  {
    title: "Posted Date",
    dataIndex: "posted_date",
    key: "postedDAte",
    sorter: (a, b) => dateSort(a.posted_date, b.posted_date),
    sortDirections: ASCEND_DESCEND,
  },
];

export default function AdminTransactionsContent() {
  const navigate = useNavigate();
  const checkResponseFail = useCheckResponseFail();
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [tx_category_map, setTxCategoryMap] = useState(null);
  useEffect(() => {
    if (getMoneyFlowToken() === "") {
      navigate("/", { replace: true });
    }
  }, []);

  async function getTransactions() {
    let response = await backend.get(
      `/v1/tx/transaction?user_id=${location.state.user_id}`,
    );
    // let response = await backend.get("/v1/tx/transaction?user_id=2&categorization_type=uncategorized&_limit=3");
    if (response.items) {
      //we will asign each element a key for use in the table
      let key = 0;
      response.items.forEach((element) => {
        element.key = key;
        const element_name = tx_category_map[element.tx_category_id];
        element.tx_category_name =
          element_name !== null ? element_name : "No Category Found";
        key++;
      });
      setDataSource(response.items);
      setLoading(false);
    }
  }

  async function getTxCategoryMap() {
    let response = await backend.get(`/v1/tx/category`);
    if (response.items) {
      let result = response.items.reduce(function (map, obj) {
        map[obj.id] = obj.name;
        return map;
      }, {});
      setTxCategoryMap(result);
    }
  }

  useEffect(() => {
    setLoading(true);
    getTxCategoryMap();
  }, []);

  useEffect(() => {
    if (tx_category_map !== null) {
      getTransactions();
    }
  }, [tx_category_map]);

  return (
    <Content
      style={{
        margin: "24px 16px 0",
        minHeight: "75vh",
        overflow: "initial",
      }}
    >
      <div
        className="site-layout-background"
        style={{ padding: 24, textAlign: "left" }}
      >
        <h1>{location.state.username} Transactions</h1>
        <Link to="/admin">Back to Admin Dashboard</Link>
        <br />
        <Link to="/admin-users">Back to Users Dashboard</Link>
      </div>
      <Table loading={loading} dataSource={dataSource} columns={columns} />
    </Content>
  );
}
