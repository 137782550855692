import { useState } from "react";
import { Link } from "react-router-dom";
import { useDeleteUser } from "./useDeleteUser";
import { Space, Button } from "antd";
import { EditOutlined, ReadOutlined, DeleteOutlined } from "@ant-design/icons";

// custom React hook
export function useUserTable() {
  const [dataSource, setDataSource] = useState([]);
  const { handleDeleteUser } = useDeleteUser(setDataSource);

  // Works for Unicode characters.
  // Ignores case and diacritics.
  // Currently hardcoded to 'en' language.
  const alphabeticalSort = (a, b) => {
    return a.localeCompare(b, "en", { sensitivity: "base" });
  };

  // This assumes all phone numbers have the same number of digits
  const phoneNumberSort = (a, b) => {
    // Remove all non-number characters from the phone number string
    // and convert to a number.
    let a_num = +a.replace(/\D/g, "");
    let b_num = +b.replace(/\D/g, "");
    return a_num - b_num;
  };

  // Cycle between 'ascend' and 'descend'.
  // The last 'ascend' is to tell Ant, not to cycle back to 'no sort'.
  const ASCEND_DESCEND = ["ascend", "descend", "ascend"];

  // For readability, add hyphens to number only phoneNumber.
  const formattedPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length === 10) {
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    } else if (phoneNumber.length > 10) {
      // for international phone numbers with ###- prefix or those with 1- prefix.
      return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");
    }
    console.warn(`ERROR: unexpected phoneNumber = ${phoneNumber}`);
    return phoneNumber;
  };

  const columns = [
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} />
          <Link
            to="/admin-transactions"
            state={{ user_id: record.id, username: record.username }}
            title="See Transactions"
          >
            <Button icon={<ReadOutlined />} />
          </Link>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteUser(record)}
          />
        </Space>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: (a, b) => alphabeticalSort(a.username, b.username),
      sortDirections: ASCEND_DESCEND,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "firstName",
      sorter: (a, b) => alphabeticalSort(a.first_name, b.first_name),
      sortDirections: ASCEND_DESCEND,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "lastName",
      sorter: (a, b) => alphabeticalSort(a.last_name, b.last_name),
      sortDirections: ASCEND_DESCEND,
    },
    {
      title: "Email Address",
      dataIndex: "email_address",
      key: "email",
      sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
      sortDirections: ASCEND_DESCEND,
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone",
      sorter: (a, b) => phoneNumberSort(a.phone_number, b.phone_number),
      sortDirections: ASCEND_DESCEND,
      render: (phone_number) => {
        return <p>{formattedPhoneNumber(phone_number)}</p>;
      },
    },
  ];

  return { dataSource, setDataSource, columns };
}
