export function setupStepString(money_wheel_setup_step) {
  if (typeof money_wheel_setup_step !== "number") return "";

  const str = [
    "first screen",
    "welcome to setup",
    "single or married?",
    "number of dependents?",
    "Personal subcategories",
    "Grocery subcategories",
    "rent or own?",
    "Home subcategories",
    "Entertainment subcategories",
    "car yes or no?",
    "Transportation subcategories",
    "Health subcategories",
    "Focus?",
    "Focus subcategories",
    "Other subcategories",
    "next to last page",
    "last page",
  ];
  const SETUP_STEP_PLAID = 100;
  const SETUP_STEP_EXPENSE_REVIEW_INFO = 101;
  const SETUP_STEP_EXPENSE_REVIEW_TOUR = 102;
  const SETUP_STEP_EXPENSE_REVIEW_SORT = 103;
  const SETUP_STEP_BUDGET = 104;
  const SETUP_STEP_ACTIVE_WHEEL_INFO = 111;
  const SETUP_STEP_ACTIVE_WHEEL_TOUR = 112;
  const SETUP_STEP_ACTIVE_WHEEL_BASIC_TOUR = 113;
  const SETUP_STEP_ACTIVE_WHEEL_MAIN = 114;
  const SETUP_STEP_CREDIT_WHEEL_INFO = 201;
  const SETUP_STEP_CREDIT_WHEEL_TOUR = 202;
  const SETUP_STEP_CREDIT_WHEEL_MAIN = 203;
  const SETUP_STEP_GOALS_WHEEL_INFO = 301;
  const SETUP_STEP_GOALS_WHEEL_TOUR = 302;
  const SETUP_STEP_GOALS_WHEEL_MAIN = 303;

  str[SETUP_STEP_PLAID] = "Plaid";
  str[SETUP_STEP_EXPENSE_REVIEW_INFO] = "Expense Review Info";
  str[SETUP_STEP_EXPENSE_REVIEW_TOUR] = "Expense Review Tour";
  str[SETUP_STEP_EXPENSE_REVIEW_SORT] = "Expense Review Sort";
  str[SETUP_STEP_BUDGET] = "Budget";
  str[SETUP_STEP_ACTIVE_WHEEL_INFO] = "Money Wheel Info";
  str[SETUP_STEP_ACTIVE_WHEEL_TOUR] = "Money Wheel Tour";
  str[SETUP_STEP_ACTIVE_WHEEL_BASIC_TOUR] = "Money Wheel Basic Tour";
  str[SETUP_STEP_ACTIVE_WHEEL_MAIN] = "Money Wheel";
  str[SETUP_STEP_CREDIT_WHEEL_INFO] = "Credit Wheel Info";
  str[SETUP_STEP_CREDIT_WHEEL_TOUR] = "Credit Wheel Tour";
  str[SETUP_STEP_CREDIT_WHEEL_MAIN] = "Credit Wheel";
  str[SETUP_STEP_GOALS_WHEEL_INFO] = "Goals Wheel Info";
  str[SETUP_STEP_GOALS_WHEEL_TOUR] = "Goals Wheel Tour";
  str[SETUP_STEP_GOALS_WHEEL_MAIN] = "Goals Wheel";

  let index = Math.min(str.length - 1, money_wheel_setup_step);
  index = Math.max(0, index);
  return `${money_wheel_setup_step}: ${str[index]}`;
}
