import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../state/UserProvider";
import { useSettings, updateSetupStep1 } from "../../state/SettingsProvider";
import { getMoneyFlowToken } from "../../state/stateApplication";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import { TopNavBarWheels } from "../../components/PageComponents/TopNavBar";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";
import GoalsWheel from "../Tracker/GoalsWheel";
import InitTrackerConfig from "../ExpenseReview/InitTrackerConfig";
import styled from "styled-components";
import "../../styles/IPadLook.css";
import { Screen } from "../../styles/styledScreen";
import { BodyRegularText } from "../../styles/styledText";
import backend from "../../functions/backend";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import InfoPopoverAlt from "../../components/UI/Popover/InfoPopoverAlt";
import { GoalPopoverText } from "../../components/UI/Popover/InfoPopoverText";

import Drawer from "../../components/DrawerComponents/Drawer";
import DrawerContent from "./DrawerComponents/DrawerContent";
import { GoalModal } from "./NewGoalModal";
import { AssetModal } from "./NewAssetModal";

export default function GoalsWheelPage() {
  const navigate = useNavigate();

  const { currentUser } = useUser();
  const { wheelSettings, dispatchSettings } = useSettings();

  const [isTour, setIsTour] = useState(
    false, // false, during Early Access
    // Need to use goal_settings' setup_step
    //wheelSettings.setup_step === wheelSettings.SETUP_STEP_GOALS_WHEEL_TOUR,
  );

  console.log(wheelSettings.setup_step);
  console.log(wheelSettings.SETUP_STEP_GOALS_WHEEL_TOUR);

  useEffect(() => {
    //setIsTour( wheelSettings.setup_step === wheelSettings.SETUP_STEP_GOALS_WHEEL_TOUR);
  }, [wheelSettings.setup_step]);

  useEffect(() => {
    if (!isTour) {
      console.log("useEffect().  isTour is false");
      if (getMoneyFlowToken() === "") {
        navigate("/", { replace: true });
      } else {
        if (
          wheelSettings.setup_step === wheelSettings.SETUP_STEP_GOAL_WHEEL_TOUR
        ) {
          updateSetupStep1(
            wheelSettings.SETUP_STEP_GOAL_WHEEL_MAIN,
            currentUser.goal_settings_id,
            dispatchSettings,
            "goal",
          );
        }
      }
    }
  }, [isTour]);

  const trackerConfig = InitTrackerConfig();
  trackerConfig.color = "#FFFFB4";
  trackerConfig.vectorColor = "#D4E7FF";

  /*********
   * NEW GOAL WORK
   */
  const [tempIsTour, setTempIsTour] = useState(true);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const checkResponseFail = useCheckResponseFail();
  const [drawerLocation, setDrawerLocation] = useState(1);
  const [isDrawerShort, setIsDrawerShort] = useState(true);
  const [isDrawerTall, setIsDrawerTall] = useState(false);
  const [isPageLayoutComplete, setIsPageLayoutComplete] = useState(true);
  const [currentInfo, setCurrentInfo] = useState(null);
  const [goalModalOpen, setGoalModalOpen] = useState(false);
  const [assetModalOpen, setAssetModalOpen] = useState(false);
  const [currentCategoryInt, setCurrentCategoryInt] = useState(0);
  const [totalApplied, setTotalApplied] = useState(0);
  const [totalNeeded, setTotalNeeded] = useState(0);
  const [totalAssets, setTotalAssets] = useState(0);

  const [categoryNames, setCategoryNames] = useState([]);
  const [goalData, setGoalData] = useState([]);
  const [assetData, setAssetData] = useState([]);

  useEffect(() => {
    setIsPopoverOpen(false);
  }, [isDrawerShort]);

  const headerColor = "#F1FAFFBF";

  async function fetchData(category = -1) {
    const cat = category !== -1 ? category : currentCategoryInt;
    const url =
      "/v1/goal_wheel/goal" + (cat !== 0 ? "?goal_category_id=" + cat : "");
    const response = await backend.get(url);
    checkResponseFail(response, "Failed to get goals.");
    if (response.items) {
      setGoalData(response.items);
      const applied = response.items.reduce(
        (acc, item) => acc + parseFloat(item.allocated_amount),
        0,
      );
      setTotalApplied(isNaN(applied) ? 0 : applied);
      const needed = response.items.reduce(
        (acc, item) => acc + parseFloat(item.target_amount),
        0,
      );
      setTotalNeeded(isNaN(needed) ? 0 : needed);
    }
  }
  async function fetchAssetData(category = -1) {
    //needs to be implemented
    console.log("fetching asset data");
    const cat = category !== -1 ? category : currentCategoryInt;
    const endpoints = ["/personal_property", "/real_property", "/vehicle"];
    const data = [];
    for (const endpoint of endpoints) {
      const url =
        "/v1/asset" + endpoint + (cat !== 0 ? "?goal_category_id=" + cat : "");
      const response = await backend.get(url);
      checkResponseFail(response, "Failed to get assets.");
      if (response.items) {
        data.push(...response.items);
      }
    }
    setTotalAssets(data.reduce((acc, item) => acc + parseFloat(item.value), 0));
    setAssetData(data);
  }

  async function getCategoryNames() {
    const url = "/v1/goal_wheel/category";
    const response = await backend.get(url);
    checkResponseFail(response, "Failed to get goals.");
    console.log("testgoalcategory");
    if (response.items) {
      const names = response.items.map((item) => item.name);
      console.log("names");
      console.log(names);
      setCategoryNames(names);
    }
  }
  const switchCategory = (name, num) => {
    console.log(name);
    console.log(num);
    console.log("switching category");
    if (num) {
      const cat = num === "All" ? 0 : num;
      setCurrentCategoryInt(cat);
      fetchData(cat);
      fetchAssetData(cat);
    }
  };
  useEffect(() => {
    fetchData();
    fetchAssetData();
    getCategoryNames();
  }, []);

  //for now we are only returning this simple instruction to return to the money wheel
  return (
    <Screen>
      <MoneyFlowHeader />
      <TopNavBarWheels />
      <div className="tracker-form-split ActiveTracker">
        <GoalsWheel
          dropExpense={() => {}}
          expense={[]}
          config={trackerConfig}
          switchCategory={switchCategory}
          showNumbers={false}
          categoryFigures={{}}
          isTour={isTour}
          setIsTour={setIsTour}
          navBack={() => {}}
          isExpenseReview={false}
          wheelType={"GoalsWheel"}
          goal_S_active={currentCategoryInt === 8}
        />
      </div>
      <Drawer
        {...{
          drawerLocation,
          undefined,
          isPageLayoutComplete,
          setIsDrawerShort,
          setIsDrawerTall,
          headerColor,
        }}
      >
        <GoHome>
          <p>Goal Wheel Setup Coming Soon</p>
          <button
            onClick={() => {
              navigate("/money-wheel");
            }}
          >
            Back
          </button>
        </GoHome>
      </Drawer>
      <BottomNavBar />
    </Screen>
  );
  return (
    <Screen>
      <PopoverPlacer>
        <InfoPopoverAlt
          open={isPopoverOpen}
          onCloseButton={setIsPopoverOpen}
          contentText={GoalPopoverText["step1"]}
          placement="top"
        ></InfoPopoverAlt>
      </PopoverPlacer>
      <GoalModal
        isOpen={goalModalOpen}
        setIsOpen={setGoalModalOpen}
        fetchData={fetchData}
        categoryId={currentCategoryInt}
        isTour={tempIsTour}
      />
      <AssetModal
        isOpen={assetModalOpen}
        setIsOpen={setAssetModalOpen}
        fetchData={fetchAssetData}
      />
      <MoneyFlowHeader />
      <TopNavBarWheels />
      <div className="tracker-form-split ActiveTracker">
        <GoalsWheel
          dropExpense={() => {}}
          expense={[]}
          config={trackerConfig}
          switchCategory={switchCategory}
          showNumbers={false}
          categoryFigures={{}}
          isTour={isTour}
          setIsTour={setIsTour}
          navBack={() => {}}
          isExpenseReview={false}
          wheelType={"GoalsWheel"}
          goal_S_active={currentCategoryInt === 8}
        />
      </div>
      <Drawer
        {...{
          drawerLocation,
          undefined,
          isPageLayoutComplete,
          setIsDrawerShort,
          setIsDrawerTall,
          headerColor,
        }}
      >
        <DrawerContent
          isDrawerShort={isDrawerShort}
          isDrawerTall={isDrawerTall}
          setGoalModalOpen={setGoalModalOpen}
          setAssetModalOpen={setAssetModalOpen}
          goalData={goalData}
          assetData={assetData}
          categoryNames={categoryNames}
          currentCategoryInt={currentCategoryInt}
          totalApplied={totalApplied}
          totalNeeded={totalNeeded}
          totalAssets={totalAssets}
          isTour={tempIsTour}
          startTour={() => {
            setIsPopoverOpen(true);
            switchCategory("Other", 8);
          }}
        />
      </Drawer>
      <BottomNavBar />
    </Screen>
  );
}

const PopoverPlacer = styled.div`
  position: absolute;
  top: 30vh;
`;

const GoHome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 20%;
  border: 5px solid #2a8ff3b2;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  color: #333333b2;
  font-size: 1.4rem;
  button {
    background-color: #09d609b2;
    border: none;
    border-radius: 10px;
    color: #ffffff;
    font-size: 2rem;
  }
`;
