import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnExit,
  PlaidLinkOnEvent,
} from "react-plaid-link";
import { getMoneyFlowToken } from "../../state/stateApplication";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import { useDisplayErrorMsg } from "../../hooks/useDisplayErrorMsg";
import backend from "../../functions/backend";
import { isRunningTest } from "../../functions/environment";
import { isNull } from "../../functions/utils";
import { logPurple, logGreen, logOrange } from "../../functions/consoleLog";
import MyPlaidContext from "../Plaid";
import PlaidSetupPage from "./PlaidSetupPage";

const LinkAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [pageStatus, setPageStatus] = useState<
    "initial" | "plaid_link_running" | "plaid_link_success" | "plaid_link_fail"
  >("initial");

  const { linkToken, isPaymentInitiation, dispatch } =
    useContext(MyPlaidContext);
  const checkResponseFail = useCheckResponseFail();
  const { displayErrorMsg, hideErrorMsg } = useDisplayErrorMsg();

  const createLinkToken = async () => {
    console.log("Getting link token from our backend.");
    let response = await backend.post("/v1/plaid/link_token", {});
    checkResponseFail(response, "Failed to get link token:");

    console.log("Got response:", response);
    console.log("dispatch:", dispatch);
    let new_link_token;

    if (isNull(response.link_token) || isNull(response.link_token.link_token)) {
      console.log("link_token is null:", response);
      new_link_token = null;
      displayErrorMsg("Link token is null!");
    } else {
      new_link_token = response.link_token.link_token;
    }

    console.log("dispatch setting linkToken:", new_link_token);
    dispatch({ type: "SET_STATE", state: { linkToken: new_link_token } });
    // XXX Do we need to keep this locally for future oauth?
    //localStorage.setItem("link_token", response.link_token);

    // not yet set, so this is undefined
    console.log("LinkAccount: config.token:", config.token);
  };

  // get link_token from MF server when component mounts
  useEffect(() => {
    console.log(`Initially, linkToken = ${linkToken}`);

    // if no linkToken
    if (linkToken === "") {
      createLinkToken();
    }
  }, []);
  // A [] dependency runs only when the component is mounted
  // (ie only during the first navigation to this page).

  useEffect(() => {
    console.log("reset component");
    // React will skip rerendering, if the state hasn't changed.
    setPageStatus("initial");
  }, [location]);
  // This useEffect will run every time the app does a navigate() to this page.

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken: string, metadata) => {
      console.log("onSuccess(): With metadata:", metadata);
      await onPlaidPopupsDone(publicToken);
    },
    [dispatch, isPaymentInitiation],
  );

  const onPlaidPopupsDone = async (publicToken: string) => {
    console.log("Success on Plaid! Got public token:", publicToken);
    // send public_token to MF server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    const exchangePublicTokenForAccessToken = async () => {
      console.log("Sending public token to our backend.");
      let response = await backend.post("/v1/plaid/link", {
        public_token: publicToken,
      });
      checkResponseFail(response, "Failed to save public / access token:");
      console.log("Got response:", response);
      if (!response.success) {
        dispatch({
          type: "SET_STATE",
          state: {
            itemId: `no item_id retrieved from POST`,
            accessToken: `no access_token retrieved`,
            isItemAccess: false,
          },
        });
      } else {
        /* response looks like {
              http_status_code:  200
              id: 16
              message:  "PlaidLink created."
              success: true
           }
           response2 looks like {
              "access_token": "string",
              "created_datetime": "2025-03-17T22:05:24.770Z",
              "cursor": "string",
              "id": 0,
              "item_id": "string",
              "last_changed_datetime": "2025-03-17T22:05:24.770Z",
              "user_id": 0
            }
        */
        console.log(`GET /v1/plaid/link for user with id =${response.id}`);
        let response2 = await backend.get("/v1/plaid/link");
        checkResponseFail(response2, "Failed to get access token:");
        console.log("Got response2:", response2);
        if (
          !response2.success ||
          !response2.items ||
          response2.items.length === 0
        ) {
          dispatch({
            type: "SET_STATE",
            state: {
              itemId: `no item_id retrieved from GET`,
              accessToken: `no access_token retrieved`,
              isItemAccess: false,
            },
          });
        } else {
          dispatch({
            type: "SET_STATE",
            state: {
              itemId: response2.items[0].item_id,
              accessToken: response2.items[0].access_token,
              isItemAccess: true,
            },
          });
          console.log("Got access token:");
          console.log(response2.items[0].access_token);
          console.assert(
            response2.items[0].access_token,
            "response2.items[0].access_token should be set, but is undefined",
          );
        }
      }
    };

    // 'payment_initiation' products do not require the public_token to be exchanged for an access_token.
    if (isPaymentInitiation) {
      console.log(
        "isPaymentInitiation so no need to exchange public token for access token.",
      );
      dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
    } else {
      console.log("Exchanging public token for access token.");
      await exchangePublicTokenForAccessToken();
    }

    console.log("Link success.");
    dispatch({ type: "SET_STATE", state: { linkSuccess: true } });
    //  alert("You have successfully linked your accounts! Press Next to continue.");
    setPageStatus("plaid_link_success");
  };

  // handle user exiting Plaid Link and errors
  const onExit = useCallback<PlaidLinkOnExit>(
    async (error, metadata) => {
      console.log("Incomplete on Plaid! Got error:", error);
      console.log("With metadata:", metadata);

      if (error != null) {
        if (error.error_code === "INVALID_LINK_TOKEN") {
          console.warn("ERROR: INVALID_LINK_TOKEN");
          // The user exited the Link flow with an INVALID_LINK_TOKEN error.
          // This can happen if the token expires or the user has attempted
          // too many invalid logins.
          if (getMoneyFlowToken() === "") {
            // This case happens if user waits for over 1 hour in a PlaidLink page,
            // causing the MoneyFlow token to expire.
            navigate("/", { replace: true });
          } else {
            // This case happens if the MoneyFlow token isn't cleared and
            // if the user waits for over 4 hours in a PlaidLink page,
            // causing the Link token to expire.
            // Get a new link_token.
            await createLinkToken();
            setPageStatus("plaid_link_running");
            if (!isRunningTest()) {
              console.log("I'm a live person");
              // Automatically try to start Plaid Link again.
              if (ready) open();
            } else {
              console.warn(
                "I'm in a Cypress Test.  Why is link token invalid?",
              );
            }
            return;
          }
        } else if (error.error_code === "INSTITUTION_NO_LONGER_SUPPORTED") {
          displayErrorMsg("This institution is no longer supported.");
        } else if (error.error_code === "INSTITUTION_NOT_AVAILABLE") {
          displayErrorMsg("This institution is not available.");
        }
      }

      dispatch({
        type: "SET_STATE",
        state: {
          itemId: `no item_id retrieved`,
          accessToken: `no access_token retrieved`,
          isItemAccess: false,
        },
      });

      //  alert("No accounts were linked. Try again later on your Accounts page. Press Next to continue.");
      setPageStatus("plaid_link_fail");
      displayErrorMsg(
        "Could not connect your accounts. Please try again now." +
          "  Or you can try again later by going to the Accounts Page.",
      );
    },
    [dispatch],
  );

  // log events and errors from Plaid Link
  const onEvent = useCallback<PlaidLinkOnEvent>(async (eventName, metadata) => {
    logPurple(`Got event: ${eventName}`);
    logPurple("with metadata:");
    console.log(metadata);
    if (eventName === "ERROR") {
      console.warn(`onEvent(): Got error: ${metadata.error_code}`);
      console.warn(`message: ${metadata.error_message}`);
      switch (metadata.error_code) {
        case "INSTITUTION_DOWN":
          displayErrorMsg("Institution undergoing maintenance.");
          break;
        case "INSTITUTION_NOT_RESPONDING":
          displayErrorMsg("Institution temporarily unavailable.");
          break;
        case "INVALID_CREDENTIALS":
          displayErrorMsg(
            `${metadata.error_message}.  Institution: ${metadata.institution_name}`,
          );
          break;
      }
    } else if (eventName === "SELECT_INSTITUTION") {
      logOrange(`Selected institution: ${metadata.institution_name}`);
    } else if (eventName === "OPEN") {
      logOrange("Plaid Link opened.");
    } else if (eventName === "EXIT") {
      logOrange("User closed Plaid Link.");
    } else if (eventName === "TRANSITION_VIEW") {
      logOrange(`Transition View: ${metadata.view_name}`);
    }
  }, []);

  // config object has the same type as the first parameter of usePlaidLink
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess: onSuccess,
    onExit: onExit,
    onEvent: onEvent,
  };

  console.log("config.token:", config.token);
  const { open, ready } = usePlaidLink(config);

  // when user clicks on "Connect Accounts" button
  // or "Add another account" button
  function navNext() {
    hideErrorMsg();
    if (getMoneyFlowToken() === "") {
      // if user waits at the "Connect Accounts" page forever,
      // then they are logged out, so don't start Plaid Link.
      navigate("/", { replace: true });
    } else if (ready) {
      setPageStatus("plaid_link_running");
      if (!isRunningTest()) {
        console.log("I'm a live person");
        open();
      } else {
        console.log("I'm in a Cypress Test.");
        doPlaidLinkForCypress();
      }
    }
  }

  const doPlaidLinkForCypress = async () => {
    let publicToken = "";
    const createSandboxPublicToken = async () => {
      let response = await backend.post("/v1/plaid/sandbox_public_token", {});
      checkResponseFail(response, "Failed to create public token:");
      publicToken = response.public_token;
      console.log("createSandboxPublicToken() completes here");
    };

    await createSandboxPublicToken();
    await onPlaidPopupsDone(publicToken);
  };

  return (
    <PlaidSetupPage
      initialPageStatus={pageStatus}
      navNext={navNext}
      ready={ready}
    />
  );
};

export default LinkAccount;
