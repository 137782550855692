import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../state/UserProvider";
import { useSettings, updateSetupStep1 } from "../../state/SettingsProvider";
import { getMoneyFlowToken } from "../../state/stateApplication";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import { TopNavBarWheels } from "../../components/PageComponents/TopNavBar";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";
import CreditWheel from "../Tracker/CreditWheel";
import InitTrackerConfig from "../ExpenseReview/InitTrackerConfig";
import styled from "styled-components";
import "../../styles/IPadLook.css";
import { Screen } from "../../styles/styledScreen";
import { BodyRegularText } from "../../styles/styledText";
import Drawer from "../../components/DrawerComponents/Drawer";

export default function CreditWheelPage() {
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const { wheelSettings, dispatchSettings } = useSettings();

  const [drawerLocation, setDrawerLocation] = useState(1);
  const [isDrawerShort, setIsDrawerShort] = useState(true);
  const [isDrawerTall, setIsDrawerTall] = useState(false);
  const [isPageLayoutComplete, setIsPageLayoutComplete] = useState(true);
  const headerColor = "#F1FAFFBF";

  const [isTour, setIsTour] = useState(
    false, // false, during Early Access
    // Need to use debt_settings' setup_step
    //wheelSettings.setup_step === wheelSettings.SETUP_STEP_CREDIT_WHEEL_TOUR,
  );

  useEffect(() => {
    //setIsTour( wheelSettings.setup_step === wheelSettings.SETUP_STEP_CREDIT_WHEEL_TOUR);
  }, [wheelSettings.setup_step]);

  useEffect(() => {
    if (!isTour) {
      console.log("useEffect().  isTour is false");
      if (getMoneyFlowToken() === "") {
        navigate("/", { replace: true });
      } else {
        if (
          wheelSettings.setup_step ===
          wheelSettings.SETUP_STEP_CREDIT_WHEEL_TOUR
        ) {
          updateSetupStep1(
            wheelSettings.SETUP_STEP_CREDIT_WHEEL_MAIN,
            currentUser.debt_settings_id,
            dispatchSettings,
            "debt",
          );
        }
      }
    }
  }, [isTour]);

  const trackerConfig = InitTrackerConfig();
  trackerConfig.color = "#FFFFB4";
  trackerConfig.vectorColor = "#FDF5A9";

  return (
    <Screen>
      <MoneyFlowHeader />
      <TopNavBarWheels />
      <div className="tracker-form-split ActiveTracker">
        <CreditWheel
          dropExpense={() => {}}
          expense={[]}
          config={trackerConfig}
          switchCategory={() => {}}
          showNumbers={false}
          categoryFigures={{}}
          isTour={isTour}
          setIsTour={setIsTour}
          navBack={() => {}}
          isExpenseReview={false}
          wheelType={"CreditWheel"}
        />
      </div>
      <Drawer
        {...{
          drawerLocation,
          undefined,
          isPageLayoutComplete,
          setIsDrawerShort,
          setIsDrawerTall,
          headerColor,
        }}
      >
        <GoHome>
          <p>Credit Wheel Setup Coming Soon</p>
          <button
            onClick={() => {
              navigate("/money-wheel");
            }}
          >
            Back
          </button>
        </GoHome>
      </Drawer>
      <BottomNavBar />
    </Screen>
  );
}

const Text1 = styled.div`
  ${BodyRegularText}
  text-align: center;
  margin-top: 2em;
`;

const GoHome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 20%;
  border: 5px solid #f7e00bcc;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  color: #333333b2;
  font-size: 1.4rem;
  button {
    background-color: #09d609b2;
    border: none;
    border-radius: 10px;
    color: #ffffff;
    font-size: 2rem;
  }
`;
