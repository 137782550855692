import { isNull } from "./utils";

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// IN: text: string that has newlines and multiple space formatting
// RETURN: text: substitutes <br> and &nbsp; for newline and spaces
export function textToHtml(text) {
  if (isNull(text)) return "";

  // Replace multiple spaces with non-breaking spaces
  text = text.replace(/ {2,}/g, function (match) {
    return match.replace(/ /g, "&nbsp;");
  });

  // Replace newlines with <br> tags
  text = text.replace(/\n/g, "<br>");

  return text;
}

// returns true, if not undefined, not null, not empty string, and
// not composed of only whitespace characters
export function hasContent(str) {
  return typeof str === "string" && str.trim().length > 0;
}
