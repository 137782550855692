import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
//import { useDisplayErrorMsg } from "../../hooks/useDisplayErrorMsg";
import backend from "../../functions/backend";
//import { isDeployed } from "../../functions/environment";
import { hasContent } from "../../functions/string";
import { setMoneyFlowToken } from "../../state/stateApplication";
import { isGoodBrowser } from "../../functions/platform";
import { focusTextBoxes, setDisplay } from "../../functions/dom";
import { initUser, useUser } from "../../state/UserProvider";
import { initSettings, useSettings } from "../../state/SettingsProvider";
import { SyncOutlined } from "@ant-design/icons";
import CoolFormInputEmail from "../../components/UI/CoolFormInputEmail";
import CoolFormInputName from "../../components/UI/CoolFormInputName";
import CoolFormInputPassword from "../../components/UI/CoolFormInputPassword";
import CoolFormInputPhone from "../../components/UI/CoolFormInputPhone";
import GreenGradientBackground from "../../components/UI/GreenGradientBackground";
import MoneyFlowFooter from "../../components/PageComponents/MoneyFlowFooter";

import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";
import { Form } from "antd";
//import { BodyRegularText } from "../../styles/styledText";
import styled from "styled-components";
import * as SP from "../../styles/styledPageComponents";
import {
  MainContainer,
  TitleText,
  ReminderText,
  GreenLink,
  BigGreenButton,
  SmallText,
} from "../../styles/styledLoginComponents";

export default function RegisterContent() {
  //const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const { dispatchUser } = useUser();
  const { dispatchSettings } = useSettings();
  const [createAccountButtonClicked, setCreateAccountButtonClicked] =
    useState(false);

  const navigate = useNavigate();
  const checkResponseFail = useCheckResponseFail();
  //const { displayErrorMsg } = useDisplayErrorMsg();
  const [formInitialValues, setFormInitialValues] = useState({});

  /*
  const doSomething = () => {
    // do something like submit a form and then refresh recaptcha
    setRefreshReCaptcha((r) => !r);
  };
  */

  const [form0] = Form.useForm();

  window.onload = function () {};

  useEffect(() => {
    prefillFormWithEmailFromUrl();
    focusTextBoxes("form_registration_first_name");
    setDisplay("loading_msg", false);
    if (!isGoodBrowser()) {
      /*
      displayErrorMsg(
        "Early Access Note: Your specific device and browser combination has not yet been tested. Please let us know if you encounter any issues.",
      );
      */
    }
  }, []);

  // Get specific query parameter.
  // returns the first value associated with the parameter, if it exists.
  // returns null, if the parameter doesn't exist
  const getQueryParam = (param) => {
    return new URLSearchParams(window.location.search).get(param);
  };

  // Sample URL:
  // https://app.moneyflow.org/register?email_address=example%40gmail.com
  function prefillFormWithEmailFromUrl() {
    const emailAddress = getQueryParam("email_address");
    if (emailAddress) {
      const decodedEmail = decodeURIComponent(emailAddress);

      let fields = {
        email_address: decodedEmail,
      };
      form0.setFieldsValue(fields);
      // run the input's validator on the email parsed from the url.
      form0.validateFields(["email_address"]);
      setFormInitialValues({ ...fields });
    }
  }

  async function onFinish(values) {
    if (!createAccountButtonClicked) {
      setCreateAccountButtonClicked(true);
    }
    console.log("RegisterContent: onFinish(): 0");
    backend.tokenTimeout.reinitTimeout();
    document.getElementsByTagName("HTML")[0].scrollTop = 0;

    // remove non-digit characters from the phone number
    values.phone_number = values.phone_number.replace(/\D/g, "");

    // send lowercased email to backend
    values.email_address = values.email_address.toLowerCase();

    setDisplay("loading_msg", true);
    console.log("Success:", values, JSON.stringify(values));

    var storedValues = JSON.parse(JSON.stringify(values));
    // storedValues.password = undefined;
    // delete storedValues["password"];
    var res = await backend.post("/v1/user/signup", storedValues);
    if (checkResponseFail(res, "Failed to POST user signup:")) {
      setDisplay("loading_msg", false);
      setCreateAccountButtonClicked(false); // enable Create Account button
    } else {
      /*
      if (isDeployed()) {
        navigate("/sent-registration-email", { replace: true });
      } else {
        // Go direct to Let's Personalize page (see code below)
      }
      */
      // For now, skip the above code, and deployed version will behave the
      // same as staging and Cypress tests.

      // Cypress tests all depend on registration leading to auto- login

      // clear the MF access token
      setMoneyFlowToken("");

      let data = await backend.post("/v1/user/login", {
        email_address: values.email_address,
        password: values.password,
      });
      if (checkResponseFail(data, "Failed to POST user login:")) {
        setDisplay("loading_msg", false);
      }

      console.log("Response data: " + JSON.stringify(data));

      if (data.token) {
        // store the MF access token
        setMoneyFlowToken(data.token);

        let user3 = await initUser();
        console.log("LoginContent: onFinish(): user3", user3);

        dispatchUser({
          type: "USER_LOGIN",
          payload: { user: user3 },
        });

        let wheelSettings = await initSettings(user3);

        dispatchSettings({
          type: "SETTINGS_INIT",
          payload: { settings: wheelSettings },
        });

        // start at the beginning for a newly registered user
        navigate(`/money-wheel-setup`, { replace: true });
      } else {
        console.log("No token: " + JSON.stringify(data));
        navigate(`/`, { replace: true });
      }
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <MainContainer justifyContent={"flex-start"}>
      <GreenGradientBackground bottom={true} />
      <ErrorNoticeBar />
      <div id="loading_msg">
        <LoadingIcon>
          <SyncOutlined spin />
        </LoadingIcon>
      </div>
      <Form
        form={form0}
        layout="vertical"
        name="form_registration"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <TitleText>Welcome!</TitleText>
        <ReminderText>Create your MoneyFlow account</ReminderText>
        <SP.FormItemContainer>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <CoolFormInputName name="first_name" placeholder="First Name" />
            <div style={{ width: "20px" }}></div>
            <CoolFormInputName name="last_name" placeholder="Last Name" />
          </div>
          <CoolFormInputEmail
            placeholder="Email Address"
            required={true}
            hasInitialValue={hasContent(formInitialValues.email_address)}
          />
          <CoolFormInputPassword placeholder="Password" required={true} />
          <CoolFormInputPhone required={true} />

          <Form.Item>
            <BigGreenButton
              type="primary"
              htmlType="submit"
              onClick={() => {}}
              disabled={createAccountButtonClicked}
            >
              Create Account
            </BigGreenButton>
          </Form.Item>
          <SmallText>
            By creating your account, you agree to our
            <br /> <GreenLink to="/terms">terms of service</GreenLink> and our{" "}
            <GreenLink to="/privacy-policy">privacy policy.</GreenLink>
          </SmallText>
        </SP.FormItemContainer>
      </Form>
      <div
        style={{ marginBottom: "1em", textAlign: "center", marginTop: "auto" }}
      >
        <p>
          Already have an account?{" "}
          <GreenLink to="/login" id="registerLink" size={14}>
            Sign In
          </GreenLink>
        </p>
      </div>
      <MoneyFlowFooter />
    </MainContainer>
  );
}

/*
const LoginText = styled.p`
  font-family: PrimaryVariableFont, TitleFont, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 2em;
`;

const Text1 = styled.div`
  ${BodyRegularText}
  text-align: left;
  margin-bottom: 1em;
`;
*/

const LoadingIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
  font-size: 7rem;
`;
