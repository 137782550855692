import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import { getMoneyFlowToken } from "../../state/stateApplication";
import { Layout } from "antd";
import styled from "styled-components";

const { Content } = Layout;

export default function AdminContent() {
  const navigate = useNavigate();
  const checkResponseFail = useCheckResponseFail();
  useEffect(() => {
    if (getMoneyFlowToken() === "") {
      navigate("/", { replace: true });
    }
  }, []);
  return (
    <StyledContent>
      <h1>Admin Dashboard</h1>
      <StyledLink to="/admin-notifications">Manage Notifications</StyledLink>
      <StyledLink to="/admin-users">Manage Users</StyledLink>
      <StyledLink to="/admin-users-stat">User Activity</StyledLink>
    </StyledContent>
  );
}

const StyledContent = styled(Content)`
  margin: 0;
  padding: 0.5em 1em;
`;

const StyledLink = styled(Link)`
  margin: 0 1em;
  padding: 0.5em 1em;
  display: block;
`;
