import { useRef } from "react";
import Tracker from "./Tracker";
import getCategoryIcons from "./MoneyWheelIcons";
import {
  Text_Below_Position_Angle_Map,
  Text_Above_Position_Angle_Map,
  Radius_Map,
  Size_Map,
} from "./MoneyWheelPlacedSVG";
import "./style.css";

//import dragExpenseToCategoryVideo from "../../assets/vid/dragExpenseToCategory.webm";
//import moneyWheelWithCategoryNames from "../../assets/img/MoneyWheel/money-wheel-with-category-names.png";

export default function MoneyWheel(props) {
  const childRefs = useRef(null);

  /* Old tour text:

    `Welcome to your Money Wheel.  Going around the wheel, you see
        8 different expense categories, such as Grocery or Home.  You will
        be sorting each new expense into 1 of these categories.`,

    "If you have a new expense to sort, it appears in the center of your Money Wheel. You can tap it to automatically go in the 'best guess' category or drag and drop it in the category you want. If you want to label or split the expense tap the Edit button.",

    "If you have a new expense to sort, it appears in the center of your
        Money Wheel. You can drag and drop it into the category you want.
        Tap the expense to label or split it.`,

    "Tap on a category to see details like subcategories and the list of expenses in that category.",

    `Tap on a category and open the drawer to see category details
        and a list of expenses in that category.
        Tap on a row in the list to to see more details or edit that expense.`,
        //Once you categorize an expense, you can edit it again by tapping on
        //a row in the expense drawer.
  */

  // start w/ N category, and go clockwise
  const categoryLabel = [
    "Family",
    "Home",
    "Transport",
    "Focus",
    "Other",
    "Health",
    "Entertain",
    "Grocery",
  ];
  return (
    <>
      <Tracker
        {...props}
        categoryIcons={getCategoryIcons(props.config)}
        categoryLabel={categoryLabel}
        Text_Below_Position_Angle_Map={Text_Below_Position_Angle_Map}
        Text_Above_Position_Angle_Map={Text_Above_Position_Angle_Map}
        Radius_Map={Radius_Map}
        Size_Map={Size_Map}
        ref={childRefs}
      />
    </>
  );
}
