import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSettings } from "../../state/SettingsProvider";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import backend from "../../functions/backend";
import { getLastNumMonthsQueryString } from "../../functions/timeDate";
import { logBlue, logRed } from "../../functions/consoleLog";
import { isDevelopment } from "../../functions/environment";

// Will repeatedly check for uncategorized data from the last 2 months.
// If not enough uncategorized data, it'll relabel some data as "uncategorized".
// It will try to ensure there is at least MIN_UNCATEGORIZED_TRANSACTIONS

export const DataPrep = ({ setWhileSyncingMsg, setPageStatus }) => {
  const navigate = useNavigate();
  const checkResponseFail = useCheckResponseFail();
  const [numPollsRun, setNumPollsRun] = useState(0);
  const { wheelSettings } = useSettings();

  console.log("DataPrep component rendering, but not necessarily running poll");
  //const numTries = useRef(0);
  const startTimeRef = useRef(Date.now());
  console.log("Start time:  " + Math.floor(startTimeRef.current / 1000));

  function goToNextPage() {
    if (wheelSettings.is_setup_complete) {
      // finished adding additional accounts from the Profile/Accounts button
      navigate(`/money-wheel`, { replace: true });
    } else {
      // completed Plaid Setup for the first time
      navigate("/expense-review-info", { replace: true });
    }
  }

  const MIN_UNCATEGORIZED_TRANSACTIONS = 2;
  const MAX_UNCATEGORIZED_TRANSACTIONS = 9;

  // Returns the number of uncategorized tx for the last 2 months.
  // Asks for 2 months, since there will be only a few tx if it's
  // early in the current month.
  const fetchDataCount = async () => {
    console.log("Data count is being fetched.");
    const query =
      "/v1/tx/transaction?categorization_type=uncategorized&_count=true&posted_date=" +
      getLastNumMonthsQueryString(2);
    console.log(`query is ${query}`);
    let response = await backend.get(query);
    console.log("DataPrep fetchDataCount response is");
    console.log(response);

    // for testing, simulate no tx
    //return 0;

    if (
      checkResponseFail(
        response,
        "Failed to GET uncategorized transactions count:",
      )
    ) {
      console.error("Error fetching data count:");
      return 0;
    }
    return response.count;
  };

  const printMostRecentTransactionFound = async () => {
    console.log("printMostRecentTransactionFound() ");
    const query =
      "/v1/tx/transaction?_limit=1&_sort_by=posted_date&_sort_order=desc";
    console.log(`query is ${query}`);
    let response = await backend.get(query);
    console.log("printMostRecentTransactionFound response is");
    console.log(response);
    checkResponseFail(response, "Failed to GET any transactions.");
    console.log("response.items");
    console.log(response.items);
    if (response.items && response.items.length) {
      console.log(
        `Most recent transaction found has posted_date=${response.items[0].posted_date}`,
      );
      console.log(response.items[0]);
    } else {
      console.log(`No transactions at all`);
    }
  };

  const relabelDataUncategorized = async () => {
    console.log("Categorized Data is being fetched.");
    // get up to MAX_UNCATEGORIZED_TRANSACTIONS most recent tx from the last 2 months
    const query =
      "/v1/tx/transaction?_limit=" +
      MAX_UNCATEGORIZED_TRANSACTIONS +
      "&_sort_by=posted_date&_sort_order=desc&posted_date=" +
      getLastNumMonthsQueryString(2);
    console.log(`query is ${query}`);
    let response = await backend.get(query);
    console.log("relabelDataUncategorized response is");
    console.log(response);
    checkResponseFail(
      response,
      "Failed to GET categorized transactions.  No expenses after 30 sec.",
    );
    console.log("response.items");
    console.log(response.items);
    let convertedCount = 0;
    if (response.items) {
      for (
        let i = 0;
        i < response.items.length &&
        convertedCount < MIN_UNCATEGORIZED_TRANSACTIONS;
        i++
      ) {
        let expense = response.items[i];
        if (
          expense.plaid_tx_id === "fake_income_tx_id" ||
          expense.counterparty.includes("Employer")
        ) {
          continue; // skip income items
        }

        // Convert the expense to uncategorized,
        // so there is something for the user to sort in
        // Initial Expense Review.
        let data = {
          id: expense.id,
          categorization_type: "uncategorized",
          // Uncategorized transactions go to the "Other" category (ie 8)
          tx_category_id: 8,
          // 800 is "Uncategorized"
          tx_subcategory_id: 800,
        };
        console.log("Starting to make expense uncategorized again.");
        let response2 = await backend.patch("/v1/tx/transaction", data);
        checkResponseFail(response2, "Failed to PATCH transaction:");
        if (response2.success) {
          convertedCount++;
        }
      }
    }

    // for testing, simulate no tx
    //convertedCount = 0;

    if (convertedCount === 0) {
      // Ask about adding another account through Plaid
      console.warn(
        "No transactions (categorized and uncategorized) were found from this and the previous months.",
      );
      console.warn("Returning to 'Connect Accounts' page");
      setPageStatus("download_fail");

      // are there any syncable tx at all in the account?
      printMostRecentTransactionFound();
    } else {
      goToNextPage();
    }
  };

  useEffect(() => {
    console.log("DataPrep is rendering");
  });

  useEffect(() => {
    console.log("useEffect(): 0.   DataPrep component is mounted.");

    let MAX_POLLING_DURATION = 29000; // 29 sec  // for production
    if (isDevelopment()) {
      //MAX_POLLING_DURATION = 119000; // 119 sec
      MAX_POLLING_DURATION = 600000; // for testing, wait up to 10 minutes.
      // See it it's just taking a long time or the backend server never
      // got transactions in the first place.
    }
    console.log(`MAX_POLLING_DURATION is ${MAX_POLLING_DURATION}`);

    const POLLING_INTERVAL = 6000; // 6 sec
    // poll every 6 sec, up to MAX_POLLING_DURATION sec.

    // This runs every 6 sec
    const poll = async () => {
      console.log("New Poll is running.  Fetching data count.");
      const dataCount = await fetchDataCount();
      console.log("Data was fetched.");
      //if (false) {  // for testing only, force running until MAX_POLLING_DURATION, to show the while syncing messages
      if (dataCount > MIN_UNCATEGORIZED_TRANSACTIONS) {
        console.log("Data is good.");

        let startTime = Math.floor(startTimeRef.current / 1000);
        logBlue(`Start time:  ${startTime}`);
        let endTime = Math.floor(Date.now() / 1000);
        logBlue(`End time:  ${endTime}`);
        logBlue(`Elapsed time: ${(endTime - startTime) / 60} minutes`);
        logBlue("Got transactions from Plaid");

        clearInterval(intervalId);
        clearTimeout(timeoutId);
        goToNextPage();
      } else {
        console.log("Not enough data.");
        // only show a new message, if still not enough data available.
        setNumPollsRun((numPollsRun) => numPollsRun + 1);
        // Using setInterval creates a stale closure,
        // so this updater function is required to ensure that we have the
        // latest value for numPollsRun.
      }
    };

    // do next polls every 6 sec
    const intervalId = setInterval(poll, POLLING_INTERVAL);

    // stop polling after MAX_POLLING_DURATION sec
    const timeoutId = setTimeout(() => {
      console.log("Time is up.");
      clearInterval(intervalId);

      // Couldn't find the MIN_UNCATEGORIZED_TRANSACTIONS,
      // so we'll take some categorized transactions
      // and make them uncategorized, so that the
      // initial expense review can use them.

      relabelDataUncategorized();
    }, MAX_POLLING_DURATION);

    poll(); // do first poll immediately

    // This cleanup function runs when the component is unmounted
    // and every time the useEffect runs again.
    return () => {
      console.log("DataPrep cleanup function is running.");
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    // make new message appear
    const msgs = [
      "Your transactions are in safe hands with our bank-level security and strong encryption.",
      "Ready to take charge of your finances?  You got this!",
      "Budgeting isn't about limiting yourself- it's about making your goals that excite you possible.",
      "Transfer of financial transactions from Plaid servers are taking longer than expected. Thank you for your patience.",
      "Grow your savings by spending less each month.  Research shows that tracking your expenses can reduce spending by 15%.",
      "Your transactions are in safe hands with our bank-level security and strong encryption.",
      "Ready to take charge of your finances?  You got this!",
      "Budgeting isn't about limiting yourself- it's about making your goals that excite you possible.",
      "Transfer of financial transactions from Plaid servers are taking longer than expected. Thank you for your patience.",
      "Grow your savings by spending less each month.  Research shows that tracking your expenses can reduce spending by 15%.",
      "Couldn't retrieve any transactions from Plaid.  You have no expenses to review.",
    ];
    // causes message to change every 2 intervals.  So 12 seconds to read each message.
    let msgIndex = Math.floor(numPollsRun / 2);
    // Necessary to limit msgIndex, if we occassionally
    // reset MAX_POLLING_DURATION to a high number.
    msgIndex = Math.min(msgs.length - 1, msgIndex);
    setWhileSyncingMsg(msgs[msgIndex]);
  }, [numPollsRun]);

  return null;
};
