import categoryData from "../CFTSetup/Forms/Data";

import { ReactComponent as SingleIcon } from "../../assets/img/MoneyWheel/money-single-icon.svg";
import { ReactComponent as Single1KidIcon } from "../../assets/img/MoneyWheel/money-single-1kid-icon.svg";
import { ReactComponent as Single2KidIcon } from "../../assets/img/MoneyWheel/money-single-2kid-icon.svg";
import { ReactComponent as Single3KidIcon } from "../../assets/img/MoneyWheel/money-single-3kid-icon.svg";
import { ReactComponent as Single4KidIcon } from "../../assets/img/MoneyWheel/money-single-+kid-icon.svg";

import { ReactComponent as CoupleIcon } from "../../assets/img/MoneyWheel/money-couple-icon.svg";
import { ReactComponent as Couple1KidIcon } from "../../assets/img/MoneyWheel/money-couple-1kid-icon.svg";
import { ReactComponent as Couple2KidIcon } from "../../assets/img/MoneyWheel/money-couple-2kid-icon.svg";
import { ReactComponent as Couple3KidIcon } from "../../assets/img/MoneyWheel/money-couple-3kid-icon.svg";
import { ReactComponent as Couple4KidIcon } from "../../assets/img/MoneyWheel/money-couple-+kid-icon.svg";

import { ReactComponent as BusIcon } from "../../assets/img/MoneyWheel/money-bus-icon.svg";
import { ReactComponent as CarIcon } from "../../assets/img/MoneyWheel/money-car-icon.svg";

import { ReactComponent as EducationIcon } from "../../assets/img/MoneyWheel/money-education-icon.svg";
import { ReactComponent as WorkIcon } from "../../assets/img/MoneyWheel/money-work-icon.svg";
import { ReactComponent as HobbyIcon } from "../../assets/img/MoneyWheel/money-hobby-icon.svg";

function getFamilyIcon(config) {
  const familyIcons = [
    [
      SingleIcon,
      Single1KidIcon,
      Single2KidIcon,
      Single3KidIcon,
      Single4KidIcon,
    ],
    [
      CoupleIcon,
      Couple1KidIcon,
      Couple2KidIcon,
      Couple3KidIcon,
      Couple4KidIcon,
    ],
  ];
  console.log(config);
  if (!config.family) return SingleIcon; //because in the setup this was being called before the congig wasset correctly
  const numKids = config.family.kidAgeRanges.length;
  const familyIcon = familyIcons[+config.family.married][numKids];
  return familyIcon;
}

function getFocusIcon(config) {
  if (config.focus === "education") {
    return EducationIcon;
  } else if (config.focus === "work") {
    return WorkIcon;
  } else if (config.focus === "hobby") {
    return HobbyIcon;
  } else {
    console.error(`getFocusIcon(): unknown focus type: ${config.focus}`);
  }
}

export default function getCategoryIcons(config) {
  const transportationIcon =
    config.transportation === "public" ? BusIcon : CarIcon;
  const categoryIcons = {
    N_Icon: getFamilyIcon(config),
    NE_Icon: categoryData["Home & Garden"].CategoryTitleIcon,
    E_Icon: transportationIcon,
    SE_Icon: getFocusIcon(config),
    S_Icon: categoryData.Other.CategoryTitleIcon,
    SW_Icon: categoryData.Health.CategoryTitleIcon,
    W_Icon: categoryData.Entertainment.CategoryTitleIcon,
    NW_Icon: categoryData.Grocery.CategoryTitleIcon,
  };
  return categoryIcons;
}
