import styled from "styled-components";

export const BudgetProgressBar = ({
  spent,
  total,
  remaining,
  isCurrentMonth = false,
}) => {
  const currentDate = new Date();
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  );
  // Want this number to be consistent with the number in the wheel center.
  // So it will always be at least 1.
  const days = lastDayOfMonth.getDate() - currentDate.getDate() + 1;
  let percent = Math.floor((spent / total) * 100);
  // if user overspends, then cap percent to 100%
  percent = Math.min(100, percent);
  // if user gets a large refund, then don't let percent go below 0
  percent = Math.max(0, percent);
  console.log("percent", percent);
  return (
    <ProgressBarContainer percent={percent}>
      <p>
        <span className="spent-text">
          $<span data-cy="spentAmount">{spent}</span>
        </span>{" "}
        spent of $<span data-cy="totalAmount">{total}</span>
      </p>
      <ProgressBarBar className="outer">
        <ProgressBarBar className="inner" percent={percent} />
      </ProgressBarBar>
      <p className="lower">
        <span className="green">
          $<span data-cy="remainingAmount">{remaining}</span>
        </span>{" "}
        remaining
        {isCurrentMonth && (
          <span>
            {" "}
            for the next {days} day{days === 1 ? "" : "s"}
          </span>
        )}
      </p>
    </ProgressBarContainer>
  );
};

const ProgressBarContainer = styled.div`
  border-radius: 16px;
  box-shadow: 0px 2px 10px 0px #0b0d1626;
  background: white;
  font-size: 10px;
  color: #0b0d16bf;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px 0 20px;

  .spent-text {
    color: ${(props) =>
      props.percent
        ? props.percent >= 100
          ? `#FF8A00`
          : `#00af15`
        : `#00af15`};
    font-size: 24px;
  }
  .green {
    color: #00af15;
    font-size: 12px;
  }
  .lower {
    text-align: right;
  }
`;

const ProgressBarBar = styled.div`
  border-radius: 20px;

  &.inner {
    background: ${(props) =>
      props.percent
        ? props.percent >= 100
          ? `#FF8A00`
          : `#00af15`
        : `#00af15`};
    position: absolute;
    left: 0px;
    top: 0px;
    height: 98%;
    width: ${(props) => (props.percent ? props.percent : 0)}%;
  }
  &.outer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    background: #ebedf0;
    position: relative;
    height: 10px;
  }
`;
