import getIcontSVG from "./IconHelper";
import { formatAsMonthNameDD } from "../../../functions/timeDate";
import styled from "styled-components";

const iconNameList = [
  "Family",
  "Grocery",
  "Home & Garden",
  "Entertainment",
  "Health",
  "Transportation",
  "Focus",
  "Other",
];

function TableEntry(props) {
  /*
  props should be:
  date: date
  amount: number or string?
  originalAmount: number or string?
  counterParty: string
  description: string
  category: string
  onclicked: () => void
  */

  let SVG = null;
  if (props.category) {
    SVG = getIcontSVG(props.category);
  }
  if (props.showIcon !== null && props.showIcon === false) {
    SVG = null;
  }

  return (
    <MainContainer
      data-cy={"Card"}
      onClick={() => {
        props.editRow();
      }}
    >
      <LeftSide>
        {SVG && (
          <Icon>
            <SVG aria-label={props.category} />
          </Icon>
        )}
        <LeftColumn>
          <p className="top-bottom">{formatAsMonthNameDD(props.date)}</p>
          <p>{props.description}</p>

          <p className="top-bottom">
            <span className="darker">
              {props.counterParty === props.description
                ? ""
                : props.counterParty}
            </span>{" "}
            Visa 0567
          </p>
        </LeftColumn>
      </LeftSide>

      <div data-cy={"card-amount"}>
        {/* <p>${props.amount}</p> */}
        {/* <p>${props.originalAmount}</p> */}
        {parseFloat(props.amount) !== parseFloat(props.originalAmount) ? (
          <p>
            $<span data-cy={"card-amount-inner"}>{props.amount}</span>{" "}
            <span style={{ fontSize: "15px" }}>of ${props.originalAmount}</span>
          </p>
        ) : (
          <p>
            $<span data-cy={"card-amount-inner"}>{props.amount}</span>
          </p>
        )}
      </div>
    </MainContainer>
  );
}

export default TableEntry;

export const TABLE_ENTRY_HEIGHT = 85;

const MainContainer = styled.div`
  width: 363px;
  height: ${TABLE_ENTRY_HEIGHT}px;
  min-height: 80px;
  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px 0px #d4d4d4cc;
  margin-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: black;
  font-size: 18px;
  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;
const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
  .top-bottom {
    font-size: 12px;
    color: #b7b7b7;
    .darker {
      // font-size: 16px;
      color: black;
    }
  }
`;

const Icon = styled.svg`
  height: 30px;
  width: 30px;
  margin-right: 10px;
  margin-left: 10px;
`;
