export const ID_LIST = {
  Uncategorized: 0,
  Personal: 1,
  Grocery: 2,
  Home: 3,
  Entertainment: 4,
  Health: 5,
  Transport: 6,
  Focus: 7,
  Other: 8,
  Income: 101,
  Transfer: 201,
};

export const CAT_LIST = [
  "Uncategorized",
  "Personal",
  "Grocery",
  "Home",
  "Entertainment",
  "Health",
  "Transport",
  "Focus",
  "Other",
];
