import { monthmap } from "../../functions/timeDate";
import styled from "styled-components";
import { InfoText } from "../CFTSetup/Forms/styledFormComponents";

// IN: props.viewMonth: 1..12
// The component is passed the previous month, which is the month info
// to be displayed.
export default function MonthReviewComponent(props) {
  return (
    <Box>
      <DateText>
        {monthmap[props.viewMonth - 1]} {props.viewYear} MoneyFlow
      </DateText>
      <RemainingNumber center={props.center}>
        {props.center >= 0 ? `$${props.center}` : `-$${Math.abs(props.center)}`}
      </RemainingNumber>
      <InfoText>
        {parseInt(props.center) < 0 && (
          <span className="overBudget"> You're a bit over this month</span>
        )}
        {parseInt(props.center) >= 0 && (
          <span className="underBudget">Nice work staying on budget!</span>
        )}
      </InfoText>
      <InfoText>
        Get help from the community to make next month even better!
      </InfoText>
      <button
        id="endOfMonthOKBtn"
        onClick={() => {
          props.acknowledgeMonthReview();
        }}
      >
        OK
      </button>
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
`;

const DateText = styled.p`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`;

const RemainingNumber = styled.p`
  color: ${(props) => (props.center >= 0 ? "green" : "red")};
  font-weight: 800;
  font-size: 20px;
  margin-top: 0px;
`;
