import { useEffect } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import CFTSetup from "./pages/CFTSetup";
import ExpenseReviewInfoDisplay from "./pages/ExpenseReview/ExpenseReviewInfoDisplay";
import ExpenseReview from "./pages/ExpenseReview";
import BudgetSetup from "./pages/BudgetSetup";

import Login from "./pages/Login";
import Registration from "./pages/Registration";
import RegisterPayment from "./pages/Registration/RegisterPayment";
import ActiveTracker from "./pages/ActiveTracker";

import UserProvider from "./state/UserProvider";
import SettingsProvider from "./state/SettingsProvider";
import { PlaidProvider } from "./pages/Plaid/index.tsx";
import NotifyFlagProvider from "./pages/Congratulations/NotifyFlagProvider";
import { usePollingA } from "./pages/ActiveTracker/PollingProvider";

import LinkAccount from "./pages/Plaid/LinkAccount";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProfilePage from "./pages/ProfilePage";
import AccountsPage from "./pages/AccountsPage";
import ScorePage from "./pages/Score";
import Congratulations from "./pages/Congratulations";
import Admin from "./pages/Admin";
import AdminNotificationsContent from "./pages/Admin/pageAdminNotificationsContent";
import AdminUsersContent from "./pages/Admin/pageAdminUsersContent";
import AdminUsersStatContent from "./pages/Admin/pageAdminUsersStatContent";
import AdminTransactionsContent from "./pages/Admin/pageAdminTransactionsContent";
import Community from "./pages/Community/Community";
import Marketplace from "./pages/Marketplace/Marketplace";
import HelpPage from "./pages/HelpPage";
import HelpFAQ from "./pages/HelpPage/HelpFAQ";
import HelpThankYou from "./pages/HelpPage/HelpThankYou";
import Posts from "./pages/Community/Posts";
import Replies from "./pages/Community/Replies";
import CreditWheelPage from "./pages/CreditWheel";
import GoalsWheelPage from "./pages/GoalsWheel";
import Tracker from "./pages/Tracker/TrackerDemo";
import { Notifications } from "react-push-notification";
import GlobalStyle from "./styles/GlobalStyle";
import PasswordForgot from "./pages/PasswordForgot";
import PasswordReset from "./pages/PasswordReset";
import VerifyEmail from "./pages/Registration/VerifyEmail.js";
import SentRegistrationEmail from "./pages/Registration/SentRegistrationEmail.js";
import {
  isRunningTest,
  isDevelopment,
  isDeployed,
} from "./functions/environment";
import { getFormattedTimestamp } from "./functions/timeDate";
import { setMoneyFlowToken } from "./state/stateApplication";

function App() {
  const navigate = useNavigate();

  const { stopPolling } = usePollingA();

  useEffect(() => {
    console.log(`Log start time: ${getFormattedTimestamp()}`);
    console.log(`isRunningTest = ${isRunningTest()}`);
    console.log(`isDevelopment = ${isDevelopment()}`);
    console.log(`isDeployed = ${isDeployed()}`);

    const handlePopState = (event) => {
      // Redirect to the current route to effectively ignore the back button
      // and forward button.
      navigate(window.location.pathname, { replace: true });
    };

    if (!isRunningTest()) {
      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, []);

  useEffect(() => {
    let reloadConfirmed = false;

    const handleBeforeUnload = (event) => {
      reloadConfirmed = true;
      console.log("User is about to leave the page");

      console.log("One of these things has occurred:");
      console.log("1. User has closed the browser tab or window.");
      console.log(
        "2. User has refreshed the page by clicking the reload button on the web browser or typed F5.",
      );
      console.log(
        "3. User navigated to another page by typing in a URL in the address bar.",
      );

      // NOTE: for mobile, beforeunload event will not always fire for app A,
      // when user is on app A, then switches to app B, and
      // then closes the browser from the app manager.

      console.log("event =");
      console.log(event);

      // prevent browser's default action, which is to page refresh
      event.preventDefault();

      const msg = "Are you sure you want to leave?";
      // This triggers the browser's default confirmation dialog
      event.returnValue = msg; // for Chrome and Firefox
      return msg; // for other browsers
    };

    const handleHidden = () => {
      if (document.hidden) {
        console.log("User has left the page or quit the app.");
        // cleanup and send data to the server
        //stopPolling();
        if (reloadConfirmed) {
          localStorage.setItem("redirectAfterReload", "/login");
        }
      }
    };

    if (!isRunningTest()) {
      // triggered when user hits the reload button in a web browser or is leaving the page
      window.addEventListener("beforeunload", handleBeforeUnload);

      //NOTE: unload event is deprecated.  Using visibilitychange event instead.

      // Visibility changes to hidden when browswer is minimized or closed,
      // when switching from the browser to a different app,
      // and sometimes when the the browser window is completely covered by
      // another window.
      // or when user clicks on the web browser reload button and causes the page
      // to start to unload, so the page is hidden.
      window.addEventListener("visibilitychange", handleHidden);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("visibilitychange", handleHidden);
        localStorage.removeItem("redirectAfterReload");
      };
    }
  }, [navigate]);

  useEffect(() => {
    const redirectPath = localStorage.getItem("redirectAfterReload");
    if (redirectPath) {
      setMoneyFlowToken("");
      stopPolling();
      localStorage.removeItem("redirectAfterReload");
      navigate(redirectPath);
    }
  }, [navigate]);

  // BAD: Navigation API doesn't work in FireFox or Safari.
  useEffect(() => {
    (async () => {
      console.log(performance.getEntriesByType("navigation"));
      // Check if the page was just reloaded.
      const navigationEntry = performance.getEntriesByType("navigation")[0];

      // Deprecated:
      //if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD) {

      // BAD: once the user clicks the web browser's reload button once for the open tab,
      // every time navigate() is called, it's still considered a "reload"!
      if (navigationEntry && navigationEntry.type === "reload") {
        console.log("Page reload detected");
        console.log(`${navigationEntry.name} was reloaded!`);
        console.log(navigationEntry);
        // await stopPolling();
      }
    })();
  }, [navigate]);

  return (
    <>
      <GlobalStyle />
      <NotifyFlagProvider>
        <UserProvider>
          <SettingsProvider>
            <PlaidProvider>
              <Notifications />
              <Routes>
                <Route
                  path="/admin-transactions"
                  element={<AdminTransactionsContent />}
                />
                <Route
                  path="/admin-notifications"
                  element={<AdminNotificationsContent />}
                />
                <Route path="/admin-users" element={<AdminUsersContent />} />
                <Route
                  path="/admin-users-stat"
                  element={<AdminUsersStatContent />}
                />
                <Route path="/admin" element={<Admin />} />
                <Route path="/" element={<Login />} />
                <Route path="/money-wheel-setup" element={<CFTSetup />} />
                <Route path="/tracker" element={<Tracker />} />
                <Route path="/money-wheel" element={<ActiveTracker />} />
                <Route path="/community" element={<Community />} />
                <Route path="/replies/:postId" element={<Replies />} />
                <Route path="/posts/:groupId" element={<Posts />} />
                <Route path="/marketplace" element={<Marketplace />} />

                <Route path="/help" element={<HelpPage />} />
                <Route path="/help-faq" element={<HelpFAQ />} />
                <Route
                  path="/help-thank-you/:ticketId"
                  element={<HelpThankYou />}
                />
                <Route path="/credit-wheel" element={<CreditWheelPage />} />
                <Route path="/goals-wheel" element={<GoalsWheelPage />} />
                <Route
                  path="/expense-review-info"
                  element={<ExpenseReviewInfoDisplay />}
                />
                <Route
                  path="/expense-review-tour"
                  element={<ExpenseReview />}
                />
                <Route path="/expense-review" element={<ExpenseReview />} />
                <Route path="/budget-setup" element={<BudgetSetup />} />
                <Route path="/congratulations" element={<Congratulations />} />

                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Registration />} />
                <Route
                  path="/sent-registration-email"
                  element={<SentRegistrationEmail />}
                />
                <Route path="/verify-email/:token" element={<VerifyEmail />} />
                <Route path="/recover-password" element={<PasswordForgot />} />
                <Route path="/reset-password/*" element={<PasswordReset />} />
                <Route path="/link-account" element={<LinkAccount />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/profile-page" element={<ProfilePage />} />
                <Route path="/accounts-page" element={<AccountsPage />} />
                <Route path="/score" element={<ScorePage />} />
                <Route
                  path="/payment-registration"
                  element={<RegisterPayment />}
                />
              </Routes>
            </PlaidProvider>
          </SettingsProvider>
        </UserProvider>
      </NotifyFlagProvider>
    </>
  );
}

export default App;
