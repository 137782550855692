import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../state/UserProvider";
import { useSettings, updateSetupStep1 } from "../../state/SettingsProvider";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import BudgetPoll from "./BudgetPoll";
import backend from "../../functions/backend";
import { ID_LIST, CAT_LIST } from "../ActiveTracker/constants";

import BudgetWheel from "./BudgetWheel";
import SetBudgetComponent from "./SetBudgetComponent";
import Drawer from "../../components/DrawerComponents/Drawer";

export default function BudgetSetup() {
  const navigate = useNavigate();
  const { wheelSettings, dispatchSettings } = useSettings();
  const { currentUser } = useUser();
  const checkResponseFail = useCheckResponseFail();
  const [isPageLayoutComplete, setIsPageLayoutComplete] = useState(false);

  const now = new Date(); // gets local time
  const month = now.getMonth() + 1; // 1..12
  const year = now.getFullYear();

  const [catString, setCatString] = useState(undefined);
  const [cat, setCat] = useState(8);
  useEffect(() => {
    console.log("BudgetSetup(): 0");

    updateSetupStep1(
      wheelSettings.SETUP_STEP_BUDGET,
      currentUser.money_wheel_settings_id,
      dispatchSettings,
    );
    setIsPageLayoutComplete(true);
    getBudget();
  }, []); // run only once

  const [RemainingBudget, setRemainingBudget] = useState({
    Uncategorized: 0,
    Personal: 0,
    Grocery: 0,
    Home: 0,
    Entertainment: 0,
    Health: 0,
    Transport: 0,
    Focus: 0,
    Other: 0,
    center: 0,
  });

  const [BudgetedAmount, setBudgetedAmount] = useState({
    Uncategorized: 0,
    Personal: 0,
    Grocery: 0,
    Home: 0,
    Entertainment: 0,
    Health: 0,
    Transport: 0,
    Focus: 0,
    Other: 0,
    center: 0,
  });
  const [SpentAmount, setSpentAmount] = useState({
    Uncategorized: 0,
    Personal: 0,
    Grocery: 0,
    Home: 0,
    Entertainment: 0,
    Health: 0,
    Transport: 0,
    Focus: 0,
    Other: 0,
    center: 0,
  });
  const [budgetIds, setBudgetIds] = useState({
    Uncategorized: 0,
    Personal: 0,
    Grocery: 0,
    Home: 0,
    Entertainment: 0,
    Health: 0,
    Transport: 0,
    Focus: 0,
    Other: 0,
    center: 0,
  });

  // returns whether the complete budget for all categories is ready
  async function getBudget() {
    const response = await backend.get(
      "/v1/money_wheel/budget?year=" +
        year +
        "&month=" +
        month +
        "&user_id=" +
        currentUser.id,
    );
    checkResponseFail(response, "Failed to GET budget year and month.");
    console.log(response);

    console.log(`getBudget():  viewMonth is ${month}`);
    console.log(`getBudget():  budget response for ${month}/${year}`);
    console.log(response.items);
    let TempRemainingBudget = {
      Uncategorized: 0,
      Personal: 0,
      Grocery: 0,
      Home: 0,
      Entertainment: 0,
      Health: 0,
      Transport: 0,
      Focus: 0,
      Other: 0,
      center: 0,
    };
    let TempBudgetedAmount = {
      Uncategorized: 0,
      Personal: 0,
      Grocery: 0,
      Home: 0,
      Entertainment: 0,
      Health: 0,
      Transport: 0,
      Focus: 0,
      Other: 0,
      center: 0,
    };
    let TempSpentAmount = {
      Uncategorized: 0,
      Personal: 0,
      Grocery: 0,
      Home: 0,
      Entertainment: 0,
      Health: 0,
      Transport: 0,
      Focus: 0,
      Other: 0,
      center: 0,
    };
    let TempBudgetIds = {
      Uncategorized: 0,
      Personal: 0,
      Grocery: 0,
      Home: 0,
      Entertainment: 0,
      Health: 0,
      Transport: 0,
      Focus: 0,
      Other: 0,
      center: 0,
    };
    if (response.items && response.items.length <= 7) {
      if (response.items.length > 0) {
        console.warn(
          "getBudget(): got partial budget: response.items.length =",
          response.items.length,
        );
      }
      // Don't change budget numbers, until we can get a complete budget.
      return false;
    }
    if (response.items && response.items.length >= 9) {
      console.warn(
        "getBudget(): response.items.length =",
        response.items.length,
      );
    }
    if (response.items && response.items.length >= 8) {
      // length = 9
      // includes a budget for Uncategorized, when i=0.
      for (let i = 0; i < response.items.length; i++) {
        const item = response.items[i];

        const categoryStr = CAT_LIST[parseInt(item.tx_category_id)];
        console.assert(item.year === +year, "getBudget(): unexpected year");

        // item.month is 1..12 (ie 1-based index)

        // budget_amount is positive.
        // The server returns actual_amount and projected_amount that are negative.
        // But to simplify frontend display code, we'll save
        // SpentAmount and ProjectedAmount as positive.
        TempBudgetedAmount[categoryStr] = parseFloat(item.budget_amount);
        TempSpentAmount[categoryStr] = -1 * parseFloat(item.actual_amount);

        // set the remaining budget for the category and update the total amount.
        const remaining =
          TempBudgetedAmount[categoryStr] - TempSpentAmount[categoryStr];
        TempRemainingBudget[categoryStr] = remaining;
        TempRemainingBudget.center += remaining;

        TempBudgetIds[categoryStr] = item.id;
      }

      // In ActiveWheel's getBudget(), we call calcBudgets(response.items) here.
      // But we don't need to calculate the total across all categories here.

      // This changes the values in the wheel (around the wheel and the center)
      setRemainingBudget(TempRemainingBudget);
      setBudgetedAmount(TempBudgetedAmount);
      setSpentAmount(TempSpentAmount);
      setBudgetIds(TempBudgetIds);
      console.log("budgetedAmount");
      console.log(TempBudgetedAmount);
      console.log(TempSpentAmount);
      console.log(TempBudgetIds);
    }
    return true;
  }

  async function updatePlanned(planned) {
    if (catString && budgetIds[catString] === 0) {
      console.log("Can't patch budget with id = 0!");
      //  alert("Can't patch budget with id = 0!");
    }

    let query = "/v1/money_wheel/budget";
    const params = {
      tx_category_id: cat,
      budget_amount: String(planned),
      is_set_by_user: true,
      year: year,
      month: month,
      id: budgetIds[catString],
    };
    const budget = await backend.patch(query, params);
    getBudget(); // update remaining numbers in Wheel
  }
  const switchCategory = (name, num) => {
    console.log("switchCategory", name, num);
    console.log(CAT_LIST[num]);
    setCatString(CAT_LIST[num]);
    setCat(num);
  };

  return (
    <div>
      <BudgetPoll budget={BudgetedAmount} getBudget={getBudget} />
      <WheelContainer>
        <BudgetWheel
          RemainingBudget={RemainingBudget}
          switchCategory={switchCategory}
          centerText={cat === "All" ? "Edit a category!" : "Tap to finish!"}
        />
      </WheelContainer>
      <Drawer
        drawerLocation={1}
        setMb={() => {}}
        isPageLayoutComplete={isPageLayoutComplete}
        setIsDrawerShort={() => {}}
        isInitialExpenseReview={true}
      >
        <SetBudgetComponent
          category={catString}
          budgetedAmount={BudgetedAmount[catString]}
          spentAmount={SpentAmount[catString]}
          remainingAmount={RemainingBudget[catString]}
          setBudgetedAmount={updatePlanned}
          finishedFunction={() => {
            navigate("/congratulations", { replace: true });
          }}
        />
      </Drawer>
    </div>
  );
}

const WheelContainer = styled.div`
  display: flex;
  justify-content: center;
`;
