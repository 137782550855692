import "./style.css";
import FormFooter from "../../../components/UI/FormFooter";
import { useCategoryData } from "./Data";
import styled from "styled-components";

export const InfoButtonExplanation = ({
  onFinish,
  onFinishFailed,
  previousPage,
}) => {
  const [getCategoryData] = useCategoryData();
  return (
    <BoxWithTitleAndFooter
      {...getCategoryData("All")}
      nextPage={onFinish}
      previousPage={previousPage}
    />
  );
};

const BoxWithTitleAndFooter = ({
  CategoryTitleIcon,
  categoryTitleText,
  subcategoryText,
  nextPage,
  previousPage,
}) => {
  return (
    <div className={"form-wrapper"} data-cy="infoButtonExplanation">
      <div className={"category-title-group"}>
        <CategoryTitleIcon
          className={"category-title-icon"}
          style={{ width: "4em", height: "4em" /* outline: "1px solid red" */ }}
        />
        <h1 className={"category-title-text"}>{categoryTitleText}</h1>
      </div>
      <BoxOutline>Know Your Money</BoxOutline>
      <FormFooter
        onPrevButton={(e) => previousPage(e)}
        onNextButton={(e) => nextPage(e)}
      />
    </div>
  );
};

const BoxOutline = styled.div`
  border: 3px solid var(--green-moneyflow);
  border-radius: 0.75em;

  width: 100%;
  max-width: 430px;
  min-height: 200px;
  background: white;

  margin: 0.5em auto 0.5em auto;
  @media (min-width: 768px) {
    /* changes for ipad/tablet sizes */
  }
  @media (min-width: 1200px) {
    /* changes for desktop sizes */
  }

  color: var(--grey-dark);
  font-family: CondensedFont;
  font-weight: medium;
  line-height: 1.5;
  font-size: 2.5rem;
  padding: 1em;

  text-align: center;
`;
