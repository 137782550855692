import { Layout } from "antd";
import AdminFooter from "./layout/AdminFooter";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import AdminContent from "./AdminContent";

export default function AdminPage() {
  return (
    <Layout>
      <MoneyFlowHeader />
      <AdminContent />
      <AdminFooter />
    </Layout>
  );
}
