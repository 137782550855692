/*
function getPopoverOrigin() {
  const domObj = document.getElementById("popoverOrigin");
  if (domObj) {
    const rect = domObj.getBoundingClientRect();
    console.log("getPopoverOrigin");
    console.log(rect);
    return rect.bottom;
  } else {
    console.warn("getPopoverOrigin domObj is null");
    return 250;
  }
}

export function getFamilyCategoryLocation() {
  const domObj = document.getElementById("N_Group");
  if (domObj) {

    const rect = domObj.getBoundingClientRect();
    console.log("getFamilyCategoryLocation");
    console.log(rect);
    return rect.bottom - getPopoverOrigin() + 10;
  } else {
    console.warn("getFamilyCategoryLocation domObj is null");
    return 70;
  }
}
*/

// each popover has an associated html text,
// and optional top transform, left transform, and position.
// By default transform is [0,0] and position is "top".
export const InfoPopoverText = {
  page1:
    "Welcome to your Money Wheel Setup.  Wherever " +
    "you are in your app tap i for <i>i</i>nfo, guidance and " +
    "<i>i</i>nsight.<br /><br />" +
    "Tap <b>x</b> to close the pop-up." +
    "<br /><br />Next to continue.",
  page2:
    "Do you manage your finances on your own, or do you share " +
    "financial responsibilities?",
  page3:
    "Do you have any dependents relying on you financially?" +
    "<br /><br />Next to continue",
  page4:
    "Below are your default category details." +
    "You can edit your details after your Money Wheel setup." +
    "<br /><br />Next to continue.",
  page5:
    "Below are your default category details." +
    "You can edit your details after your Money Wheel setup." +
    "<br /><br />Next to continue.",
  page6:
    "Do you rent, own, or have another living arrangement?" +
    "<br /><br />Next to continue.",
  //page6Transform: [-180, 0],
  //page6Position: "bottom",
  page9: "Do you own a motor vehicle?",
  page12:
    "Select a Focus Category that fits your Journey. Whether it's business, " +
    "education, a cherished passion, or an expensive hobby.  If more than " +
    "one applies, you can customize your Focus Category anytime after setup.",
  focusWork:
    "If you have a business, work as an indendent contractor, or " +
    "need to track work-related expenses, this category is for you." +
    "<br /><br />Next to continue.",
  focusEducation:
    "Educating yourself and your family is a wonderful life long endeavor." +
    "<br /><br />Next to continue.",
  focusHobby: "Next to continue.",
  nextButton: "Next to continue",
  prePlaid:
    "Once you connect your bank and credit card accounts, you'll " +
    "be able to view balances and manage your settings here." +
    "<br /><br />Your data rights and security are our top priority. " +
    "We use bank-level security protocols, and you have rights " +
    "regarding your data.  See your data rights at www.moneyflow.org" +
    "<br /><br />Go to connect",
};

export const GoalPopoverText = {
  step1:
    "Welcome to your Goal wheel!" +
    "<br /><br /> Open the drawer to set a goal for your MoneyFlow Buffer!",
  step2: "Click here to add your new goal!",
  step3: "Enter a description for your goal!",
  step4: "How much do you want to save up?",
  step5: "When do you want to accomplish your goal by?",
};

export const MoneyWheelBasicTourText = {
  step1:
    'Welcome to your Money Wheel. Your Objective for the first month is to "Sort your Spending" to "Know Your Money"',
  step2:
    "MoneyFlow will send sms text notifications when you have expenses to Sort.",
  step3:
    "Your Category Details are in the Drawer. Pull up the drawer to find your Category Budget, your Expenses and other details.",
  step4:
    "Move between your Wheels in the Top Nav, Explore the bottom Nav to see the Marketplace and visit the Community for Support and Insight.",
};
