import styled from "styled-components";
import { useEffect, useState } from "react";
import { EditFilled } from "@ant-design/icons";
import SVGHeader from "../ActiveTracker/DrawerComponents/SVGHeader.js";

export default function SetBudgetComponent({
  category,
  budgetedAmount,
  spentAmount,
  remainingAmount,
  setBudgetedAmount,
  finishedFunction,
}) {
  const [amount, setAmount] = useState();
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    setAmount(Math.round(budgetedAmount * 100) / 100);
    setEditing(false);
  }, [category, budgetedAmount]);

  if (!category) {
    return (
      <MainContainer>
        <FinishedContainer>
          <p>
            Review your Category Budgets for this month. You can always play
            with your Budgets in Category details.
          </p>
          <button onClick={finishedFunction} data-cy="budget-finish">
            Finish
          </button>
        </FinishedContainer>
      </MainContainer>
    );
  }
  return (
    <MainContainer>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <SVGHeader headerLabel={category} fontSize={"1.625rem"} />
      </div>
      <Header>
        <Column>
          <p>Budgeted</p>

          {!editing && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                onClick={() => {
                  setEditing(true);
                }}
              >
                ${Math.round(budgetedAmount * 100) / 100}
              </p>
              <EditFilled onClick={() => setEditing(true)} />
            </div>
          )}
          {editing && (
            <BudgetInput
              type="number"
              data-cy="BudgetNumber"
              value={amount}
              autoFocus={true}
              onChange={(e) => setAmount(e.target.value)}
            />
          )}
        </Column>
        <Column>
          <p>Spent</p>
          <p>
            $
            <span data-cy="SpentNumber">
              {Math.round(spentAmount * 100) / 100}
            </span>
          </p>
        </Column>
        <Column>
          <p>Remaining</p>
          <p>
            $
            <span data-cy="RemainingNumber">
              {Math.round(remainingAmount * 100) / 100}
            </span>
          </p>
        </Column>
      </Header>

      {editing && (
        <ButtonContainer>
          <button
            className="cancel"
            onClick={() => {
              setEditing(false);
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setEditing(false);
              setBudgetedAmount(amount);
            }}
          >
            Save
          </button>
        </ButtonContainer>
      )}
    </MainContainer>
  );
}

export function DisplayBudgetComponent({
  date,
  budgetedAmount,
  spentAmount,
  remainingAmount,
}) {
  return (
    <MainContainer>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p>{date}</p>
      </div>
      <Header>
        <Column>
          <p>Budgeted</p>

          <div style={{ display: "flex", alignItems: "center" }}>
            <p>${Math.round(budgetedAmount * 100) / 100}</p>
          </div>
        </Column>
        <Column>
          <p>Spent</p>
          <p>${Math.round(spentAmount * 100) / 100}</p>
        </Column>
        <Column>
          <p>Remaining</p>
          <p>${Math.round(remainingAmount * 100) / 100}</p>
        </Column>
      </Header>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  border: #09d609b2 3px solid;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  padding: 0.2rem;
  color: #0b0d16bf;
  font-size: 1.2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 33%;
`;

const BudgetInput = styled.input`
  margin-top: auto;
  margin-bottom: auto;
  width: 4em;
  font-size: 1.2rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5%;
  button {
    border: 1px solid #09d609b2;
    border-radius: 5px;
    background-color: #09d609b2;
    font-size: 1.2rem;
    height: 2.7rem;
    &.cancel {
      background-color: rgba(128, 128, 128, 0.7);
      border: 1px solid rgba(128, 128, 128, 0.7);
    }
  }
`;

const FinishedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 5%;
  button {
    border: 1px solid #09d609b2;
    border-radius: 5px;
    background-color: #09d609b2;
    font-size: 1.2rem;
    width: 20%;
    height: 2.7rem;
  }
`;
