// for currencies that have 2 decimals
// IN: val: string of some decimal number. number type ok too.
// 1.4 => 1.40
// 1.123 => 1.12
// 1.129 => 1.13 (rounds, not truncates)
// "" or null => 0
// returns string of the same number, but with exactly 2 decimal places
export function twoDecimals(val) {
  if (!val) return 0;
  return String(parseFloat(val).toFixed(2));
}

// This is to fix "$-34.12"
// IN: amount: number  or string that looks like a number
//             There is no $ in amount.
// OUT: negative sign will be in front of the $
//      eg "-$34.12"
export function formatCurrency(amount) {
  // Convert amount to a number if it's a string.
  // Number(amount) will return NaN, if amount can't be converted to a number.
  const numAmount =
    typeof amount === "string" ? parseFloat(amount) : Number(amount);

  if (isNaN(numAmount)) {
    console.warn(`ERROR: ${numAmount} is an invalid amount`);
    return "Invalid amount";
  }

  const isNegative = numAmount < 0;

  // format with $ and 2 decimal places
  const formattedAmount = "$" + Math.abs(numAmount).toFixed(2);

  return (isNegative ? "-" : "") + formattedAmount;
}
