import ActiveWheel from "../Tracker/ActiveWheel";
import InitTrackerConfig from "../ExpenseReview/InitTrackerConfig";

export default function BudgetWheel({
  RemainingBudget,
  switchCategory,
  centerText,
}) {
  const trackerConfig = InitTrackerConfig();

  function navBack() {
    console.log("navBack");
  }
  async function editRow(id, isCallerTable = true) {
    console.log("editRow", id, isCallerTable);
  }
  return (
    <ActiveWheel
      dropExpense={() => {}}
      draggableExpense={null}
      nextExpense={null}
      config={trackerConfig}
      switchCategory={switchCategory}
      showNumbers={true}
      categoryFigures={RemainingBudget}
      isTour={false}
      setIsTour={() => {}}
      navBack={navBack}
      isExpenseReview={false}
      wheelType={"MoneyWheel"}
      EditFunction={editRow}
      centerText={centerText}
    />
  );
}
