import { Button, Form, Input } from "antd";
import { useState } from "react";

import {
  AntGreyTextEntry,
  AntDateSelector,
  GreyDropDown,
  AntGreyTextEntryContainer,
  SmallText,
  AntStyleInput,
  AntStyleDateSelector,
} from "../../components/DrawerComponents/splitComponents";
import styled from "styled-components";
import InfoPopoverAlt from "../../components/UI/Popover/InfoPopoverAlt";
import { GoalPopoverText } from "../../components/UI/Popover/InfoPopoverText";

export default function NewGoalForm({ form, onFinish, closeModal, isTour }) {
  const [category, setCategory] = useState(0);
  const [descPopOpen, setDescPopOpen] = useState(isTour);
  const [costPopOpen, setCostPopOpen] = useState(false);
  const [datePopOpen, setDatePopOpen] = useState(false);
  return (
    <Form
      form={form}
      name={"NewGoalForm"}
      onFinish={onFinish}
      onFinishFailed={closeModal}
      initialValues={{ category: category }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <>
          <p>Add New Goal</p>

          {isTour ? (
            <AntGreyTextEntryContainer>
              <SmallText>{"Description"}</SmallText>
              <InfoPopoverAlt
                // open={descPopOpen}
                onCloseButton={() => {
                  // setDescPopOpen(false);
                  // setCostPopOpen(true);
                }}
                contentText={GoalPopoverText["step3"]}
                placement="top"
                trigger="focus"
              >
                <Form.Item name={"description"}>
                  <AntStyleInput
                    autoFocus={false}
                    // use Form's initialValues instead of Form.Item's defaultValue
                    //defaultValue={starterText ? starterText : ""}
                  />
                </Form.Item>
              </InfoPopoverAlt>
            </AntGreyTextEntryContainer>
          ) : (
            <AntGreyTextEntry label="Description" name="description" />
          )}
          {isTour ? (
            <AntGreyTextEntryContainer>
              <SmallText>{"Total Cost"}</SmallText>
              <InfoPopoverAlt
                // open={costPopOpen}
                onCloseButton={() => {
                  // setCostPopOpen(false);
                  // setDatePopOpen(true);
                }}
                trigger="focus"
                contentText={GoalPopoverText["step4"]}
                placement="top"
              >
                <Form.Item name={"amount"}>
                  <AntStyleInput
                    autoFocus={false}
                    type={"number"}
                    // use Form's initialValues instead of Form.Item's defaultValue
                    //defaultValue={starterText ? starterText : ""}
                  />
                </Form.Item>
              </InfoPopoverAlt>
            </AntGreyTextEntryContainer>
          ) : (
            <AntGreyTextEntry label="Total Cost" name="amount" />
          )}
          {isTour ? (
            <AntGreyTextEntryContainer>
              <SmallText>{"Due Date"}</SmallText>
              <InfoPopoverAlt
                // open={costPopOpen}
                onCloseButton={() => {
                  // setCostPopOpen(false);
                  // setDatePopOpen(true);
                }}
                trigger="focus"
                contentText={GoalPopoverText["step5"]}
                placement="top"
              >
                <Form.Item name={"due_date"}>
                  <AntStyleDateSelector />
                </Form.Item>
              </InfoPopoverAlt>
            </AntGreyTextEntryContainer>
          ) : (
            <AntDateSelector label="Due Date" name="due_date" />
          )}

          {/* <AntGreyTextEntry label="Due Date" name="due_date" /> */}

          {/* <AntGreyTextEntry label="Category" name="category" /> */}
        </>

        <div
          style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
        >
          <AddInfoButton
            className="footer"
            type="button"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </AddInfoButton>
          <SubmitButton>Save!</SubmitButton>
        </div>
      </div>
    </Form>
  );
}

const SubmitButton = styled.button`
  background: linear-gradient(180deg, #3590ff 0%, #8dc1ff 171.11%);

  color: white;
  border-radius: 15px;
  border: none;

  box-shadow: 0px 2px 6px 0px #2180f473;

  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: auto;
  width: 45%;
  heigh: 100%;
`;

const AddInfoButton = styled.button`
  border: none;
  border-radius: 100px;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #0b0d16bf;
  border: 1px solid #0b0d1640;
  box-shadow: 0px 2px 20px 0px #0b0d161a;
  &.footer {
    margin: 0;
    // margin-left: 0px;
    font-size: 18px;
    color: #0b0d16bf;
    width: 45%;
    height: 100%;
  }
`;
