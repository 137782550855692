import getIcontSVG, { categoryNames } from "./IconHelper";
import styled from "styled-components";
import { GreyDropDown } from "../../../components/DrawerComponents/splitComponents";

export default function SVGHeader(props) {
  const headerLabel =
    props.headerLabel === "All" ? "Money Wheel" : props.headerLabel;
  const SVG = getIcontSVG(props.headerLabel);
  const fontSize = props.fontSize ? props.fontSize : "24px";
  console.log(`SVGHeader category is ${props.category}`);

  return props.switchCategory ? (
    <GreyDropDown
      category={props.category}
      setCategory={(value) => {
        console.log("switching category");
        console.log(value);
        props.switchCategory(categoryNames[value], value === 0 ? "All" : value);
      }}
      defaultDisplay={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0",
            margin: "0.5em 0 0 0",
          }}
        >
          <SVG height={fontSize} />
          <HeaderText fontSize={fontSize}>{headerLabel}</HeaderText>
        </div>
      }
    ></GreyDropDown>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0",
        marginTop: "0",
        marginBottom: "0",
      }}
    >
      <SVG
        height={fontSize}
        style={{
          padding: "0",
          marginTop: "0",
        }}
      />
      <HeaderText fontSize={fontSize}>{headerLabel}</HeaderText>
    </div>
  );
}

const HeaderText = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1.5rem")};
  line-height: 33px;
  line-height: 1.03; // unitless
  margin-right: auto;
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
`;
