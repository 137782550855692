import "./style.css";
import FormFooter from "../../../components/UI/FormFooter";
import styled from "styled-components";

// Even out the column lengths, so that the first column will have
// 1 or 0 more items than the second column.
// Using a table, instead of ul, so that items on a row line up.
const SubcatGroup = ({ subcategoryText }) => {
  // convert from a linear array, ordered by top down, then left right...
  // to an array of 2 item arrays, ordered by left right, then top down.
  const numSubcat = subcategoryText.length;
  const startIndexOfSecondColumn = Math.ceil(numSubcat / 2);
  const rowText = subcategoryText
    .slice(0, startIndexOfSecondColumn)
    .map((txt, index) => {
      const s = startIndexOfSecondColumn + index;
      return [txt, s < numSubcat ? subcategoryText[s] : ""];
    });

  return (
    <TableOutline>
      <StyledTable>
        <tbody>
          {rowText.map((row, index) => (
            <StyledTR key={index}>
              <StyledTD>{row[0]}</StyledTD>
              <StyledTD>{row[1]}</StyledTD>
            </StyledTR>
          ))}
        </tbody>
      </StyledTable>
    </TableOutline>
  );
};

// No category title or FormFooter
export const SubcatListSimple = ({ subcategoryText }) => {
  return (
    <div className={"form-wrapper"}>
      <SubcatGroup subcategoryText={subcategoryText} />
    </div>
  );
};

export const SubcatListWithTitle = ({
  CategoryTitleIcon,
  categoryTitleText,
  subcategoryText,
}) => {
  console.log(`categoryTitleText is ${categoryTitleText}`);
  console.log(`subcategoryText is ${subcategoryText}`);
  return (
    <div className={"form-wrapper"}>
      <div className={"category-title-group"}>
        <CategoryTitleIcon className={"category-title-icon"} />
        <h1 className={"category-title-text"}>{categoryTitleText}</h1>
      </div>
      <SubcatGroup subcategoryText={subcategoryText} />
    </div>
  );
};

export const SubcatList = ({
  CategoryTitleIcon,
  categoryTitleText,
  subcategoryText,
  nextPage,
  previousPage,
}) => {
  return (
    <div className={"form-wrapper"}>
      <div className={"category-title-group"}>
        <CategoryTitleIcon className={"category-title-icon"} />
        <h1 className={"category-title-text"}>{categoryTitleText}</h1>
      </div>
      <SubcatGroup subcategoryText={subcategoryText} />
      <FormFooter
        onPrevButton={(e) => previousPage(e)}
        onNextButton={(e) => nextPage(e)}
      />
    </div>
  );
};

const TableOutline = styled.div`
  border: 3px solid var(--green-moneyflow);
  border-radius: 0.75em;
  overflow: hidden; // clip the square corners extending outside the outline
`;

const StyledTable = styled.table`
  width: 100%;
  max-width: 430px;
  border-collapse: collapse;
  background: white;

  // padding inside the table doesn't work when border-collapse: collapse;
  //padding: 2em 1em; /* 22/16 = 1.375 */

  margin: 0em auto 1em auto;
  @media (min-width: 768px) {
    /* changes for ipad/tablet sizes */
  }
  @media (min-width: 1200px) {
    /* changes for desktop sizes */
  }
`;

const StyledTR = styled.tr`
  &:first-child td {
    padding-top: 1.1em; /* Space above first row */
  }
  &:last-child td {
    padding-bottom: 0.5em; /* Space below last row */
  }
`;

const StyledTD = styled.td`
  width: 50%; /* each column takes up 50% width of the table */
  border: none;
  color: var(--grey-dark);
  font-family: CondensedFont;
  font-weight: medium;
  /* make font-size and line-height smaller than Figma values,
     so that Transportation subcategory items fit */
  line-height: 1.7rem; /* 30px in Figma */
  font-size: 1.4rem; /* 26/16=1.625rem */
  letter-spacing: -0.02em;
  padding: 0.4em 0.6em;
`;
