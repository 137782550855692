import { useState, useEffect } from "react";
import { useApp } from "../../../state/AppProvider";
import { useSettings } from "../../../state/SettingsProvider";
import { Form } from "antd";
import { Radio } from "antd-mobile";
import "./style.css";
import FormFooter from "../../../components/UI/FormFooter";
import * as S from "./styledFormComponents";
import { ReactComponent as EducationIcon } from "../../../assets/img/MoneyWheel/money-education-icon.svg";
import { ReactComponent as WorkIcon } from "../../../assets/img/MoneyWheel/money-work-icon.svg";
import { ReactComponent as HobbyIcon } from "../../../assets/img/MoneyWheel/money-hobby-icon.svg";

export const FocusForm = ({ onFinish, onFinishFailed, previousPage }) => {
  // Form is an antd component
  // useForm() is a React hook
  const [form1] = Form.useForm();
  const { setIsPopoverOpen, setPopoverTextKey } = useApp();
  const { wheelSettings } = useSettings();

  useEffect(() => {
    const fields = { focus: wheelSettings.flex_category };
    form1.setFieldsValue(fields);
    console.log("useEffect() in FocusForm");
    console.log(fields);
    switchIcon(fields.focus);
  }, []);

  const [icon, setIcon] = useState();
  const switchIcon = (val) => {
    if (!val || val === "unspecified" || val === "education") {
      setIcon(<EducationIcon className="categoryIcon" />);
    } else if (val === "work") {
      setIcon(<WorkIcon className="categoryIcon" />);
    } else {
      setIcon(<HobbyIcon className="categoryIcon" />);
    }
  };

  const selectedValue = Form.useWatch("focus", form1);

  return (
    <div className={"form-wrapper"}>
      <Form
        form={form1}
        layout="vertical"
        name="Focus"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="focus"
          label=""
          rules={[
            {
              required: true,
              message: "Please make a selection",
            },
          ]}
        >
          <Radio.Group
            onChange={(val) => {
              console.log("FocusForm: Radio.Group: val =", val);
              switchIcon(val);
              setIsPopoverOpen(true);
              setPopoverTextKey("focus" + val[0].toUpperCase() + val.slice(1));
            }}
          >
            <Radio
              value="work"
              icon={(checked) => <S.RadioCircle />}
              block
              style={S.radioStyle}
              className={"mfo-radio"}
            >
              Business
            </Radio>
            <Radio
              value="education"
              icon={(checked) => <S.RadioCircle />}
              block
              style={S.radioStyle}
              className={"mfo-radio"}
            >
              Education
            </Radio>
            <Radio
              value="hobby"
              icon={(checked) => <S.RadioCircle />}
              block
              style={S.radioStyle}
              className={"mfo-radio"}
            >
              Passion
            </Radio>
          </Radio.Group>
        </Form.Item>
        <FormFooter
          onPrevButton={(e) => previousPage(e)}
          onNextButton={
            !selectedValue || selectedValue === "unspecified"
              ? "disabled"
              : "enabled"
          }
        />
      </Form>
    </div>
  );
};
