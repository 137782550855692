import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../state/UserProvider";
import {
  useSettings,
  serverSetSetupComplete,
  updateSetupStep1,
} from "../../state/SettingsProvider";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import { SingleButtonFooter } from "../../components/UI/FormFooter";
import addNotification from "react-push-notification";
import { getMoneyFlowToken } from "../../state/stateApplication";

import { PageTitle, BodyText } from "../../styles/styledPageComponents";
import { Form } from "antd";
import "../../styles/IPadLook.css";

import { ReactComponent as CelebrateIcon } from "../../assets/img/Other/celebrate-icon.svg";
import styled from "styled-components";

function Congratulations() {
  const { currentUser } = useUser();

  console.log(
    "Congratulations: 0: This is from the UserProvider: currentUser=",
    currentUser,
  );
  const { wheelSettings, dispatchSettings } = useSettings();
  console.log(
    "Congratulations: 0: This is from the SettingsProvider: wheelSettings=",
    wheelSettings,
  );

  const navigate = useNavigate();
  const [form1] = Form.useForm();

  // useEffect(() => {
  //   addNotification({
  //     title: "Please allow notifications",
  //     subtitle: "Please allow notifications",
  //     message: "Thanks for allowing notifications",
  //     theme: "darkblue",
  //     native: true, // when using native, your OS will handle theming.
  //   });
  // }, []); // run only during first render

  useEffect(() => {
    console.log("Congratulations: cash_settings.is_setup_complete set to true");
    serverSetSetupComplete(currentUser.money_wheel_settings_id);
    dispatchSettings({ type: "SETUP_COMPLETE" });
    //when we are done with the setup lets change our setup status for the tracker
    updateSetupStep1(
      wheelSettings.SETUP_STEP_ACTIVE_WHEEL_BASIC_TOUR,
      currentUser.money_wheel_settings_id,
      dispatchSettings,
    );
  }, []); // run only during first render

  function onFinish(values) {
    if (getMoneyFlowToken() === "") {
      navigate("/", { replace: true });
    } else {
      navigate("/money-wheel", { replace: true });
    }
  }

  return (
    <div className="screen">
      <MoneyFlowHeader />
      <div className="tracker-form-split">
        <div className="transactionsContainer">
          <div className={"form-wrapper"}>
            <MainContainer>
              <StyledCelebrateIconContainer>
                <CelebrateIcon style={{ maxWidth: "100px" }} />
              </StyledCelebrateIconContainer>
              <Text3>Congratulations!</Text3>
              {/* <Text3>Allow Notifications</Text3> */}
              {/* <Text2>
                Please allow notifications, so we can let you know when you have
                something new.
              </Text2> */}
              <Text3 style={{ lineHeight: 1.2 }}>
                It's time to
                <br />
                Make Your MoneyFlow!
              </Text3>
              {/* <Form
              layout="vertical"
              name="income_setup"
              onFinish={onFinish}
              form={form1}
            >
              <FormFooter onNextButton="enabled" disabledPrevButton={true} />
            </Form> */}
              <SingleButtonFooter
                onPrevButton={null}
                onNextButton={(e) => onFinish(e)}
                disabledPrevButton={true}
                nextBtnText={"Let's Go!"}
              />
            </MainContainer>
            <SecondRing />
            <GradientSection />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Congratulations;

const StyledCelebrateIconContainer = styled.div`
  display: flex;
  width: 95%;
  margin: auto;
  justify-content: center;
  margin-top: 7em;
  margin-bottom: 2em;
`;

const MainContainer = styled.div`
  background-color: white;
  width: 100vw;
  height: 60vh;
  border-radius: 0 0 60% 60%/ 0 0 30% 30%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`;

const SecondRing = styled.div`
  background-color: #d9f7bd;
  width: 100vw;
  height: 68vh;
  border-radius: 0 0 60% 60%/ 0 0 25% 25%;
  position: absolute;
  z-index: 5;
`;

const GradientSection = styled.div`
  width: 100vw;
  height: 60vh;
  background: linear-gradient(to bottom, #69cd74 0%, #d9f7bd 100%);
  position: absolute;
  top: 40vh;
  z-index: 0;
`;

const Text2 = styled.div`
  font-family: PrimaryVariableFont;
  font-size: 1.25rem; /* 20/16 */
  font-weight: 400;
  line-height: 1.6875rem; /* 27/16 */
  text-align: center;
  width: 90%;
`;

const Text3 = styled.div`
  font-family: PrimaryVariableFont;
  font-size: ${(props) => (props.size ? props.size : 1.75)}rem; /* 20/16 */
  font-weight: 800;
  line-height: 1.6875rem; /* 27/16 */
  text-align: center;
  width: 90%;
`;
