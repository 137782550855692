import { useUser } from "../../../state/UserProvider";
import { useSettings } from "../../../state/SettingsProvider";
import { ReactComponent as BusCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-bus-icon.svg";
import { ReactComponent as CarCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-car-icon.svg";
import { ReactComponent as EntertainmentCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-entertainment-icon.svg";
import { ReactComponent as GroceryCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-food-icon.svg";
import { ReactComponent as HealthCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-health-icon.svg";
import { ReactComponent as HomeCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-home-icon.svg";
import { ReactComponent as OtherCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-other-icon.svg";
import { ReactComponent as SinglePersonCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-single-icon.svg";
import { ReactComponent as MarriedCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-couple-icon.svg";
import { ReactComponent as FocusEducationCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-education-icon.svg";
import { ReactComponent as FocusHobbyCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-hobby-icon.svg";
import { ReactComponent as FocusWorkCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-work-icon.svg";
import { ReactComponent as AllCategoryTitleIcon } from "../../../assets/img/MoneyWheel/moneyflow-wheels-icon.svg";

const categoryData = {
  Family: {
    CategoryTitleIcon: MarriedCategoryTitleIcon,
    categoryTitleText: "Family",
    subcategoryText: [
      "Personal Item",
      "Personal Care",
      "Child Care, Pet",
      "Clothing",
      "Education",
      "Gift, Tithe, Charity",
    ],
  },
  Personal: {
    CategoryTitleIcon: SinglePersonCategoryTitleIcon,
    categoryTitleText: "Personal",
    subcategoryText: [
      "Personal Item",
      "Personal Care",
      "Pet",
      "Clothing",
      "Education",
      "Gift, Tithe, Charity",
    ],
  },
  Grocery: {
    CategoryTitleIcon: GroceryCategoryTitleIcon,
    categoryTitleText: "Grocery",
    subcategoryText: [
      "Grocery",
      "Vending Machine",
      "Meal Service",
      "Convenience Store",
    ],
  },
  "Home & Garden": {
    CategoryTitleIcon: HomeCategoryTitleIcon,
    categoryTitleText: "Home",
    subcategoryText: [
      "Tax, Deposit",
      "Rent, Mortgage",
      "Rent / Home Insurance",
      "Utilities",
      "Garden, Patio",
      "Furniture, Appliance",
    ],
  },
  Entertainment: {
    CategoryTitleIcon: EntertainmentCategoryTitleIcon,
    categoryTitleText: "Entertainment",
    subcategoryText: [
      "Theater, Event",
      "ATM Withdrawal",
      "Restaurant, Coffee",
      "TV, Streaming",
      "Gaming, Music",
      "Vacation, Hosting",
    ],
  },
  Health: {
    CategoryTitleIcon: HealthCategoryTitleIcon,
    categoryTitleText: "Health",
    subcategoryText: [
      "Medical Bill",
      "Health Insurance",
      "Medical Equipment",
      "Prescription",
      "Medicine, Supplement",
      "Gym, Health Member",
    ],
  },
  Transportation: {
    CategoryTitleIcon: CarCategoryTitleIcon,
    categoryTitleText: "Transportation",
    subcategoryText: [
      "Gasoline, Charging",
      "Parking, Toll",
      "Loan, Insurance, License",
      "Repair, Maintenance",
    ],
  },
  get TransportationCar() {
    return this.Transportation;
  },
  TransportationBus: {
    CategoryTitleIcon: BusCategoryTitleIcon,
    categoryTitleText: "Transportation",
    subcategoryText: ["Ride Service", "Public Transportation"],
  },
  Focus: {
    CategoryTitleIcon: FocusWorkCategoryTitleIcon,
    categoryTitleText: "Business",
    subcategoryText: [
      "Office Rent",
      "Office Supplies",
      "Gas / Utility / Phone",
      "Accessories",
      "Office Equipment",
      "Business Dining",
    ],
  },
  get FocusWork() {
    return this.Focus;
  },
  FocusHobby: {
    CategoryTitleIcon: FocusHobbyCategoryTitleIcon,
    categoryTitleText: "Passion",
    subcategoryText: ["Supplies", "Equipment", "Travel", "Memberships"],
  },
  FocusEducation: {
    CategoryTitleIcon: FocusEducationCategoryTitleIcon,
    categoryTitleText: "Education",
    subcategoryText: [
      "Tuition",
      "Room, Board",
      "Supplies",
      "Books",
      "Subscriptions",
    ],
  },
  Other: {
    CategoryTitleIcon: OtherCategoryTitleIcon,
    categoryTitleText: "Other",
    subcategoryText: [
      "Uncategorized",
      "Credit Card Minimum",
      "Other Loan Minimum",
      "Life Insurance",
      "Legal, Bank Fee",
    ],
  },
  All: {
    CategoryTitleIcon: AllCategoryTitleIcon,
    categoryTitleText: "Money Wheel",
    subcategoryText: [],
  },
};

export default categoryData;

export function useCategoryData() {
  const { currentUser } = useUser();
  const { wheelSettings } = useSettings();
  const numDependents = wheelSettings.dependent_count;
  //const married = currentUser.marital_status;
  const focusCat = wheelSettings.flex_category;
  const hasVehicle = currentUser.vehicles.length > 0;

  const categories = [
    "All",
    "Personal",
    "Family",
    "Grocery",
    "Home",
    "Home & Garden",
    "Entertainment",
    "Health",
    "Transport",
    "Transportation",
    "Focus",
    "Other",
  ];

  // IN: category: It's one of the 8 main categories (or name variations or "All") on the wheel.
  const getCategoryData = (category) => {
    console.assert(
      categories.includes(category),
      `ERROR: getCategoryData: unknown category=${category}`,
    );
    // categoryName is category + user selected option.
    // categoryName is the key for the categoryData object.
    let categoryName = category;
    if (category === "Focus") {
      categoryName += focusCat[0].toUpperCase() + focusCat.slice(1);
    } else if (category === "Transportation") {
      categoryName += hasVehicle ? "Car" : "Bus";
    } else if (category === "Family") {
      if (numDependents === 0) categoryName = "Personal";
    }
    console.log(`useCategoryData(): categoryName = ${categoryName}`);
    console.assert(
      categoryData[categoryName],
      `ERROR: getCategoryData: no category data found for category=${category}`,
    );
    return categoryData[categoryName];
  };

  return [getCategoryData];
}
