import { useState, useEffect } from "react";
import { useApp } from "../../../state/AppProvider";
import { useUser } from "../../../state/UserProvider";
import { Form } from "antd";
import { Radio } from "antd-mobile";
import "./style.css";
import FormFooter from "../../../components/UI/FormFooter";
import * as S from "./styledFormComponents";
import { ReactComponent as HomeIconStroke } from "../../../assets/img/SetupOutlineIcons/Home_Stroke.svg";
import { ReactComponent as HomeIconFill } from "../../../assets/img/SetupOutlineIcons/Home_Fill.svg";

export const RentOrOwnForm = ({ onFinish, onFinishFailed, previousPage }) => {
  // Form is an antd component
  // useForm() is a React hook
  const [form1] = Form.useForm();
  const { setIsPopoverOpen, PopoverTextMap, setPopoverTextKey } = useApp();
  const { currentUser } = useUser();

  useEffect(() => {
    const fields = { home_ownership: currentUser.home_ownership };
    form1.setFieldsValue(fields);
    console.log("useEffect() in RentOrOwnForm");
    console.log(fields);
    // currentUser ->        home_ownership : "rent"
    switchIcon(fields.home_ownership);
  }, []);

  const [icon, setIcon] = useState();
  const switchIcon = (val) => {
    if (!val || val === "unspecified") {
      setIcon(<HomeIconStroke className="categoryIcon" />);
    } else {
      setIcon(<HomeIconFill className="categoryIcon" />);
    }
  };

  const selectedValue = Form.useWatch("home_ownership", form1);

  return (
    <div className={"form-wrapper"}>
      <Form
        form={form1}
        layout="vertical"
        name="HomeAndGarden"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="home_ownership"
          label=""
          rules={[
            {
              required: true,
              message: "Please make a selection",
            },
          ]}
        >
          <Radio.Group
            onChange={(val) => {
              console.log("RentOrOwnForm: Radio.Group: val =", val);
              switchIcon(val);
              setIsPopoverOpen(true);
              setPopoverTextKey(PopoverTextMap.NEXT_BUTTON);
            }}
          >
            <Radio
              value="rent"
              icon={(checked) => <S.RadioCircle />}
              block
              style={S.radioStyle}
              className={"mfo-radio"}
            >
              Rent
            </Radio>
            <Radio
              value="own"
              icon={(checked) => <S.RadioCircle />}
              block
              style={S.radioStyle}
              className={"mfo-radio"}
            >
              Own
            </Radio>
            <Radio
              value="other"
              icon={(checked) => <S.RadioCircle />}
              block
              style={S.radioStyle}
              className={"mfo-radio"}
            >
              Other
            </Radio>
          </Radio.Group>
        </Form.Item>

        <FormFooter
          onPrevButton={(e) => previousPage(e)}
          onNextButton={
            !selectedValue || selectedValue === "unspecified"
              ? "disabled"
              : "enabled"
          }
        />
      </Form>
    </div>
  );
};
